@import "sass/variabiles";

.policy-page {
  .policy-content {
    padding-bottom: 75px;
  }

  .title {
    font-weight: $bold;
    font-size: 46px;
    line-height: 57px;
    text-align: left;
  }

  p {
    margin: 20px 0;
    &.limited-txt {
      max-width: 900px;
    }
  }

  ul {
    padding: 30px 60px;
    list-style-type: none;

    li::before {
      content: "\2022";
      color: $blue;
      font-weight: bold;
      display: inline-block;
      width: 18px;
      margin-left: -1em;
    }
  }

  a {
    color: #5986ff;
    &:hover {
      opacity: 0.8;
    }
  }

  h3 {
    margin: 35px 0;
    font-size: 26px;
  }
}
