.camera-preview {
  position: relative;
  background-image: linear-gradient(0deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.11)),
    linear-gradient(360deg, rgba(23, 23, 35, 11.7), rgba(5, 6, 28, 0.5) 94.79%) !important;
  overflow: hidden;
  > video {
    width: 100%;
  }
  .lds-ring {
    border: 5px solid #3a3b50;
    border-top: 5px solid #4f59d9;
    left: calc(50% - 30px);
    top: calc(50% - 30px);
    width: 60px;
    height: 60px;
  }

  &.hide-loader {
    animation: fade-out 1s ease forwards;
    .lds-ring {
      opacity: 0;
    }
  }

  .video-preview {
    animation: fadeIn 1s ease;
  }
}
