// Colors
$black: #000;
$white: #fff;
$gray: #2B2D3F;
$text-gray: #72818A;
$second-gray: #72818a;
$normal-gray: #bdbdbd;
$blue: rgb(79, 89, 217);
$light-blue: #5986FF;
$red: #A83B62;
$gray-button-color: rgba(255, 255, 255, .15);
$light-red: #EE4985;
$border-color: #DBDEE7;
$background-primary: #e8ebf4;
// Font-sizing
$light: 300;
$regular: 400;
$medium: 500;
$bold: 700;

$text-font-size: 16px;

// Media Queries
$mobile: 767px;
$tablet: 991px;
