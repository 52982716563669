@import "sass/variabiles";

.confirm-popup {
  &.stop-stream-modal {
    .popup-description {
      padding: 15px 0 25px;
      margin-bottom: 0;
    }

    .save-option {
      padding: 15px 0;
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      margin-bottom: 25px;
    }
  }

  &.modal-container {
    padding: 22px 30px 30px;
    width: 510px;
  }

  .modal-heading {
    font-weight: 500;
    font-size: 22px;
    line-height: 34px;
    color: $white;
    margin: 0;
  }

  .popup-description {
    color: $white;
    opacity: 0.7;
    padding: 30px 0;
  }

  .popup-button {
    font-weight: bold;
    font-size: 20px;
    border-radius: 6px;
    color: #fff;
    padding: 6px 30px;
    background: #4f59d9;
    margin-right: 34px;
    transition: all 0.3s ease;

    &:hover {
      background: #303683;
    }

    &[disabled] {
      opacity: 0.5;

      &:hover {
        background: #4f59d9;
      }
    }

    &.warning {
      background: #ee4985;

      &:hover {
        background: #b33a60;
      }
    }
  }
}
