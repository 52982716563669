@import "sass/variabiles";

.category-video-root {
  position: relative;
  margin-bottom: 48px !important;

  .filters-toggle {
    position: absolute;
    right: 28px;
    margin-top: -50px;
  }

  @media only screen and (max-width: $mobile) {
    .page-title {
      margin: 8px 0 21px 0;
      font-weight: 500 !important;
      font-size: 23px !important;
      line-height: 34px;
      text-align: center;
      color: #ffffff;
    }
  }

  .category-page-content {
    position: relative;
    min-height: 56vh;
  }
}

.category-video-content {
  margin-top: 18px;
  margin-bottom: 8px;
  @media only screen and (max-width: $mobile) {
    margin: 0 0 30px 0;
    &-item {
      margin-bottom: 30px;
      padding: 0;

      .video-post-item {
        .content {
          .date {
            right: unset;
            left: 20px;
            padding: 0;
          }
        }
      }
    }
  }
}
