@import "sass/variabiles";

.dashboard {
  .section-heading.row {
    margin-bottom: 10px !important;
  }

  .masonry-grid {
    display: flex;
    flex-flow: column wrap;
    width: 100%;
    max-height: 900px;

    .details-list {
      padding-left: 0;
      padding-top: 25px;

      li {
        list-style-type: none;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .direction-label {
          position: relative;
          display: inline-flex;
          flex-direction: row;
          align-items: center;

          & span {
            opacity: 1 !important;

            &.label-value {
              width: 82px;

              .icon-dash-line {
                color: #3ba894;
              }

              > span {
                font-weight: normal;

                &.increase {
                  color: #3ba894;
                }

                &.decrease {
                  color: #ee4985;
                }
              }
            }
          }

          .icon {
            position: relative;
            font-size: 10px;
            margin-left: 15px;
            margin-right: 10px;
            bottom: 1px;

            &.icon-caret-down {
              color: #a83b62;
            }

            &.icon-caret-up {
              color: #3ba894;
            }
          }
        }

        span {
          font-size: 16px;
          line-height: 30px;
          font-weight: $bold;

          &:first-child {
            opacity: 0.5;
          }

          &:last-child {
          }
        }
      }
    }

    .widget {
      width: 33.33%;
      padding: 10px;

      .content {
        position: relative;
      }

      &.analytics {
        .content {
          min-height: 400px;
        }

        .followers-count {
          text-align: center;
          padding-bottom: 24px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.1);

          h4 {
            font-weight: $regular;
            font-size: 16px;
            margin-top: 18px;
            line-height: 30px;
          }

          .count {
            font-weight: $bold;
            font-size: 42px;
            line-height: 57px;
            margin-bottom: 8px;
          }

          .latest-stat {
            font-size: 12px;
            line-height: 30px;
            color: #888891;

            > span {
              &.increase {
                color: #3ba894;
              }

              &.decrease {
                color: #a83b62;
              }

              padding-right: 8px;
            }
          }
        }
        .analytics-row-label {
          text-transform: capitalize;
        }
      }

      &.comments {
        .content {
          min-height: 425px;
        }

        .no-data-comments {
          text-align: center;
          padding-top: 53px;

          i {
            color: #2f303f;
            font-size: 50px;
            margin-bottom: 38px;
          }

          h3 {
            font-weight: bold;
            font-size: 18px;
            line-height: 22px;
            padding: 20px 60px 0;
          }

          p {
            font-weight: normal;
            font-size: 16px;
            line-height: 22px;
            opacity: 0.5;
            padding: 45px 35px 60px;
          }
        }

        .all-option {
          position: absolute;
          top: 31px;
          right: 27px;
          font-weight: $bold;
          color: #5986ff;

          &:hover {
            color: $blue;
          }
        }

        .comments-list {
          padding-left: 0;
          padding-top: 25px;

          li {
            list-style-type: none;
          }

          .comment-item {
            display: flex;
            flex-direction: row;
            margin-top: 20px;

            & > .link-to-user {
              display: inline-flex;
            }

            &:first-child {
              margin-top: 0;
            }

            .avatar {
              width: 24px;
              height: 24px;
              color: #ffffff;
              border-radius: 50%;
              background-size: cover;
              background-position: center;
              overflow: hidden;
              margin-top: -1px;

              & .name-letters {
                font-size: 12px;
              }
            }

            .user-inline-data {
              width: 100%;
              padding-left: 12px;

              .top-data {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                > span {
                  font-weight: $bold;
                  font-size: 14px;
                  line-height: 24px;

                  &:last-of-type {
                    font-weight: $regular;
                    font-size: 12px;
                    opacity: 0.5;
                  }
                }
              }

              .comment-body {
                font-size: 14px;
                margin-top: 6px;
                line-height: 24px;
                padding-right: 5px;
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              }

              .video-title {
                line-height: 24px;
                display: -webkit-box;
                overflow: hidden;
                color: #878893;
                text-overflow: ellipsis;
                font-size: 12px;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                margin-top: 10px;

                i {
                  font-size: 16px;
                  color: #5986ff;
                  padding-right: 10px;
                }
              }
            }
          }
        }

        .content {
          position: relative;
        }
      }

      &.latest-upload {
        .content {
          min-height: 425px;
        }

        .play-button {
          margin-top: 70px;
          height: 32px;
          width: 32px;
          line-height: 32px;
          border-radius: 50%;
          background: $white;
          text-align: center;
          position: absolute;
          left: 50%;
          margin-left: -16px;
          cursor: pointer;
          z-index: 99;
          transition: all 0.3s ease;

          &:hover {
            transform: scale(1.2);
          }

          > i {
            position: relative;
            left: 1px;
            color: #5986ff;
            font-size: 14px;
          }
        }

        .no-data-upload {
          text-align: center;
          padding-top: 53px;

          i {
            color: #2f303f;
            font-size: 50px;
            margin-bottom: 38px;
          }

          h3 {
            font-weight: bold;
            font-size: 18px;
            line-height: 22px;
            padding: 20px 40px 0;
          }

          p {
            font-weight: normal;
            font-size: 16px;
            line-height: 22px;
            opacity: 0.5;
            padding: 45px 35px 20px;
          }

          .main-button {
            padding: 6px 30px;
            margin-bottom: 20px;
            border-radius: 10px;

            i {
              color: $white;
              font-size: 18px;
            }
          }
        }

        .latest-post {
          padding-top: 18px;

          .thumb {
            height: 187px;
            background-size: cover;
            background-position: center;
            border-radius: 6px;
            position: relative;
            overflow: hidden;

            .overlay {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: linear-gradient(
                1turn,
                rgba(4, 6, 43, 0.7) 17.7%,
                rgba(5, 6, 28, 0.38) 34.79%
              );
            }

            .title {
              position: absolute;
              left: 21px;
              bottom: 19px;
              font-weight: $bold;
              color: #ffffff;
              font-size: 18px;
              line-height: 22px;
              padding-right: 70px;
              display: -webkit-box;
              overflow: hidden;
              text-overflow: ellipsis;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }

            .duration {
              position: absolute;
              bottom: 21px;
              right: 19px;
              font-size: 12px;
              font-weight: 400;
              line-height: 17px;
              height: 19px;
              padding: 2px 4px 18px;
              background-color: #575869;
              border-radius: 4px;
              opacity: 0.8;
              color: #ffffff;
            }
          }
        }

        .table-heading {
          font-size: 12px;
          line-height: 30px;
          padding: 20px 0 10px;
          text-align: center;
          opacity: 0.5;
        }

        .details-list {
          padding-top: 0;
        }
      }

      &.likes-status {
        .likes-chart {
          padding-top: 32px;

          #chartjs-tooltip {
            width: 80%;
          }

          .chart-coll {
            padding-right: 20px;
            position: relative;
          }

          .labels-items {
            padding-left: 0;
            padding-top: 40px;

            li {
              font-size: 16px;
              line-height: 24px;
              list-style-type: none;
              padding-bottom: 12px;

              i {
                font-size: 24px;
                position: relative;
                top: 4px;
                padding-right: 15px;

                &.icon-thumbs-up {
                  color: #4f59d9;
                }

                &.icon-thumbs-down {
                  color: #ee4985;
                }
              }
            }
          }
        }

        .content {
          min-height: 270px;
        }
      }

      &.top-videos {
        .top-label {
          font-size: 12px;
          line-height: 30px;
          opacity: 0.5;
          padding-top: 4px;
        }

        .content {
          min-height: 307px;
        }

        .top-views-list {
          padding-left: 0;
          padding-top: 25px;

          .video-item {
            color: #ffffff;
            list-style-type: none;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
            padding-bottom: 5px;
            margin-bottom: 5px;

            &:hover {
              color: #5986ff;
            }

            &:last-child {
              border-bottom: none;
              padding-bottom: 0;
              margin-bottom: 0;
            }

            span {
              font-size: 16px;
              line-height: 30px;

              &:first-child {
                font-weight: $bold;
                font-size: 15px;
                line-height: 30px;
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                padding-right: 5px;
              }

              &:last-child {
                opacity: 0.5;
              }
            }
          }
        }
      }

      .content {
        padding: 27px;
        border-radius: 6px;
        background: linear-gradient(360deg, rgba(4, 6, 43, 0.75) 11.7%, rgba(5, 6, 28, 0.5) 94.79%),
          #252525;
      }

      &-title {
        margin: 0;
        font-weight: $bold;
        font-size: 22px;
        line-height: 34px;
      }
    }
  }
}
