@import "sass/variabiles";

.main-slider {
  background-size: cover;
  position: relative;
  background-position: left 100%;
  overflow: hidden;

  .video-overlay {
    position: absolute;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    background-size: cover;
    background-position: center 28%;

    @media only screen and (max-width: $mobile) {
      background-image: url("../../common/assets/img/video-overlay-mobile.png") !important;
    }
  }

  .video-background {
    position: absolute;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    @media only screen and (max-width: $mobile) {
      height: 500px;
    }
  }

  .content {
    background-size: cover;
    width: 100%;
    padding-top: 100px;
    min-height: 850px;
    position: relative;
    z-index: 10;
    background: linear-gradient(180deg, rgba(2, 0, 36, 0) 74%, rgba(28, 28, 39, 1) 96%);
    @media only screen and (max-width: $mobile) {
      background: none !important;
    }

    .we-are-live {
      padding: 5px 15px;
      margin-bottom: 30px;
      background: #ee4985;
      border-radius: 6px;
      font-weight: 800;
      font-size: 12px;
      line-height: 24px;
      letter-spacing: 2px;
      text-transform: uppercase;
      display: inline-block;

      i {
        padding-right: 6px;
        font-size: 14px;
        position: relative;
        top: 2px;
        animation: pulseEffect 1.7s ease infinite;
      }
    }

    .hero-info {
      margin-top: 90px;

      .info-heading {
        padding-left: 60px;
        padding-right: 45px;
        text-align: center;

        .welcome-txt {
          max-width: 820px;
          font-weight: 900;
          font-size: 46px;
          line-height: 57px;
          margin: 0 auto;
          text-align: center;
          width: 100%;
        }

        .play-button {
          background: transparent;
          border: 1px solid $white;
          height: 80px;
          width: 80px;
          margin-left: -40px;
          padding-top: 19px;
          padding-left: 4px;
          margin-top: 60px;
          border-radius: 50%;
          text-align: center;
          position: absolute;
          left: 50%;
          cursor: pointer;
          z-index: 99;
          transition: all 0.3s ease;

          &:hover {
            transform: scale(1.2);
            background: rgba(89, 134, 255, 0.21);
          }

          > i {
            position: relative;
            left: 2px;
            top: 4px;
            color: $white;
            font-size: 35px;
          }
        }
      }
    }
  }
}

.video-popup {
  min-width: 1000px;
  height: 563px;
  @media only screen and (max-width: $mobile) {
    min-width: auto;
    height: auto;
  }
}

.become-streamer {
  min-height: 450px;
  background-size: cover;
  position: relative;
  padding: 80px 0;
  overflow: hidden;
  @media only screen and (max-width: $mobile) {
    padding: 56px 0 60px 0;
    background: #1b1b23;
  }

  .overlay-images {
    position: absolute;
    width: 100%;
    height: 100%;
    padding-bottom: 160px;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    @media only screen and (max-width: $mobile) {
      display: none;
    }

    .left-side,
    .right-side {
      width: 30%;
      height: 300px;
      background-size: contain;
      background-repeat: no-repeat;
    }

    .left-side {
      background-position: right;
    }

    .right-side {
      background-position: left;
    }
  }

  .content {
    max-width: 600px;
    width: 40%;
    margin: 0 auto;
    position: relative;
    z-index: 99;
    @media only screen and (max-width: $mobile) {
      width: 100%;
      max-width: 240px;
    }

    .title {
      text-align: center;
      font-weight: $medium;
      font-size: 46px;
      line-height: 44px;
      @media only screen and (max-width: $mobile) {
        font-style: normal;
        font-weight: 500;
        font-size: 23px;
        line-height: 26px;
        text-align: center;
        color: #ffffff;
        opacity: 1;
        width: 100%;
      }
    }

    .creator-actions {
      padding-top: 50px;
      @media only screen and (max-width: $mobile) {
        padding-top: 55px;
      }

      .cr-logo {
        text-align: center;
        @media only screen and (max-width: $mobile) {
          position: relative;
        }

        .background-logo {
          display: none;
          @media only screen and (max-width: $mobile) {
            display: block;
            position: absolute;
            background-image: url("../../common/assets/img/streamer-people.png") !important;
            width: 100%;
            height: 132px;
            background-size: contain;
            z-index: -1;
            top: -17.5px;
            background-repeat: no-repeat;
            background-position: center;
          }
        }

        > img {
          width: 230px;
          @media only screen and (max-width: $mobile) {
            width: 100px;
            height: 100px;
            object-fit: contain;
          }
        }
      }

      .description {
        font-size: 20px;
        padding: 20px 0;
        line-height: 34px;
        @media only screen and (max-width: $mobile) {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: #ffffff;
          padding: 25px 0;
        }
      }

      .become-streamer-btn {
        background: $white;
        color: $black;
        font-weight: $bold;
        padding: 12px 45px;
        @media only screen and (max-width: $mobile) {
          font-size: 16px;
          font-weight: 700;
          color: #131322;
          line-height: 24px;
          margin: 0 auto;
          display: block;
          padding: 6px 26.5px;
        }

        &:hover {
          background: #d0d0d0;
        }
      }
    }
  }
}

.top-recommended {
  margin: -34px 0 2px;

  .recommended-post-coll.hidden {
    @media only screen and (max-width: $mobile) {
      display: none;
    }
  }

  .recommended-post-list {
    @media only screen and (max-width: $mobile) {
      flex-direction: column !important;
    }
  }

  .recommended-post-coll {
    padding: 0 16px;
    @media only screen and (max-width: $mobile) {
      min-width: 300px;
      margin: 0 auto;
      padding: 0;
      &:last-child {
        .video-post-item {
          margin-bottom: 0;
        }
      }
    }
  }

  .section-title {
    text-align: center;
    font-weight: $medium;
    font-size: 46px;
    line-height: 64px;
    margin-bottom: 60px;

    .colored {
      color: #5986ff;
    }
  }

  .video-post-item {
    margin-bottom: 34px;
    @media only screen and (max-width: $mobile) {
      margin-bottom: 30px;
    }
  }

  .row {
    margin-right: -16px;
    margin-left: -16px;
  }
}

.top-videos-creators-container {
  background: linear-gradient(180deg, #1b1b28 0%, #2d2d3f 100%);
  padding-bottom: 65px;
  @media only screen and (max-width: $mobile) {
    background: none;
  }

  .top-videos-creators-container-block {
    @media only screen and (max-width: $mobile) {
      margin: 0;
      .top-videos-creators-container-block-item {
        padding: 0;
      }
    }
  }

  .row {
    margin-right: 0;
  }

  .creators-coll {
    width: 400px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    padding: 0 32px;
    border-radius: 6px;
    @media only screen and (max-width: $mobile) {
      padding: 0 30px;
      margin: 60px auto 0 auto;
      .section-title {
        font-style: normal;
        font-weight: 500;
        font-size: 23px;
        line-height: 64px;
        color: #ffffff;
        margin: 8px 0 30px;
        padding-left: 11px;
      }
    }
  }
}

.top-videos-week {
  .section-title {
    font-weight: $medium;
    font-size: 36px;
    line-height: 64px;
    margin-bottom: 45px;
    margin-top: 30px;
    @media only screen and (max-width: $mobile) {
      font-style: normal;
      font-weight: 500;
      font-size: 23px;
      line-height: 26px;
      text-align: center;
      color: #ffffff;
      margin-top: 60px;
      margin-bottom: 60px;
    }
  }

  .top-videos-week-list {
    list-style: none;
    padding-left: 0;
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
    padding-right: 30px;
    @media only screen and (max-width: $mobile) {
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-wrap: wrap;
      width: 100%;
      margin: 0;
      li {
        margin: 0 auto;
        padding: 0 !important;
      }
      li:last-child {
        .top-video-item {
          padding: 0 !important;
        }
      }
    }

    li.hidden {
      @media only screen and (max-width: $mobile) {
        display: none;
      }
    }

    li {
      webkit-column-break-inside: avoid;
      page-break-inside: avoid;
      break-inside: avoid;

      &:nth-of-type(1),
      &:nth-of-type(2),
      &:nth-of-type(3) {
        .top-video-item {
          .position-label {
            background: #ee4985;
          }
        }
      }

      &:nth-of-type(6) {
        padding-top: 1px;
      }

      &:nth-of-type(5),
      &:nth-of-type(10) {
        .top-video-item {
          padding-bottom: 0;
        }
      }

      @media only screen and (max-width: $mobile) {
        width: 100%;
      }
    }
  }

  .top-video-item {
    padding-left: 8px;
    padding-bottom: 18px;
    break-inside: avoid;
    @media only screen and (max-width: $mobile) {
      padding: 0 0 30px 0 !important;
      max-width: 291px;
      margin: 0 auto;
      width: 100%;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .position-label {
        position: absolute !important;
        left: 0 !important;
        top: 48px !important;
      }
      .top-video-item-profile {
        width: 100%;
        height: 122px;

        .thumb-profile-container {
          width: 175px !important;
          height: 122px !important;
          padding-bottom: 0 !important;
          margin: 0 auto;

          a {
            width: 100%;
            height: 100%;

            .video-image {
              width: 175px;
              height: 122px;
              position: absolute;
              @media only screen and (max-width: $mobile) {
                position: relative;
                .play-button {
                  display: block;
                  height: 50px;
                  width: 50px;
                  margin: 0 auto;
                  background: white;
                  border-radius: 50%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  padding-left: 3px;
                  position: absolute;
                  top: 36px;
                  left: 63px;
                  z-index: 9;

                  i {
                    color: #5986ff;
                  }
                }
              }
            }
          }
        }
      }

      .top-video-item-desc {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column-reverse;
        justify-content: center;
        padding: 0;

        .title {
          padding-top: 10px;
          line-height: 24px;
          font-weight: bold;
          text-align: center;
          -webkit-line-clamp: 2;
        }

        .meta-data {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 10px;

          span {
            padding: 0;
          }

          .category {
            border: 1px solid #5986ff;
            box-sizing: border-box;
            border-radius: 6px;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: #5986ff;
            padding: 6px 10px;
          }

          .meta-data-item {
            margin: 0 10px;
          }
        }
      }

      .top-video-item-channel {
        padding: 0;
        text-align: center;
        margin-top: 14px;

        .top-video-item-channel-link {
          text-decoration: none;
          display: flex;
          justify-content: center;
          align-items: center;

          .channel-avatar {
            width: 36px;
            height: 36px;
            border-radius: 50%;
            border: none;
            margin-right: 6px;
            background-size: contain;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          span {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: #fff;
          }
        }
      }
    }

    .position-label {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 1px;
      width: 26px;
      height: 26px;
      font-size: 10px;
      text-align: center;
      background: rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      font-weight: $bold;
      position: relative;
      top: 15px;
      margin-right: 10px;
      line-height: 26px;
    }

    .thumb-profile-container {
      width: 90px;
      position: relative;
      padding-bottom: 20px;

      .video-image {
        width: 68px;
        height: 68px;
        border-radius: 6px;
        background-size: cover;
        background-position: center;
        margin: 0 auto;
        overflow: hidden;
        position: relative;

        .play-button {
          display: none;
        }

        .overlay {
          position: absolute;
          width: 100%;
          height: 100%;
          background: linear-gradient(
            360deg,
            rgba(4, 6, 43, 0.8) 11.7%,
            rgba(5, 6, 28, 0.1) 94.79%
          );
        }

        &:hover {
          opacity: 0.6;
        }
      }

      .channel-avatar {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background-size: cover;
        background-position: center;
        position: absolute;
        z-index: 9;
        bottom: 4px;
        left: 50%;
        margin-left: -18px;

        > span {
          text-align: center;
          color: $white;
          display: block;
          font-weight: $bold;
          font-size: 15px;
          padding-top: 3px;
        }

        &:hover {
          opacity: 0.6;
        }
      }
    }

    .meta-data {
      position: relative;
      width: 0;
      display: flex;
      align-items: center;

      .category {
        border: 1px solid transparent;
        box-sizing: border-box;
        border-radius: 6px;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #fff;
        padding: 6px 10px 6px 0;
        margin-right: 10px;
        @media only screen and (max-width: $mobile) {
          color: #5986ff;
          border: 1px solid #5986ff;
          padding: 6px 10px;
        }
      }

      i {
        font-size: 24px;
        position: relative;
        top: 5px;
        padding-right: 8px;
      }

      .views,
      .duration {
        font-size: 16px;
        line-height: 24px;
        opacity: 0.5;
        padding-right: 20px;
      }
    }

    .title {
      font-weight: $bold;
      font-size: 16px;
      line-height: 22px;
      padding-top: 10px;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;

      a {
        color: $white;

        &:hover {
          color: #5986ff;
        }
      }
    }
  }
}

.top-creators {
  .avatar-container {
    position: relative;

    .live-badge {
      position: absolute;
      width: 64px;
      height: 64px;
      border-radius: 50%;
      border: 2px solid #ee4985;
      z-index: 7;
      transform: scale(1.18);

      @media only screen and (max-width: $mobile) {
        width: 77px;
        height: 77px;
      }

      &:before {
        content: "";
        position: absolute;
        right: -1px;
        top: 1px;
        width: 18px;
        height: 18px;
        box-sizing: border-box;
        z-index: 8;
        border-radius: 50%;
        border: 2px solid #ee4985;
      }

      .live-beep {
        position: absolute;
        right: -5px;
        top: -3px;
        width: 25px;
        z-index: 6;
        height: 25px;
        border-radius: 50%;
        box-sizing: border-box;
        background: #232333;

        &:before {
          content: "";
          width: 6px;
          height: 6px;
          z-index: 12;
          position: absolute;
          box-sizing: border-box;
          margin-top: 10px;
          margin-left: 9px;
          border-radius: 50%;
          background: #ee4985;
          animation: liveIcon 1.5s ease infinite;
        }
      }
    }
  }

  .top-creators-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 30px 0 45px;
    @media only screen and (max-width: $mobile) {
      margin: 8px 0 30px;
    }

    a.top-creators-all {
      font-style: normal;
      font-weight: $bold;
      font-size: 16px;
      line-height: 22px;
      color: #5986ff;
    }
  }

  .section-title {
    font-weight: $medium;
    font-size: 36px;
    line-height: 64px;
    margin: 0;
    @media only screen and (max-width: $mobile) {
      margin: 0 !important;
    }
  }

  .top-creators-list {
    padding-left: 0;

    .creator-item {
      list-style-type: none;
      padding-bottom: 21px;
      margin-bottom: 20px;
      border-bottom: 1px solid #344047;

      &:nth-child(1) {
        .live-beep {
          background: #1e1e2c;
        }
      }

      &:nth-child(3) ~ .creator-item {
        .live-beep {
          background: #282838;
        }
      }

      @media only screen and (max-width: $mobile) {
        margin-bottom: 25px;
        .top-creators-list-image {
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
      }

      &:last-of-type {
        border: none;
        padding: 0;
        margin: 0;
      }

      .row {
        padding-left: 8px;
      }

      .top-creators-list-info {
        .metadata-name {
          display: none;
        }

        @media only screen and (max-width: $mobile) {
          .user-name {
            display: none;
          }
          .metadata {
            display: flex;
            flex-wrap: wrap;

            .category {
              margin-bottom: 8px;
            }

            .metadata-name {
              font-weight: bold;
              font-size: 16px;
              line-height: 22px;
              color: #ffffff;
              margin-bottom: 9px;
              text-decoration: none;
              width: 100%;
              word-break: break-word;
              display: -webkit-box;
              overflow: hidden;
              text-overflow: ellipsis;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
            }

            .followers-count {
              line-height: 24px;
              height: 24px;
              display: flex;
              justify-content: flex-start;
              align-items: center;

              i {
                top: 0;
                padding-right: 11px;
              }
            }
          }
        }
      }

      .user-avatar {
        width: 64px;
        height: 64px;
        border-radius: 50%;
        background-size: cover;
        background-position: center;
        margin-right: 15px;
        @media only screen and (max-width: $mobile) {
          width: 72px;
          height: 72px;
          margin-right: 21px;
        }

        > span {
          text-align: center;
          color: $white;
          display: block;
          font-weight: $bold;
          font-size: 25px;
          padding-top: 17px;
        }
      }

      .metadata {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        .category {
          font-size: 16px;
          line-height: 24px;

          opacity: 0.5;
        }

        .followers-count {
          color: #8f8f96;

          i {
            font-size: 24px;
            position: relative;
            top: 4px;
            padding-right: 4px;
            color: $white;
          }
        }
      }

      .user-name {
        font-weight: $bold;
        font-size: 16px;
        line-height: 22px;
        margin-top: 4px;

        a {
          color: $white;

          &:hover {
            color: #5986ff;
          }
        }
      }
    }
  }
}

.exclusive-content {
  padding: 40px 0 100px;
  background-position: top center;
  background-size: cover;
  @media only screen and (max-width: $mobile) {
    padding: 60px 0 60px;
  }

  .exclusive-post-coll {
    text-align: left;
    @media only screen and (max-width: $mobile) {
      margin: 30px 0 0 0;
    }
  }

  .section-title {
    text-align: center;
    font-weight: $medium;
    font-size: 46px;
    line-height: 64px;
    margin-bottom: 60px;
    @media only screen and (max-width: $mobile) {
      font-size: 23px;
      line-height: 26px;
      color: #ffffff;
      margin-top: 0;
    }
  }

  .exclusive-description {
    max-width: 360px;
    border: 1px solid rgba(219, 222, 231, 0.25);
    border-radius: 6px;
    padding: 20px 35px;
    text-align: left;
    @media only screen and (max-width: $mobile) {
      padding: 24px 30px;
      max-width: 290px;
      margin: 0 auto;
    }

    h4 {
      font-weight: $bold;
      color: #ee4985;
      font-size: 26px;
      line-height: 34px;
      margin-bottom: 15px;
      @media only screen and (max-width: $mobile) {
        font-size: 19px;
        line-height: 28px;
        padding-bottom: 13px;
        margin-bottom: 8px;
      }
    }

    span {
      @media only screen and (max-width: $mobile) {
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
      }
    }
  }
}

.main-slider {
  @media only screen and (max-width: $mobile) {
    .content {
      padding-top: 58px;
      min-height: auto;

      .hero-info {
        margin-top: 62px;

        .info-heading {
          padding: 0 0 60px 0;

          .we-are-live {
            background: none;

            i {
              color: #a83b62;
              opacity: 1;
            }
          }

          .welcome-txt {
            font-size: 28px;
            line-height: 42px;
            max-width: 290px;
            margin: 0 auto;
          }

          .play-button {
            position: relative;
          }
        }
      }
    }
  }
}

.top-recommended {
  @media only screen and (max-width: $mobile) {
    margin: 0;
    .section-title {
      font-style: normal;
      font-size: 23px;
      line-height: 34px;
      color: #ffffff;
      margin: 0 auto 60px auto;
      max-width: 280px;
    }
  }
}

.load-more {
  display: none;
  @media only screen and (max-width: $mobile) {
    display: block;
    background: #a83b62;
    border-radius: 7px;
    max-width: 290px;
    margin: 30px auto 0 auto;
    width: 100%;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    padding: 6px 0;
  }
}

.partners-section {
  background-size: cover;
  position: relative;
  padding: 80px 0;
  overflow: hidden;

  .title {
    text-align: center;
    font-weight: $medium;
    font-size: 36px;
    line-height: 64px;
  }

  .full-container {
    padding: 45px 30px 5px;
  }

  .logos-list {
    width: 100%;

    @media screen and (max-width: 1200px) {
      justify-content: center;
    }

    .logo-link {
      transition: opacity 0.3s ease;
      opacity: 0.4;
      display: inline-block;
      padding: 10px 0;

      &:hover {
        opacity: 1;
      }
    }

    &.second {
      padding: 20px 0 0;
      .logo-link {
        padding: 10px 35px;
      }
    }
  }
}
