@import "sass/variabiles";

.page-not-found {
  @media only screen and (max-width: $mobile) {
    height: calc(100vh - 170px);
    display: flex;
    align-items: center;
  }
  .mobile-block {
    display: none !important;
  }
  &-main {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-bottom: 60px;
    &-item {
      width: 100%;
      text-align: center;
    }
    &-big {
      margin: 70px 0;
      @media only screen and (max-width: $mobile) {
        margin: 0;
      }
      span {
        font-weight: 800;
        font-size: 288px;
        color: #1b1b28;
        line-height: 230px;
        @media only screen and (max-width: $mobile) {
          font-size: 96px;
          line-height: 136px;
          color: rgba(255, 255, 255, 0.03);
        }
      }
    }
    &-bold {
      margin-bottom: 11px;
      @media only screen and (max-width: $mobile) {
        margin-bottom: 9px;
      }
      span {
        font-weight: 500;
        font-size: 32px;
        line-height: 48px;
        text-align: center;
        color: #ffffff;
        @media only screen and (max-width: $mobile) {
          font-size: 23px;
          line-height: 34px;
        }
      }
    }
    &-text {
      margin-bottom: 36px;
      @media only screen and (max-width: $mobile) {
        margin-bottom: 28px;
      }
      span {
        font-weight: normal;
        font-size: 18px;
        line-height: 27px;
        text-align: center;
        color: #72818a;
        @media only screen and (max-width: $mobile) {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
    &-btn {
      &-link {
        background: #a83b62;
        border-radius: 7px;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #fff;
        padding: 6px 45px;
        cursor: pointer;
      }
    }
  }
}
