@import "sass/variabiles";

.profile-settings {
  input,
  textarea {
    width: 100%;
  }

  .profile-info {
    textarea {
      margin-bottom: 0;
    }

    .user-basics {
      padding-right: 35px;
      @media only screen and (max-width: $mobile) {
        padding: 0 8px;
        .main-input {
          margin-bottom: 15px;
          font-size: 16px;
          padding: 12px 24px;
          line-height: 24px;
        }
      }
    }

    label {
      margin-bottom: 16px;
      font-weight: $bold;
      @media only screen and (max-width: $mobile) {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 8px;
      }
    }

    .input-description {
      height: 305px;
      margin-bottom: 0;
    }
  }

  .heading {
    font-weight: 500;
    font-size: 29px;
    line-height: 34px;
    padding-left: 8px;
  }

  .description {
    font-style: italic;
    font-size: 18px;
    line-height: 32px;
    color: #72818a;
    padding-left: 18px;
    padding-top: 3px;
    width: 100%;
  }

  .reset-password-form {
    padding-top: 34px;
    @media only screen and (max-width: $mobile) {
      padding-top: 0;
      .main-button {
        margin: 5px auto 0;
        display: block;
      }
      .main-input {
        margin-bottom: 15px;
        font-size: 16px;
        padding: 12px 24px;
        line-height: 24px;
      }
    }
  }

  .delete-account {
    padding: 20px 30px;
    text-align: center;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    color: #fff;
    @media only screen and (max-width: $mobile) {
      background: #a83b62;
      font-size: 13px;
      line-height: 24px;
      padding: 10px;
      margin: 20px auto;
      max-width: 290px;
    }

    i {
      padding-right: 15px;
      font-size: 22px;
      position: relative;
      top: 2px;
      @media only screen and (max-width: $mobile) {
        font-size: 18px;
        top: 0;
      }
    }

    > span {
      cursor: pointer;
      opacity: 0.5;
      transition: all 0.2s ease;
      @media only screen and (max-width: $mobile) {
        opacity: 1;
        display: flex;
        align-items: center;
      }

      &:hover {
        opacity: 1;
        color: #ee4985;
      }
    }
  }
}

.username-slug {
  position: relative;

  .slug-status {
    top: 18px;
    @media only screen and (max-width: $mobile) {
      top: 16px;
    }
  }
}
