@import "sass/variabiles";

.playlist-video-item {
  justify-content: flex-start;
  flex-direction: row;
  position: relative;
  font-size: 15px;
  color: #72818a;
  width: 100%;
  padding: 25px 0;
  margin-left: 10px;

  .video-details-list {
    padding-left: 0;
    margin: 0;

    > li {
      list-style-type: none;
      display: inline-block;
      vertical-align: top;
      height: 100%;

      &.thumb {
        position: relative;
        max-width: 32%;
        width: 140px;
        height: 85px;
        max-height: 74%;
        border-radius: 6px;
        margin-right: 20px;
        overflow: hidden;
        cursor: pointer;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.11), rgba(255, 255, 255, 0.11)),
          linear-gradient(360deg, rgba(4, 6, 43, 0.75) 11.7%, rgba(5, 6, 28, 0.5) 94.79%);
        @media screen and (min-width: 1424px) {
          max-height: 80%;
        }

        .default-thumb {
          display: flex;
          position: absolute;
          width: 100%;
          height: 100%;
          align-items: center;
          justify-content: center;

          i {
            color: $white;
            opacity: 0.3;
            font-size: 30px;
          }
        }

        .thumbnail-video {
          position: absolute;
          width: 100%;
          height: 100%;
          background-position: center;
          background-size: cover;
        }

        & > .duration {
          position: absolute;
          bottom: 5px;
          right: 5px;
          font-size: 12px;
          font-weight: 400;
          line-height: 17px;
          height: 19px;
          padding: 2px 4px;
          background-color: #232632;
          border-radius: 4px;
          opacity: 0.8;
          color: #ffffff;
        }
      }

      &.info {
        .title {
          margin: 0;
          font-weight: $bold;
          color: $white;
          font-size: 15px;
          width: 100%;
          line-height: 23px;
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        .video-data {
          font-size: 14px;
          padding: 8px 0 0;
          margin: 0;

          > li {
            list-style-type: none;
            display: inline-block;
            padding-right: 15px;

            &.account-details {
              display: inline-flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              font-size: 16px;
              position: relative;
              top: 5px;

              & .channel-avatar {
                display: inline-flex;
                justify-content: center;
                border-radius: 50%;
                margin-left: 0;
                margin-bottom: 2px;
                line-height: 8px;
                font-size: 10px;
                width: 22px;
                height: 22px;
                cursor: pointer;
                background-position: center;
                background-size: cover;
                position: relative;
              }

              & > .link-to-channel {
                display: inline-flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;

                .name-letters {
                  font-size: 10px;
                  font-weight: $bold;
                  padding-top: 7px;
                  color: $white;
                  position: absolute;
                  width: 100%;
                  text-align: center;
                  top: 0;
                  left: 0;
                }

                > span {
                  color: $white;
                  opacity: 0.5;
                  font-size: 14px;
                  margin-left: 10px;

                  &:hover {
                    color: #5986ff;
                  }
                }
              }
            }

            &.nr-views {
              vertical-align: middle;

              i {
                color: $white;
                font-size: 22px;
                position: relative;
                top: 4px;
                padding-right: 7px;
              }
            }
          }
        }
      }
    }
  }
}
