@import "sass/variabiles";

.edit-video-modal {
  min-width: 1100px;
  overflow: hidden;

  .checkbox-dropdown-select .app-select__menu .app-select__option.checkbox-option {
    padding: 0 17px !important;
  }

  .sharevision-video-player {
    > .wrap {
      > .video-js:before {
        height: 50px;
      }
    }
  }

  .show-comments-switch {
    margin-right: 15px;
    margin-bottom: -5px;
  }

  .thumbnail-chooser {
    display: inline-flex;
    flex-direction: row;
    height: 180px;
    width: 100%;
    padding: 15px 0;

    & > .available-thumnails-list {
      display: inline-flex;
      flex-direction: row;
      width: calc(100% - 210px);
      overflow-y: hidden;
      overflow-x: auto;
      margin-left: 10px;

      & .thumb-item:first-child {
        margin-left: 0;
      }
    }

    & .thumb-item {
      display: inline-flex;
      position: relative;
      min-width: 190px;
      height: 115px;
      width: 190px;
      background-color: #0e1131;
      border-radius: 6px;
      margin: 0 10px;

      & img {
        height: 100%;
      }

      & .icon-check-mark {
        position: absolute;
        background-color: #ffffff;
        color: #1c1c1c;
        width: 36px;
        height: 36px;
        padding: 10px;
        font-size: 16px;
        border-radius: 50%;
        z-index: 99;
        top: calc(50% - 18px);
        left: calc(50% - 18px);
      }
    }

    & .upload-dropzone {
      flex-direction: column;
      margin-left: 0rem;
      background-color: transparent;
      box-sizing: border-box;
      border: 2px dashed rgba($color: #ffffff, $alpha: 0.2);
      color: rgba($color: #ffffff, $alpha: 0.5);
      cursor: pointer;

      justify-content: center;
      align-items: center;

      & .thumb-preview {
        max-width: 100%;
        max-height: 100%;
      }

      & .icon-upload-cloud {
        font-size: 30px;
      }
    }
  }

  .row {
    margin: 0;
  }

  .modal-heading {
    font-size: 28px;
  }

  .video-load-progress {
    width: 250px;
    margin: 11px 25px 0 0;
    float: right;
  }

  .progress-tabs {
    font-size: 20px;

    & > .progress-tab {
      display: inline-flex;
      align-items: center;
      flex-direction: row;
      margin: 40px 30px;
      opacity: 0.1;

      & > .numerotation {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        font-size: 12px;
        background-color: #ffffff;
        color: #979797;
        border-radius: 50%;
        margin-right: 12px;
        width: 20px;
        height: 20px;
      }

      &.active {
        opacity: 1;

        & > .numerotation {
          color: #151516;
        }
      }
    }
  }

  .modal-body.edit-video {
    padding: 10px 50px 50px;

    & .video-preview {
      & > .thumb {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 360px;
        height: 190px;
        border-radius: 6px;
        margin-bottom: 20px;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.11), rgba(255, 255, 255, 0.11)),
          linear-gradient(360deg, rgba(4, 6, 43, 0.75) 11.7%, rgba(5, 6, 28, 0.5) 94.79%);

        & > .progress-bar-wrap {
          width: 70%;
        }
      }

      & > .details-row {
        margin-top: 5px;
        margin-left: 15px;
        flex-direction: column;

        & > .label {
          font-size: 15px;
          opacity: 0.35;
        }

        & > .value {
          font-size: 16px;
        }
      }
    }

    .video-privacy-options {
      border: 1px solid rgba(219, 222, 231, 0.25);
      box-sizing: border-box;
      border-radius: 6px;
      padding: 33px 40px;
    }
  }

  .modal-footer {
    padding: 20px 50px;

    .video-status {
      margin-top: 7px;

      span {
        font-size: 15px;
        opacity: 0.35;
      }
      .icon {
        font-size: 12px;
        margin-right: 10px;
      }
    }

    .submit-controls {
      color: #000000;
      font-size: 20px;
      text-align: right;

      & > .next-button,
      .main-button {
        width: 160px;
        height: 50px;
        border-radius: 6px;
        font-weight: 600;
      }

      & > .back-button {
        background-color: transparent;
        color: #ffffff;
        margin-right: 30px;
      }
    }
  }
}
