@import "sass/variabiles";

.dm-directory-root {
  overflow-y: auto;
  padding-right: 25px;
  visibility: hidden;

  &:hover,
  &:focus {
    visibility: visible;
  }
  @media only screen and (max-width: $mobile) {
    padding: 0 30px 0 0;
    text-align: center;
  }
}

.dm-directory-row {
  border: 1px solid transparent;
  padding: 0 14px;
  opacity: 0.7;
  transition: opacity 0.3s;

  &:hover {
    opacity: 1;
  }

  @media only screen and (min-width: $mobile) {
    max-width: 205px;
  }

  @media only screen and (max-width: $mobile) {
    display: flex;
    justify-content: center;
  }

  &[data-selected="true"] {
    border: 1px solid #5986ff;
    border-radius: 6px;
    opacity: 1;
  }
}
