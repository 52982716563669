.admin-chooser-list {
  background: rgba(22, 22, 37, 0.5);
  padding: 20px;
  border: 1px solid #2e2e3b;
  border-radius: 6px;
  margin-bottom: 18px;

  > .list {
    padding: 10px 0 0;
    list-style-type: none;
    max-height: 170px;
    overflow: auto;
  }

  .search-input {
    position: relative;

    .main-input {
      font-size: 14px;
      padding: 10px 35px 10px 12px;
      margin: 0;
    }

    > i {
      position: absolute;
      right: 0;
      font-size: 14px;
      margin-top: 13px;
      margin-right: 15px;
    }
  }
}
