@import "sass/variabiles";

.upload-videos {
  width: 80vw !important;
  max-width: 1100px;
  min-height: 300px;
  padding: 0 50px;

  .description {
    padding-top: 0 !important;
  }

  .upload-container {
    background-color: #1b1b28;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 15px 35px;
    margin: 30px 0;

    .main-button {
      margin-bottom: 15px;
    }

    &-header {
      display: inline-flex;
      flex-direction: row;
      line-height: 70px;

      & span {
        font-size: 16px;
        font-weight: 400;
        margin-left: 35px;
        opacity: 0.5;
      }
    }

    .items-wrapper {
      border-radius: 10px;
      margin: 15px 0;

      &.dropzone {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        margin: 25px 0 30px;
        border: 1px dashed rgba(#ffffff, 0.2);
        min-height: 133px;
        cursor: pointer;
        width: 100%;

        &:hover {
          border: 1px dashed rgba(83, 95, 247, 0.69);
        }

        .icon {
          font-size: 62px;
          opacity: 0.15;
        }

        & .button,
        .icon {
          margin: 15px;
        }

        & .button {
          background: transparent;
          font-weight: bold;
          font-size: 20px;
          border: 1px solid #ffffff;
          border-radius: 10px;
          padding: 10px 20px;
          transition: all ease 0.3s;

          &:hover {
            border: 1px solid $blue;
            background: $blue;
            color: $white;
          }
        }
      }

      & > .upload-list-item {
        position: relative;
        display: inline-flex;
        vertical-align: middle;
        justify-content: space-between;
        border-top: 1px solid rgba(#ffffff, 0.1);
        padding: 20px 0;
        width: 100%;
        height: 60px;
        line-height: 20px;
        font-size: 18px;

        &:focus-within {
          border: 1px solid #5662ff !important;
        }

        &.youtube {
          width: 100%;
          background-color: #161625;
          border: 1px solid #2e2e3b;
          border-radius: 6px;
          padding: 20px 24px;
        }

        & > .pull-right {
          display: inline-flex;
          vertical-align: middle;

          & > .button {
            margin-left: 10px;
          }
        }

        & .button {
          height: 20px;
          width: 20px;
          border-radius: 50%;
          background-color: #a83b62;

          i {
            color: #fff;
            font-size: 10px;
            position: relative;
            top: -13%;
            left: -5%;
          }

          &:hover {
            background-color: darken($normal-gray, 30%);
          }
        }

        & > img {
          margin: 0;
          width: 34px;
          height: 27px;
          position: relative;
          top: -4px;
          left: -5px;
        }

        & > input {
          border: none;
          background-color: transparent;
          width: calc(100% - 30px);

          &:focus {
            outline: none;
          }
        }
      }
    }
  }
}
