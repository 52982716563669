@import "sass/variabiles";

.channel-videos {
  width: 100%;
  position: relative;
  min-height: 400px;

  .app-filter-bar {
    background: #232632;
    margin: -10px 8px 20px;
    width: calc(100% - 16px);
    z-index: 99 !important;

    .selected-filter-item {
      background: #373848;
    }
  }

  .filters-toggle {
    margin-right: 10px;
    margin-top: 8px;
    @media only screen and (max-width: $mobile) {
      display: none;
    }
  }

  .title {
    font-size: 29px;
    line-height: 27px;
    margin: 20px 0 32px;
    font-weight: $regular;
    color: $white;

    &.channel-videos-title {
      padding-left: 10px;
    }
  }

  .secondary-button.outline {
    margin: 20px auto 5px;
    font-size: 16px;
    padding: 2px 20px;
    min-width: 314px;
    display: inherit;
  }
}

.empty-public-videos {
  position: relative;
  margin-top: 18%;
  text-align: center;
  transform: translateY(-50%);
  margin-bottom: 150px;
  @media only screen and (max-width: $mobile) {
    margin-top: 150px;
  }

  > i {
    font-size: 70px;
    display: inline-block;
    padding-bottom: 42px;
    color: #3c3d4c;
  }

  .empty-message {
    margin-bottom: 32px;
    color: #72818a;
    font-size: 22px;
  }
}
