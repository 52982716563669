.live-chat-message {
  background: rgba(255, 255, 255, 0.03);
  border-radius: 6px;
  padding: 10px 15px;
  margin-bottom: 10px;
  font-size: 13px;
  line-height: 20px;
  font-weight: 400;

  &:last-child {
    margin-bottom: 0;
  }

  &__host {
    background-color: rgba(89, 134, 255, 0.3);
  }
}
