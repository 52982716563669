@import "sass/variabiles";

.category-channels-root {
  position: relative;
  margin-bottom: 78px !important;

  .category-page-content {
    position: relative;
    min-height: 56vh;
  }

  .filters-toggle {
    position: absolute;
    right: 28px;
    margin-top: -50px;
  }

  .page-title {
    padding-left: 8px;
  }
}

.letters-filter-bar {
  padding: 0;
  margin: 30px 0 0;
  text-transform: capitalize;
  text-align: center;
  list-style-type: none;

  li {
    line-height: 28px;
    margin: 0 2px;
    width: 40px;
    display: inline-block;

    &:first-child {
      width: 50px;
    }

    label {
      margin: 0;
      width: 100%;
      border-radius: 6px;
      font-size: 18px;
      line-height: 32px;
      color: #72818a;
      text-align: center;
    }

    input {
      display: none;

      &:checked + label {
        color: $white;
        background: $blue;
      }

      &:hover + label {
        color: $white;
        background: rgba(79, 89, 217, 0.5);
      }
    }
  }
}

.category-content {
  margin-top: 20px;
  margin-bottom: 36px;

  .channel-item {
    &:nth-child(4) ~ .channel-item {
      .live-beep {
        background: #1c1c28 !important;
      }
    }
  }

  > .row {
    width: 100%;
    text-align: center;
    margin-top: 30px;
  }

  @media only screen and (max-width: $mobile) {
    margin-top: 0;
  }
}
