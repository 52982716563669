@import "sass/variabiles";

.progress-bar-container {
  display: flex;
  flex-direction: row;
  line-height: 16px;
  font-size: 16px;
  width: 100%;
  align-items: center;
  height: 100%;
  color: #72818a;

  .progress-container {
    height: 24px;
    width: 65%;
    margin: 5px;

    &.full-width {
      width: 85%;
    }

    .progress-label {
      display: inline-flex;
      justify-content: center;
      margin-top: 5px;
      font-size: 12px;
      width: 100%;
    }

    .progress-wrap {
      display: inline-flex;
      position: relative;
      height: 6px;
      width: 100%;
      margin: 0;
      margin-right: 10px;
      border-radius: 30px;
      background: #dbdee7;
    }

    .progress-bar {
      position: absolute;
      left: 0;
      height: 100%;
      background: #4f59d9;
      border-radius: 30px;
      transition: all 0.6s ease;
    }
  }

  .progress-percentage {
    font-size: 11px;
    font-weight: bold;
    width: 20%;
  }

  .upload-cancel {
    position: relative;
    width: 14px;
    height: 14px;
    padding: 0;
    margin-left: 10px;
    border-radius: 50%;
    background-color: #a83b62;

    & i {
      position: absolute;
      color: #ffffff;
      font-size: 7px;
      top: 3px;
      left: 3px;
    }

    &:hover {
      background-color: darken($normal-gray, 30%);
    }
  }
}
