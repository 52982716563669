@import "variabiles";

@mixin icon-button() {
  outline: 0;
  border-radius: 6px;
  padding: 0;
  overflow: hidden;
  display: table;
  background: $gray;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.3s ease;

  .icon-side {
    height: 100%;
    width: 20%;
    display: table-cell;
    padding: 13px;
    border-right: 1px solid rgba(255, 255, 255, 0.2);

    img {
      width: 28px;
    }
  }

  .text-side {
    line-height: 34px;
    padding: 10px 13px;
    font-size: 20px;
    font-weight: $bold;
    text-align: center;
    display: table-cell;
  }

  &:hover {
    background: lighten($gray, 10%);
  }
}

@mixin main-input() {
  font-size: 18px;
  padding: 16px 24px;
  border-radius: 6px;
  outline: 0;
  color: $white;
  background: #161625;
  border: 1px solid #2e2e3b;
  margin-bottom: 22px;
  width: 100%;

  &::-webkit-input-placeholder {
    color: $white;
    opacity: 0.35;
  }
  &::-moz-placeholder {
    color: $white;
    opacity: 0.35;
  }
  &:-ms-input-placeholder {
    color: $white;
    opacity: 0.35;
  }
  &:-moz-placeholder {
    color: $white;
    opacity: 0.35;
  }
  &:focus {
    border: 1px solid #1c6eec;
  }
}

@mixin main-button($color: $blue) {
  outline: 0;
  border-radius: 6px;
  padding: 10px 20px;
  overflow: hidden;
  background: $color;
  color: $white;
  @if $color == $white {
    color: $black;
    font-weight: $bold;
  } @else {
    color: $white;
  }
  font-size: 20px;
  transition: all 0.3s ease;

  &:hover {
    @if $color == $white {
      background: #424b69;
      color: $white;
    } @else if ($color == $gray-button-color) {
      background: darken($color, 35%);
    } @else {
      background: darken($color, 10%);
    }
  }

  &[disabled] {
    opacity: 0.5;

    &:hover {
      background: $blue;
    }
  }
}

@mixin secondary-button() {
  border-radius: 6px;
  padding: 10px 20px;
  overflow: hidden;
  outline: 0;
  background: $gray;
  color: $black;
  display: table;
  width: 100%;
  font-size: 20px;
  transition: all 0.3s ease;

  &:hover {
    background: lighten($gray, 10%);
  }
}
