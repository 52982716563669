@import "sass/variabiles";

.live-chat-header.live-chat-participants {
  justify-content: end;

  .icon-chevron-left {
    opacity: 0.1;
    cursor: pointer;
    width: 22px;

    &:hover {
      opacity: 1;
    }
  }

  &:before {
    top: 56px;
  }
}

.live-chat-participants-items {
  padding: 15px 35px 35px 35px;
  position: relative;
  z-index: 2;
  overflow-y: scroll;
  height: 100%;
  margin: 4px 4px 4px 0;

  .wrapper-live-chat-avatar {
    margin-bottom: 10px;
    width: 100%;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
