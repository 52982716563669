@import "sass/variabiles";

.similar-channels-list {
  margin-top: 30px;
  .live-beep {
    background: #1c1c28 !important;
  }
}

.category-list-allocation {
  padding: 25px 0 0;
  margin: 0;

  .item {
    display: inline-block;
    list-style-type: none;
    position: relative;
    font-size: 16px;
    font-weight: $regular;
    border: 1px solid rgba(219, 222, 231, 0.15);
    border-radius: 6px;
    padding: 3px 15px;
    margin-bottom: 16px;

    > .category-color {
      top: 50%;
      position: relative;
      display: inline-block;
      margin-top: -6px;
      margin-right: 10px;
      left: 0;
      width: 12px;
      height: 12px;
      background-color: #ccc;
      border-radius: 50px;
    }

    > .percentage {
      opacity: 0.5;
    }
  }
}
