@import "sass/variabiles";

.empty-placeholder-root {
  position: relative;
  margin-top: 18%;
  text-align: center;
  transform: translateY(-50%);
  margin-bottom: 150px;

  @media only screen and (max-width: $mobile) {
    margin-top: 150px;
  }
}

.empty-placeholder-icon {
  font-size: 70px;
  display: inline-block;
  padding-bottom: 42px;
  color: #3c3d4c;
}

.empty-placeholder-message {
  margin-bottom: 32px;
  color: #72818a;
  font-size: 22px;
}
