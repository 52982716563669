@import "sass/variabiles";

.video-library {
  .playlists-table-heading {
    padding-left: 0;
  }

  .playlist-load-more {
    width: 150px !important;
    font-size: 16px;
    line-height: 16px;
    margin: 22px auto 6px;
    &[disabled] {
      opacity: 0.4;
      pointer-events: none;
    }
  }
}

.playlist-heading-controls {
  display: flex;
  flex-direction: row;
  padding-left: 8px;
  padding-top: 30px;
  color: $white;
  position: relative;

  &:last-child {
    padding-top: 20px;
  }

  &.active-addition {
    position: relative;

    .toggle-button {
      .circle-button {
        top: 4px;
      }
    }

    .add-column-input {
      padding-top: 2px;

      input {
        padding-left: 0 !important;
      }
    }

    &:after {
      content: "";
      position: absolute;
      bottom: -15px;
      left: 0;
      width: 100%;
      height: 1px;
      background: rgba(255, 255, 255, 0.1);
    }
  }

  .toggle-button {
    cursor: pointer;

    .circle-button {
      display: inline-block;
      width: 25px;
      height: 25px;
      text-align: center;
      background-color: #4f59d9;
      border-radius: 50%;
      margin-right: 15px;
      position: relative;
      top: 1px;

      &:hover {
        background-color: #006cff;
      }

      i {
        font-size: 13px;
        position: relative;
        top: -3px;
      }
    }

    .label-button {
      font-size: 23px !important;
      line-height: 34px;
      font-weight: $regular;
    }
  }

  .add-column-input {
    padding-left: 0;
  }

  .input-wrap {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    font-size: 23px;
    line-height: 34px;
    font-weight: $regular;

    input {
      border: none;

      &::-webkit-input-placeholder {
        color: $white;
        opacity: 0.2;
      }

      &::-moz-placeholder {
        color: $white;
        opacity: 0.2;
      }

      &:-ms-input-placeholder {
        color: $white;
        opacity: 0.2;
      }

      &:-moz-placeholder {
        color: $white;
        opacity: 0.2;
      }
    }

    &.over {
      opacity: 0.5;
    }

    & > input {
      width: calc(100% - 60px);
      background-color: transparent;
    }
  }
}

.playlist-list {
  flex-direction: column;

  &.manage-playlists {
    margin-top: 20px;
    position: relative;

    .list-wrap {
      display: flex;
      position: relative;

      &:last-child {
        .playlist-body {
          border-bottom: 1px solid rgba(255, 255, 255, 0.1);

          > .playlist-empty {
            padding-top: 15px;
          }
        }
      }

      &.over {
        .playlist-header {
          background-color: rgba(255, 255, 255, 0.06) !important;
        }
      }
    }

    .playlist-accordion {
      .expend-toggle {
        margin-top: 2px;

        &:hover {
          opacity: 0.6;
        }
      }

      .playlist-header {
        padding: 17px 10px 15px;
        background: transparent;
        border-top: none;
        position: relative;

        .submit-button,
        .cancel-button {
          color: $white;
        }

        .submit-button {
          width: 27px;

          i {
            top: -3px;
          }
        }

        .cancel-button {
          margin-top: 3px;
          width: 22px;

          i {
            top: -4px;
          }
        }

        .playlist-title-coll {
          position: relative;
        }

        .playlist-title {
          cursor: inherit;
          color: $white;
          line-height: 23px;
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          padding-right: 70px;
        }

        .children-count {
          background-color: #ffffff;
          font-weight: 700;
          border-radius: 6px;
          color: $blue;
          font-size: 12px;
          padding: 0 5px;
          margin-left: 15px;
          top: 0;
          min-width: 40px;
          text-align: center;
          right: 35px;
          position: absolute;
          line-height: 26px;
        }

        .icon-playlist-import {
          cursor: pointer;
          font-size: 20px;
          color: $white;
          position: relative;
          top: -1px;
          z-index: 99;

          &:hover {
            color: $blue;
          }
        }

        .hover-controls {
          opacity: 0;
          position: absolute;
          left: 92px;
          color: $white;

          > i {
            font-size: 19px;
            position: relative;
            top: 2px;
            padding-right: 25px;
            cursor: pointer;

            &:hover {
              color: $blue;
            }

            &:last-child {
              &:hover {
                color: #ee4985;
              }
            }
          }
        }

        &:hover {
          .playlist-title {
            opacity: 0;
          }

          .playlist-status-coll {
            .status-display {
              opacity: 0;
            }
          }

          .input-wrap {
            .input {
              opacity: 1;
            }
          }

          .hover-controls {
            opacity: 1;
          }
        }
      }

      &.edit-options-off {
        .playlist-header {
          &:hover {
            .playlist-title {
              opacity: 1;
            }
          }
        }
      }

      .playlist-info {
        width: 100%;
        position: relative;

        .row {
          width: 100%;
          text-align: left;
        }

        .input-wrap {
          > input.playlist-title {
            font-size: 23px;
            padding-left: 15px;
          }
        }

        .playlist-title-coll {
          padding-left: 22px;
          font-size: 23px;
          width: 60%;
        }

        .playlist-status-coll {
          position: relative;
          width: 20%;
          padding-left: 5px;
          font-size: 14px;

          .status-display {
            i {
              padding-left: 2px;
              position: relative;
              top: 2px;
              font-size: 20px;
            }
          }

          .hover-controls {
            left: 0;
            top: 0;
            padding-left: 5px;

            .app-select__indicator {
              position: relative;
              top: 4px;
              color: #fff;
              padding-left: 2px;
            }

            .app-select__single-value {
              i {
                padding-left: 6px;
                position: relative;
                top: 2px;
              }
            }

            .app-select__menu {
              min-width: 145px !important;
              text-align: left;

              .app-select__option {
                .custom-status-option {
                  font-size: 16px;

                  i {
                    font-size: 20px;
                    position: relative;
                    top: 2px;
                    padding-left: 10px;
                  }
                }
              }
            }
          }
        }

        .playlist-date-coll {
          font-size: 14px;
          padding-left: 2px;
        }

        .select-all-option {
          position: relative;
          top: -1px;
        }
      }

      .playlist-body {
        padding-right: 10px;
        padding-bottom: 20px;

        > .playlist-empty {
          min-height: 100px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          padding-top: 25px;

          i {
            padding-right: 10px;
            font-size: 30px;
            position: relative;
            top: -4px;
          }
        }

        .thumb {
          cursor: default;
        }
      }
    }
  }
  .fetching-playlist {
    position: relative;
    min-height: 400px;
  }
}
