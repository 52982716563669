@import "sass/variabiles";

.live-chat-footer {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  padding: 20px;

  .live-chat-footer-title {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    max-width: 275px;
    width: 100%;

    .live-chat-avatar {
      height: 24px;
      width: 24px;
    }

    &__count {
      margin-left: auto;
      font-size: 12px;
      line-height: 24px;
      color: rgba(255, 255, 255, 0.5);

      & > span {
        font-weight: bold;
      }
    }
  }

  .live-chat-footer-message {
    display: flex;

    ::-webkit-scrollbar {
      width: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background-clip: padding-box;
    }

    &__textarea {
      width: 275px;
      position: relative;
      margin-right: 10px;

      .main-input {
        padding: 6px 25px 5px 10px;
        margin-bottom: 0;
        font-size: 13px;
        line-height: 20px !important;
        font-weight: 500;
        min-height: 40px;
        max-height: 140px;
        overflow-y: scroll;
        overflow-x: hidden;
        overflow-wrap: break-word;
        outline: none;
        position: relative;
        word-break: break-word;

        &:empty::before {
          content: attr(data-placeholder);
          font-size: 13px;
          line-height: 20px;
          color: rgba(255, 255, 255, 0.25);
        }
      }
    }

    &__textarea-icon {
      color: rgba(255, 255, 255, 0.3);
      position: absolute;
      margin-left: -30px;
      bottom: 12px;
      font-size: 18px;
      cursor: pointer;
      right: 15px;

      &:hover {
        color: rgba(255, 255, 255, 0.5);
      }
    }
  }

  .main-button {
    height: 40px;
    width: 40px;
    background: #4f59d9;
    border-radius: 50% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.5;
    padding: 0;
    align-self: flex-end;

    &:hover {
      opacity: 1;
    }

    .icon-chevron-left {
      color: $black;
      padding-right: 3px;
      transform: rotate(-180deg);
      font-size: 20px;
    }
  }
}
