@import "sass/variabiles";

.youtube-list {
  position: relative;
  background-color: #1b1b28;

  .import-heading {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    padding: 30px;

    .heading {
      margin: 0 25px 0 0;
      font-weight: 500;
      font-size: 29px;
      padding: 0;
    }
  }

  .playlist-title {
    padding-left: 22px !important;
  }

  .main-heading {
    margin-bottom: 35px;
  }

  .import-footer {
    padding: 50px 25px;

    .main-button {
      display: inline-flex;
      width: auto;
    }
  }

  .playlists-list {
    flex-direction: column;
    margin: 0;
  }

  .collapse-all {
    text-align: right;

    .action-link {
      color: $blue;
      font-size: 16px;
      line-height: 24px;
    }
  }
}

.heading-actions {
  display: flex;
  flex-direction: row;
  align-items: center;

  .action-item {
    display: inline-flex;
    margin-right: 25px;

    .select-all-option {
      margin: 0;
    }
  }

  .item-badge {
    padding: 10px 22px;
    background: rgba(#ffffff, 0.05);
    border-radius: 50px;
    font-weight: 800;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #ffffff;
    position: relative;

    &.active {
      background: $blue;
    }
  }
}
