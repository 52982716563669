@import "sass/variabiles";

.related-video-list-item {
  display: inline-flex;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  margin: 0 15px 20px;

  & > .thumb {
    display: inline-flex;
    position: relative;
    width: 160px;
    height: 100px;
    margin-right: 18px;
    border-radius: 6px;
    background: linear-gradient(360deg, rgba(4, 6, 43, 0.75) 11.7%, rgba(5, 6, 28, 0.5) 94.79%);

    .video-image {
      position: absolute;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      border-radius: 6px;
    }

    & > .duration {
      position: absolute;
      bottom: 5px;
      right: 5px;
      font-size: 12px;
      font-weight: 400;
      line-height: 17px;
      height: 19px;
      padding: 2px 4px 18px;
      background-color: #575869;
      border-radius: 4px;
      opacity: 0.8;
      color: #ffffff;
    }
  }

  & > .details {
    display: inline-flex;
    flex-direction: column;
    width: calc(100% - 149px);

    & > .title {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 5px;
      max-height: 2.4em;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
      color: #ffffff;
      text-decoration: none;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    > .video-stats {
      font-size: 14px;
      margin-bottom: 6px;
      opacity: 0.5;
      color: $white;

      .icon-glasses {
        font-size: 22px;
        position: relative;
        top: 4px;
      }

      i {
        padding-right: 6px;

        &:nth-of-type(2) {
          padding-left: 10px;
        }
      }
    }

    & > .user-details {
      display: flex;
      flex-direction: row;
      line-height: 22px;
      cursor: pointer;
      color: #ffffff;

      & > .avatar {
        width: 22px;
        height: 22px;
        margin-right: 5px;
        border-radius: 50%;
        background-color: #232632;
        background-size: cover;
        background-position: center;
        position: relative;

        .name-letters {
          font-size: 10px;
        }

        & > img {
          position: relative;
          top: -1px;
          left: 0;
          border-radius: 50%;
        }
      }

      & > .user-name {
        font-size: 14px;
        font-weight: 400;
        opacity: 0.5;
      }
    }
  }
}
