@import "sass/variabiles";

.filters-toggle {
  width: 35px;
  height: 35px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 35px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover,
  &.active {
    background: #4f59d9;
    color: $white;
  }

  i {
    font-size: 20px;
  }
}
