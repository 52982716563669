@import "sass/variabiles";

.video-post-item {
  position: relative;
  border-radius: 6px;
  min-height: 330px;
  overflow: hidden;
  margin-bottom: 30px;
  transition: transform 0.3s ease;
  transform: scale(1);
  @media only screen and (max-width: $mobile) {
    border-radius: 7px;
    background: none;
    max-width: 290px;
    transform: none;
    margin: 0 auto;
  }

  .action-info {
    position: relative;
    z-index: 4;
    @media only screen and (max-width: $mobile) {
      display: none;
    }
  }

  .click-post-area {
    cursor: pointer;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    height: 180px;
  }

  .action-info {
    position: relative;
    z-index: 4;
  }

  .click-post-area {
    cursor: pointer;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    height: 180px;
  }

  &:hover {
    transform: scale(1.04);
    @media only screen and (max-width: $mobile) {
      transform: none;
    }
  }

  .post-image {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 168px;
    background-color: #41415a;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .content {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 30px;
    background: linear-gradient(
      1turn,
      #111426 30.95%,
      #111426 49.39%,
      rgba(22, 25, 61, 0.03) 100.11%
    );
    top: 0;

    .icon-bookmark {
      cursor: pointer;
    }

    .main-info {
      height: 100px;
      width: 100%;
      margin-top: 105px;
      display: inline-flex;
      flex-direction: column;
      padding-bottom: 7px;
      justify-content: center;
      @media only screen and (max-width: $mobile) {
        margin-top: 111px;
        padding-top: 23px;
      }

      &.live {
        height: 218px;

        .title {
          text-align: left;
        }
      }

      .category {
        font-size: 16px;
      }

      .title {
        margin: 0;
        font-size: 22px;
        line-height: 34px;
        width: 100%;
        font-weight: $bold;
        word-break: break-word;
        padding-top: 5px;
        text-align: center;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        @media only screen and (max-width: $mobile) {
          padding: 0;
          font-style: normal;
          font-weight: bold;
          font-size: 19px;
          line-height: 28px;
          text-align: center;
          color: #ffffff;
        }
      }
    }

    .date,
    .duration {
      font-size: 16px;
      color: #9f9fa9;

      i {
        font-size: 22px;
        position: relative;
        top: 2px;
      }

      @media only screen and (max-width: $mobile) {
        color: #fff;
        opacity: 0.5;
        font-size: 16px;
        line-height: 24px;
        position: absolute;
        right: 18px;
        bottom: 34px;
      }
    }

    .user-bottom {
      @media only screen and (max-width: $mobile) {
        position: absolute;
        bottom: 30px;
        height: 33px;
        padding: 0;
        left: 20px;
      }

      a {
        font-size: 14px;
        line-height: 22px;
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        color: $white;

        > span {
          opacity: 0.5;
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          min-width: 100px;
          word-break: break-word;
          width: calc(100% - 50px);
          @media only screen and (max-width: $mobile) {
            opacity: 1;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            width: 135px;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }
        }

        &:hover {
          > span {
            opacity: 0.3;
          }
        }
      }

      .avatar {
        width: 32px;
        height: 32px;
        background-position: center;
        background-size: cover;
        border-radius: 50%;
        margin-right: 10px;
        position: relative;
        @media only screen and (max-width: $mobile) {
          width: 33px;
          height: 33px;
        }

        > span {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          width: 100%;
          height: 100%;
          font-weight: $bold;
          font-size: 14px;
        }
      }
    }
  }

  .post-heading-counts {
    list-style-type: none;
    padding: 0;
    margin-top: -3px;
    @media only screen and (max-width: $mobile) {
      position: absolute;
      top: 30px;
      left: 20px;
    }

    .live-btn {
      margin-right: 30px;
      @media only screen and (max-width: $mobile) {
        display: block !important;
      }
    }

    li {
      font-size: 16px;
      display: inline-block;
      padding-right: 14px;

      i {
        font-size: 18px;
        position: relative;
        top: 3px;
        padding-right: 5px;

        &.icon-glasses {
          font-size: 26px;
          top: 5px;
        }
      }
    }
  }

  &.live {
    .post-image {
      position: absolute;
      height: 100%;
    }

    .content {
      background: linear-gradient(360deg, rgba(4, 6, 43, 0.75) 11.7%, rgba(5, 6, 28, 0.5) 94.79%);
    }
  }

  .play-button {
    margin-top: 70px;
    height: 32px;
    width: 32px;
    line-height: 32px;
    border-radius: 50%;
    background: $white;
    text-align: center;
    position: absolute;
    left: 50%;
    margin-left: -16px;
    cursor: pointer;
    z-index: 99;
    transition: all 0.3s ease;
    &:hover {
      transform: scale(1.2);
    }

    > i {
      position: relative;
      left: 2px;
      color: #5986ff;
      font-size: 14px;
    }

    &.live {
      background: transparent;
      border: 1px solid $white;
      height: 80px;
      width: 80px;
      margin-left: -40px;
      padding-top: 19px;
      padding-left: 4px;
      margin-top: 50px;

      > i {
        color: $white;
        font-size: 40px;
      }
    }

    @media only screen and (max-width: $mobile) {
      height: 50px;
      width: 50px;
      line-height: 50px;
      margin: 0;
      left: 50%;
      top: 115px;
      transform: translate(-50%, -50%) !important;
      > i {
        font-size: 22px;
        left: 3px;
        top: 4px;
      }
    }
  }
}

.posts-list {
  .playlist-post-block {
    @media only screen and (max-width: $mobile) {
      padding: 0;
    }
  }
  &-item {
    @media only screen and (max-width: $mobile) {
      .video-post-item.live {
        .post-image {
          position: relative !important;
          top: 0;
          left: 0;
          width: 100%;
          height: 168px !important;
          background-color: #41415a;
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
        }
        .content {
          position: absolute;
          width: 100%;
          height: 100%;
          padding: 30px;
          background: linear-gradient(
            1turn,
            #111426 30.95%,
            #111426 49.39%,
            rgba(22, 25, 61, 0.03) 100.11%
          ) !important;
          top: 0;
          .play-button.live {
            height: 50px;
            width: 50px;
            line-height: 50px;
            margin: 0;
            left: 50%;
            top: 115px;
            transform: translate(-50%, -50%) !important;
            background: #fff;
            padding: 0;
            .icon-play {
              font-size: 22px;
              left: 3px;
              top: 3px;
              color: #5986ff;
            }
          }
          .main-info.live {
            margin-top: 111px;
            padding-top: 23px;
            height: 100px;
            width: 100%;
            display: inline-flex;
            flex-direction: column;
            padding-bottom: 7px;
            justify-content: center;
            text-align: center;
            .title {
              text-align: center;
            }
          }
          .live-btn {
            .duration {
              position: relative;
              right: 0;
              top: 0;
            }
          }
        }
      }
    }
  }
}
