@import "sass/variabiles";

.stream-manager-page {
  padding: 15px 30px 60px;

  .vjs-time-control {
    display: none !important;
  }

  .controls-list {
    align-items: center;
    .action-stream-control {
      margin-right: 0 !important;
    }
  }

  .video-header {
    width: 100%;
  }

  .live-stats-coll {
    display: inline-flex;
    flex-direction: row;
    opacity: 0.5;
    padding-left: 0;
    -webkit-transition: all 0.6s ease;
    -moz-transition: all 0.6s ease;
    -ms-transition: all 0.6s ease;
    -o-transition: all 0.6s ease;
    transition: all 0.6s ease;

    &.active {
      opacity: 1;
    }

    .stream-live-btn {
      border: 1px solid #a83b62;
      font-weight: 800;
      margin-right: 10px;
      padding: 1px 13px;
      text-transform: uppercase;
      color: #e42267;
      font-size: 12px;
      letter-spacing: 2px;
      border-radius: 6px;

      i {
        color: #fff;
        padding-right: 7px;
        font-size: 15px;
        position: relative;
        top: 2px;
      }

      span {
        color: #fff;
        padding-left: 3px;
        letter-spacing: 1px;
        font-weight: 400;
        min-width: 40px;
        display: inline-block;
      }

      &.inactive {
        opacity: 0.5;
      }
    }

    .watchers {
      padding-left: 15px;
      padding-top: 2px;

      i {
        font-size: 22px;
        position: relative;
        top: 3px;
        padding-right: 10px;
      }
    }
  }

  .connection-settings {
    display: inline-flex;
    flex-direction: row;

    .circle-status {
      width: 12px;
      height: 12px;
      border-radius: 12px;
      margin-right: 10px;
      position: relative;
      top: 9px;

      &.good {
        background: #22a180;
      }
    }

    .label-status {
      font-weight: 800;
      font-size: 12px;
      letter-spacing: 2px;
      margin-right: 15px;
      text-transform: uppercase;
    }

    .status-info {
      font-size: 14px;
      margin-right: 15px;

      i {
        font-size: 14px;
      }
    }

    .edit-stream {
      font-size: 20px;
      position: relative;
      top: 5px;
      cursor: pointer;
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }

    .visibility-status {
      background-color: $white;
      padding: 2px 13px;
      color: $black;
      border-radius: 5px;
      margin-left: 15px;
      margin-top: -3px;
      font-size: 16px;

      i {
        position: relative;
        top: 1px;
        padding-right: 8px;
      }
    }
  }

  .video-placeholder {
    position: relative;
    height: 0;
    padding-top: 56.25%;
    background: #000;
    border-radius: 6px;

    .content-manager {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      > p {
        max-width: 605px;
        font-size: 18px;
        line-height: 32px;
        text-align: center;
      }

      .lds-ring {
        left: calc(50% - 30px);
        top: calc(50% - 30px);
        width: 60px;
        height: 60px;
        border: 5px solid rgba(33, 33, 33, 0.74);
        border-top: 5px solid #4f59d9;
      }

      &.active-manager {
        p {
          margin-top: 90px;
        }

        .lds-ring {
          margin-top: -60px;
        }
      }
    }
  }

  .stream-action-btn {
    padding: 2px 19px;

    i {
      padding-right: 12px;
      top: 0;
      font-size: 13px;
    }

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}
