@import "sass/variabiles";

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 56px;
  height: 24px;
  margin: 0;

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked {
      + .slider {
        background-color: $blue;
      }
    }

    &:focus {
      + .slider {
        box-shadow: 0 0 0 4px rgba(21, 156, 228, 0.7);
        outline: none;
      }
    }

    &:checked {
      + .slider {
        &:before {
          transform: translateX(32px);
        }
      }
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.2);
    transition: 0.4s;

    &.round {
      border-radius: 24px;

      &:before {
        border-radius: 50%;
      }
    }

    &:before {
      position: absolute;
      content: "";
      height: 16px;
      width: 16px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      transition: 0.4s;
    }
  }
}
