.youtube-video-list-item {
  display: inline-flex;
  justify-content: flex-start;
  flex-direction: row;
  height: 145px;
  margin: 0 35px;
  padding: 30px 0;
  border-bottom: 1px solid rgba(#ffffff, 0.1);

  & > .checbox-wrap {
    display: inline-flex;
    line-height: 80px;
  }

  & > .thumb {
    display: inline-flex;
    position: relative;
    width: 140px;
    height: 85px;
    border-radius: 6px;
    margin-right: 23px;
    background: linear-gradient(
      360deg,
      rgba(4, 6, 43, 0.75) 11.7%,
      rgba(5, 6, 28, 0.5) 94.79%
    );
  }

  & > .details {
    display: inline-flex;
    flex-direction: column;
    width: calc(100% - 149px);
    color: #ffffff;

    & > .title {
      font-weight: 700;
      font-size: 16px;
      line-height: 16px;
      max-height: 1.2em;
      overflow: hidden;
      text-overflow: ellipsis;
      text-decoration: none;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }

    & > .description {
      padding: 0;
      margin-top: 13px;
      text-align: left;
      line-height: 24px;
      font-size: 14px;
      color: #72818a;
      max-height: 3.6em;
      overflow: hidden;
      text-overflow: ellipsis;
      text-decoration: none;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}
