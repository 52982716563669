@import "sass/variabiles";

.user-following {
  width: 100%;
  margin-top: 20px;
  position: relative;
  min-height: 350px;

  .videos-coll,
  .followers-coll,
  .action-coll {
    width: 120px;
    text-align: center;
  }

  .videos-coll {
    width: 100px;
  }

  .followers-coll {
    width: 160px;
  }

  .action-coll {
    width: 120px;
  }

  .heading-table {
    padding-bottom: 18px;
    margin-bottom: 13px;
    margin-right: 0;
    position: relative;

    &:after {
      content: "";
      background-color: rgba(255, 255, 255, 0.15);
      height: 1px;
      width: calc(100% - 16px);
      left: 8px;
      bottom: 0;
      position: absolute;
    }
  }

  .heading-option {
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #72818a;
    font-weight: 700;
    font-size: 12px;
    padding-top: 4px;
    line-height: 24px;
  }

  .following-list {
    .following-item {
      margin-right: 0;
      padding: 6px 0;

      .videos-coll,
      .followers-coll,
      .action-coll {
        font-size: 15px;
        line-height: 32px;
        color: #72818a;
        display: inline-flex;
        justify-content: center;
        align-items: center;
      }

      .videos-coll {
        color: $white;
      }

      .followers-coll {
        i {
          color: $white;
          padding-right: 10px;
          font-size: 24px;
        }
      }

      .channel-item {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .avatar {
          display: inline-flex;
          justify-content: center;
          border-radius: 50%;
          margin-left: 0;
          margin-bottom: 2px;
          line-height: 8px;
          font-size: 10px;
          width: 64px;
          height: 64px;
          cursor: pointer;
          background-position: center;
          background-size: cover;
          margin-right: 20px;
          overflow: hidden;
          position: relative;

          .link-to-channel {
            position: absolute;
            left: 0;
            top: 0;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.7);
            transition: all 0.3s ease;
            opacity: 0;

            &:hover {
              opacity: 1;
            }

            i {
              color: $white;
              font-size: 25px;
            }
          }
          .name-letters {
            font-size: 24px;
            color: $white;
          }
        }

        .channel-info {
          width: calc(100% - 84px);

          .title {
            font-weight: $bold;
            font-size: 16px;
            line-height: 24px;
            margin: 13px 0 4px;
            color: $white;
            &:hover {
              color: $blue;
            }
          }

          .description {
            font-size: 14px;
            color: #72818a;
            line-height: 24px;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }
        }
      }

      .follow-action {
        &.following {
          background-color: $blue;
          border: 1px solid $blue;
          color: $white;

          i {
            padding-right: 8px;
            font-size: 12px;
          }
        }

        &:hover {
          background-color: $blue;
          border: 1px solid $blue;
        }
      }
    }
  }
}

.empty-following {
  position: relative;
  margin-top: 16%;
  text-align: center;
  transform: translateY(-50%);
  margin-bottom: 150px;
  @media only screen and (max-width: $mobile) {
    margin-top: 100px;
  }

  > i {
    font-size: 70px;
    display: inline-block;
    padding-bottom: 42px;
    color: #3c3d4c;
  }

  .empty-message {
    margin-bottom: 32px;
    color: #72818a;
    font-size: 22px;
  }
}
