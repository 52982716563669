@import "sass/variabiles";

.sign-in-container {
  .col-sm-6 {
    padding: 0 8px 0 8px;
  }

  .heading {
    text-align: center;
    font-weight: 500;
    line-height: 44px;
    font-size: 44px;
    padding: 15px 0;
  }

  .main-button {
    margin-top: 0;
  }

  .break-line {
    background: rgba(255, 255, 255, 0.2);
    height: 1px;
    margin: 45px 0;
    border: none;

    &:before {
      content: "or";
      background: #050713;
      position: absolute;
      text-align: center;
      left: 50%;
      width: 70px;
      margin-left: -35px;
      margin-top: -17px;
    }
  }

  .bottom-buttons {
    margin-bottom: 40px;
  }

  .forgot-link {
    color: $white;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    margin-top: 25px;

    &:hover {
      color: $blue;
    }
  }
}

.auth-bottom-info {
  text-align: center;
}

.sign-up {
  padding: 5px 0;
  text-align: center;

  .pop-content-container {
    padding-top: 0;
  }

  .heading {
    font-weight: 500;
    line-height: 44px;
    font-size: 44px;
    padding: 0 90px;
  }

  .description {
    width: 100%;
    max-width: 360px;
    margin: 40px auto 55px;
  }
}

.sign-up-options {
  padding-left: 0;

  li {
    list-style-type: none;
    padding-bottom: 20px;

    .icon-button {
      min-width: 365px;

      .text-side {
        width: 300px;
      }
    }
  }
}

.sign-up-mail-form {
  .pop-content-container {
    padding: 45px 63px;

    .main-button {
      margin-top: 20px;
    }
  }
}

.form-container {
  width: 535px;
  padding-top: 20px;

  .heading {
    font-weight: 500;
    line-height: 44px;
    font-size: 44px;
    padding: 0 90px;
    text-align: center;
  }

  .description {
    padding-top: 35px;
    text-align: center;
  }
}

.arrow-back {
  position: absolute;
  top: 45px;
  left: 65px;
  color: $white;
  font-size: 20px;
  cursor: pointer;
  @media only screen and (max-width: $mobile) {
    top: 30px;
    z-index: 100;
    left: 30px;
    font-size: 20px;
    cursor: pointer;
    opacity: 0.25;
  }
}

.pop-content-container {
  padding: 45px 93px;

  .main-button {
    width: 100%;
    margin-top: 12px;
  }
}

.login-register-action {
  padding-top: 25px;
  font-weight: $regular;
  font-size: 18px;

  span {
    color: #4f59d9;
    cursor: pointer;
    text-decoration: none;
    font-weight: $bold;
    padding-left: 12px;
  }
}

.success-sign-up {
  text-align: center;
  width: 535px;

  .heading {
    font-weight: 500;
    line-height: 44px;
    font-size: 44px;
    padding: 30px 15px 10px;
  }

  .description {
    width: 100%;
    max-width: 360px;
    margin: 40px auto 55px;
    padding: 10px 10px 20px;
  }

  a {
    text-decoration: none;
  }

  .main-button {
    padding: 13px 30px;
  }
}

.forgot-password {
  .pop-content-container {
    padding: 55px 93px 75px;
  }

  .description {
    padding: 45px 90px 0;
  }
}

.welcome-modal {
  .pop-content-container {
    padding: 32px 40px 35px;
    position: relative;

    .heading {
      text-align: left;
      font-size: 31px;
      line-height: 44px;
      padding: 0;
      margin: 0;
    }

    .description {
      text-align: left;
      padding: 25px 0 0;
      margin: 0;

      a {
        color: $blue;
        text-decoration: none;

        &:hover {
          opacity: 0.7;
        }
      }
    }

    .main-button {
      max-width: 165px;
      display: inline-block;
      margin-top: 33px;
      margin-right: 38px;
    }

    .skip-link {
      display: inline-block;
      color: $blue;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .basic-filter-container {
    margin-top: 28px;
    border-radius: 6px;
    background: rgba(255, 255, 255, 0.08);
    padding: 27px;

    .name {
      font-weight: $bold;
      font-size: 16px;
      line-height: 22px;
    }
  }
}

.right-header-options {
  padding-top: 3px;
  padding-left: 0;
  display: flex;
  float: right;
  align-items: center;

  > li {
    display: inline-block;
    padding-left: 40px;

    .main-button {
      &:hover {
        i {
          color: $white;
        }
      }
    }

    i:hover {
      color: $blue;
    }
  }

  .news-container {
    position: relative;

    .icon-news {
      font-size: 24px;
      position: relative;
      top: 4px;
      cursor: pointer;
    }

    .news-context-menu {
      position: absolute;
      right: 0;
      top: 50px;
      box-shadow: 0px 0px 20px 6px rgba(0, 0, 0, 0.75);
      border-radius: 6px;
      display: none;

      &[data-visible="true"] {
        display: block;
      }

      iframe {
        border: none;
        border-radius: 6px;
        height: calc(100vh - 168px);
        width: 316px;
        max-width: 100vw;
        visibility: hidden;

        &[data-loaded="true"] {
          visibility: visible;
        }
      }
    }
  }

  &.logged-options {
    li {
      padding-left: 24px;

      > i {
        font-size: 22px;
        position: relative;
        top: 2px;
        cursor: pointer;
      }

      .icon-news {
        font-size: 24px;
        position: relative;
      }

      .tiny-button {
        padding: 5px 25px;
      }

      .profile-menu-trigger {
        position: relative;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background-color: #232632;
        margin-bottom: 4px;
        cursor: pointer;

        & > img {
          border-radius: 50%;
          position: absolute;
          top: 0;
          left: 0;
        }

        .trigger-avatar {
          position: absolute;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          background-size: cover;
          background-position: center;
          cursor: pointer;
          @media only screen and (max-width: $mobile) {
            display: none;
          }
        }

        .trigger-avatar.mobile {
          display: none;
          @media only screen and (max-width: $mobile) {
            display: block;
            position: relative;
            &::after {
              content: "";
              display: block;
              position: absolute;
              top: 22px;
              right: 0px;
              width: 10px;
              height: 10px;
              border-radius: 50%;
              background-color: #3ba894;
            }
          }
        }

        .name-letters {
          font-size: 13px;
          font-weight: $bold;
          display: inline-block;
          width: 98%;
          text-align: center;
          position: relative;
          top: -1px;
        }

        .profile-context-menu {
          position: absolute;
          background-color: #1b1b28;
          box-shadow: 0px 0px 20px 6px rgba(0, 0, 0, 0.75);
          flex-direction: column;
          display: flex;
          padding: 20px 25px;
          border-radius: 6px;
          font-size: 12px;
          width: max-content;
          min-width: 150px;
          right: 0;
          top: 50px;
          z-index: 999;

          .name-letters {
            font-size: 13px;
            font-weight: $bold;
            display: inline-block;
            width: 98%;
            text-align: center;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
          }

          & > .list-group {
            border-bottom: 1px solid rgba(#ffffff, 0.15);
            padding: 5px 0 5px 2px;
            display: inline-flex;
            flex-direction: column;

            &.menu-group,
            &.create-channel-group,
            &.logout-group {
              margin-bottom: 0;
            }

            &.create-channel-group {
              color: #3ba894;
            }

            &.create-channel-group.disabled {
              opacity: 0.3;
              pointer-events: none;
              cursor: auto;
            }

            .logout {
              margin-bottom: 0;
            }

            &:last-child {
              border-bottom: none;
            }
          }

          .menu-list-item {
            display: inline-flex;
            flex-direction: row;
            line-height: 20px;
            padding-left: 0;
            width: 100%;
            margin: 5px 0;
            height: 20px;
            cursor: pointer;
            user-select: none;

            > a {
              color: $white;
              text-decoration: none;
            }

            &:hover {
              opacity: 0.7;
            }

            &.profile {
              margin-bottom: 15px;
              cursor: default;
              opacity: 1 !important;

              & > .avatar {
                width: 32px;
                height: 32px;
                border-radius: 50%;
                margin-right: 10px;
                background-size: cover;
                background-position: center;

                & > img {
                  border-radius: 50%;
                }
              }

              & > .user-name {
                font-weight: 700;
                line-height: 32px;
              }
            }

            &.visibility-status > .dot {
              width: 8.5px;
              height: 8.5px;
              border-radius: 50%;
              margin-right: 10px;
              margin-top: 5px;

              &.online {
                background-color: #3ba894;
              }

              &.busy {
                background-color: #de832f;
              }

              &.no-disturb {
                background-color: #ee4985;
              }
            }

            &.logout {
              color: #ee4985;
            }
          }
        }
      }
    }
  }
}

.isolated-break-line {
  position: relative;
}

.form-container.sign-up-mail-form {
  @media only screen and (max-width: $mobile) {
    padding: 20px 30px 60px 30px;
    width: 100%;
    .heading {
      margin: 0;
      padding: 0;
      font-weight: 500;
      font-size: 23px;
      line-height: 34px;
      text-align: center;
      color: #ffffff;
    }
    .pop-content-container {
      padding: 26px 0 0 0;
    }
  }
}

.form-container.sign-up {
  @media only screen and (max-width: $mobile) {
    padding: 25px 30px 58px 30px;
    width: 100%;
    .heading {
      font-weight: 500;
      font-size: 23px;
      line-height: 34px;
      text-align: center;
      color: #ffffff;
      padding: 0 30px;
      margin: 0;
    }
    .pop-content-container {
      padding: 0;

      .description {
        padding: 0;
        margin: 6px auto 32px auto;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #ffffff;
      }

      .sign-up-options {
        width: 100%;
        margin-bottom: 0;

        li {
          button {
            width: 100%;
            min-width: auto;
            border-radius: 7px;

            .icon-side {
              padding: 11px;

              img {
                width: 20px;
                height: 20px;
                object-fit: contain;
              }
            }

            .text-side {
              font-weight: bold;
              padding: 8px 13px;
              font-size: 19px;
              line-height: 34px;
              color: #ffffff;
            }
          }
        }
      }

      .login-register-action {
        margin: 0;
        padding: 0;
        width: 100%;
        font-weight: normal;
        font-size: 16px;
        line-height: 32px;
        text-align: center;
        color: #ffffff;

        span {
          font-size: 16px;
          line-height: 32px;
          padding-left: 19px;
          text-align: center;
        }
      }

      .terms-conditions {
        padding: 0 15px;
        margin: 44px 0 0 0;
      }
    }
  }
}

.form-container.sign-in-container {
  .pop-content-container {
    .label-pass {
      position: relative;

      &::after {
        content: "\e936";
        font-family: "icomoon" !important;
        display: block;
        position: absolute;
        top: 12px;
        right: 17px;
        font-size: 16px;
        width: 16px;
        height: 16px;
      }
    }
  }

  @media only screen and (max-width: $mobile) {
    padding: 20px 30px 60px 30px;
    width: 100%;
    .heading {
      margin: 0;
      padding: 0;
      font-weight: 500;
      font-size: 23px;
      line-height: 34px;
      text-align: center;
      color: #ffffff;
    }
    .pop-content-container {
      padding: 26px 0 0 0;

      .main-input {
        font-size: 16px;
        padding: 13.5px 20px;
        border-radius: 7px;
        margin-bottom: 20px;
      }

      .forgot-link {
        margin: 0;
        font-weight: normal;
        font-size: 16px;
        line-height: 32px;
        color: #ffffff;
      }

      .main-button {
        background: #a83b62;
        border-radius: 7px;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #ffffff;
        margin: 0;
        padding: 6px 20px;
        max-width: 130px;
        float: right;
      }

      .bottom-buttons {
        margin-bottom: 20px;

        button {
          padding: 2.5px 20px;

          img {
            width: 20px;
            height: 20px;
            object-fit: contain;
          }
        }
      }

      .auth-bottom-info {
        .login-register-action {
          margin: 0;
          padding: 0;
          font-size: 16px;
          line-height: 32px;
        }

        .terms-conditions {
          margin: 44px 0 0 0;
          font-size: 16px;
          padding: 0 5px;
        }
      }
    }
  }
}

.mobile-profile {
  display: none;
  height: 0;
  transition: height 300ms;
  @media only screen and (max-width: $mobile) {
    display: block;
    &.active {
      height: 207px;
    }
    .mobile-profile-context-menu {
      display: flex;
      background-color: #06081d;
      width: 100%;
      flex-wrap: wrap;
      padding: 30px 30px 14px 30px;
      position: relative;

      .menu-top {
        width: 100%;
        display: flex;

        &-avatar {
          margin-right: 29px;

          .avatar {
            width: 36px;
            height: 36px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            .name-letters {
              font-size: 12px;
            }

            &:after {
              content: "";
              display: block;
              position: absolute;
              top: 28px;
              right: 2px;
              width: 10px;
              height: 10px;
              border-radius: 50%;
              background-color: #3ba894;
            }
          }
        }

        &-info {
          margin-top: -5px;

          p {
            font-size: 16px;
            margin: 0;
            line-height: 24px;
          }

          &-name {
            font-weight: bold;
          }
        }

        &-close {
          display: flex;
          position: absolute;
          top: 30px;
          right: 30px;

          .icon-close-rounded {
            cursor: pointer;
            color: rgba(255, 255, 255, 0.25);
            font-size: 20px;
          }
        }
      }

      .menu-main {
        display: flex;
        flex-wrap: wrap;
        border-top: 1px solid rgba(255, 255, 255, 0.25);
        width: 100%;
        margin: 14px 0 0 0;
        padding: 13px 0 0 0;

        &-item {
          width: 50%;
          height: 46px;
          display: flex;
          align-items: center;

          @media screen and (max-width: $mobile) {
            &.disabled {
              opacity: 0.5;
              pointer-events: none;
            }
          }

          a {
            font-weight: normal;
            font-size: 16px;
            line-height: 46px;
            color: #ffffff;
          }

          span {
            color: #ee4985;
            cursor: pointer;
          }

          &:nth-child(odd) {
            justify-content: flex-start;
          }

          &:nth-child(even) {
            justify-content: flex-end;
          }
        }
      }
    }
  }
}
