@import "sass/variabiles";

.user-profile {
  .account-image {
    .owner-avatar {
      margin: 0 auto;
    }
  }

  & > .account-heading {
    .following-list {
      .following-item {
        .following-item-mobile {
          display: none;
        }
      }
    }
    @media only screen and (max-width: $mobile) {
      .left-side {
        padding: 0 !important;
        width: 100% !important;
        max-width: 100% !important;
      }
      .account-details {
        h2.account-name,
        .details-col,
        .description {
          display: none !important;
        }
        .nav-tabs.row {
          display: block !important;
          position: relative;
          border-bottom: 2px solid rgba(255, 255, 255, 0.5);
          max-width: 222px;
          margin: 21px auto 0 auto !important;
          padding-bottom: 4px;
          .nav-tab.active {
            font-weight: bold;
          }
          .nav-tab {
            padding-bottom: 10px;
          }
        }

        .user-following {
          .heading-table {
            display: none !important;
          }
          .following-list {
            .following-item {
              .name-coll,
              .videos-coll,
              .followers-coll,
              .action-coll {
                display: none;
              }
              .following-item-mobile {
                display: flex;
                justify-content: center;
                width: 100%;
                margin-bottom: 30px;
                .item-avatar {
                  width: 102px;
                  .link-to-channel {
                    width: 72px;
                    height: 72px;
                    display: block;
                    text-decoration: none;
                    margin-right: 72px;
                    .avatar {
                      width: 72px;
                      height: 72px;
                      border-radius: 50%;
                      overflow: hidden;
                      background-position: center;
                      background-size: cover;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      .name-letters {
                        font-size: 30px;
                      }
                    }
                  }
                }
                .item-info {
                  width: calc(100% - 102px);
                  display: flex;
                  justify-content: flex-start;
                  flex-wrap: wrap;
                  margin-top: -6px;
                  max-width: 200px;
                  &-title {
                    width: 100%;
                    height: 24px;
                    display: flex;
                    .link-to-channel {
                      text-decoration: none;
                      font-weight: bold;
                      font-size: 16px;
                      line-height: 24px;
                      color: #ffffff;
                      word-break: break-word;
                      display: -webkit-box;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      -webkit-line-clamp: 2;
                      -webkit-box-orient: vertical;
                    }
                  }
                  &-count {
                    display: flex;
                    width: 100%;
                    margin: 1px 0 6px 0;
                    &-item {
                      width: 85px;
                      display: flex;
                      align-items: center;
                      height: 26px;
                      i {
                        font-size: 17px;
                        margin-right: 7px;
                      }
                      span {
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 24px;
                        color: #ffffff;
                        opacity: 0.5;
                      }
                    }
                  }
                  &-btn {
                    .round-action-button {
                      padding: 5px 12px;
                    }
                  }
                }
              }
              // display: flex;
              // justify-content: flex-start;
              // position: relative;
              // .name-coll{
              //   padding: 0;
              //   .channel-item{
              //     align-items: flex-start;
              //     margin-right: 30px;
              //     .avatar{
              //       width: 72px;
              //       height: 72px;
              //       margin: 0;
              //     }
              //     .channel-info{
              //       .link-to-channel{
              //         position: absolute;
              //       }
              //       .description{
              //         display: none;
              //       }
              //     }
              //   }
              // }
            }
          }
        }
      }
    }
  }

  .details-col {
    margin: 0 0 10px !important;

    i {
      padding-left: 12px;

      &:first-child {
        padding-left: 0;
      }
    }
  }

  .streamer-details {
    .avatar {
      display: inline-flex;
      justify-content: center;
      border-radius: 50%;
      margin-left: 10px;
      line-height: 8px;
      font-size: 10px;
      width: 20px;
      height: 20px;
      cursor: pointer;
      background-position: center;
      background-size: cover;
      position: relative;
      top: 4px;

      .name-letters {
        position: absolute;
        top: 7px;
        font-size: 10px;
        color: #fff;
      }
    }

    .channel-name {
      color: #5986ff;
      padding-left: 10px;
      &:hover {
        opacity: 0.7;
      }
    }
  }
}
