@import "sass/variabiles";

.dm-directory-category-group {
  visibility: visible;
}

.dm-directory-category-group-name {
  font-size: 12px;
  font-weight: 800;
  line-height: 24px;
  letter-spacing: 2px;
  color: #ffffff;
  opacity: 0.35;
  text-transform: uppercase;
  margin: 20px 0;
}

.dm-directory-category-name {
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  line-height: 34px;
  transition: opacity 0.3s;
  cursor: pointer;

  @media only screen and (max-width: $mobile) {
    display: inline-block;
    width: 100%;
    font-weight: bold;
    opacity: 1;
  }
}

.sub-items-list {
  display: none;
  padding-left: 30px;
  padding-top: 10px;

  @media (max-width: $mobile) {
    padding-left: 0 !important;
  }

  > li {
    font-size: 16px;
    color: $white;
    list-style-type: none;
    a {
      line-height: 30px;
      opacity: 0.5;

      &.active {
        opacity: 1;
      }

      &:hover {
        opacity: 1;
      }
    }
  }

  &.active {
    display: block;
  }
}

.dm-directory-category-icon {
  margin-right: 12px;
  color: #ee4985;
}
