@import "sass/variabiles";

.admin-tabs-coll {
  display: inline-flex;
  flex-direction: row;
  width: calc(100% - 395px);

  .nav-tabs .nav-tab {
    margin: 10px 20px 10px 0;
  }
}

.crashed-videos-list {
  .main-title {
    margin: 0;
    padding: 0 13px;
  }
}

.crash-videos-mass-edit-bar {
  margin-top: 25px;
  padding-left: 13px;
}

.admin-info-badge {
  background: #1b1b28;
  padding: 10px 20px;
  margin-left: 20px;
  margin-top: 10px;
  border-radius: 6px;
  font-size: 15px;

  &.crashed {
    background: rgba(164, 52, 98, 0.48);
    cursor: pointer;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;

    &:hover {
      background: rgba(164, 52, 98, 0.88);
    }
  }

  > i {
    padding-right: 10px;
    position: relative;
    top: 1px;
  }

  > span {
    background: $blue;
    border-radius: 20px;
    font-size: 15px;
    font-weight: $bold;
    padding: 2px 10px;
    margin-left: 10px;

    .total-value {
      font-size: 12px;
      position: relative;
      top: -1px;
    }
  }
}

.admin-settings {
  padding: 20px 8px;

  .videos-coll,
  .followers-coll,
  .action-coll {
    width: 120px;
    text-align: center;
  }

  .videos-coll {
    width: 100px;
  }

  .followers-coll {
    width: 160px;
  }

  .action-coll {
    width: 238px;

    .ban-btn {
      margin-right: 15px;
      position: relative;

      > .hover-btn-text {
        position: absolute;
        background: #ee4985;
        width: 100%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border-radius: 20px;

        height: 100%;
        left: 0;
        top: 0;
        opacity: 0;

        &:hover {
          opacity: 1;
        }
      }

      &.banned {
        color: #fff;
        border: 1px solid #a43462;
        background: #a43462;
      }
    }
  }

  .heading-table {
    padding-bottom: 18px;
    margin-bottom: 13px;
    margin-right: 0;
    position: relative;

    &:after {
      content: "";
      background-color: rgba(255, 255, 255, 0.15);
      height: 1px;
      width: calc(100% - 16px);
      left: 8px;
      bottom: 0;
      position: absolute;
    }
  }

  .heading-option {
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #72818a;
    font-weight: 700;
    font-size: 12px;
    padding-top: 4px;
    line-height: 24px;
  }

  .channel-item-container {
    margin-right: 0;
    padding: 6px 0;

    .videos-coll,
    .followers-coll,
    .action-coll {
      font-size: 15px;
      line-height: 32px;
      color: #72818a;
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }

    .action-coll {
      justify-content: flex-end;
      padding-right: 8px;
    }

    .videos-coll {
      color: $white;

      i {
        padding-right: 10px;
        font-size: 16px;
      }
    }

    .followers-coll {
      i {
        color: $white;
        padding-right: 10px;
        font-size: 24px;
      }
    }

    .channel-item {
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .avatar {
        display: inline-flex;
        justify-content: center;
        border-radius: 50%;
        margin-left: 0;
        margin-bottom: 2px;
        line-height: 8px;
        font-size: 10px;
        width: 64px;
        height: 64px;
        cursor: pointer;
        background-position: center;
        background-size: cover;
        margin-right: 20px;
        overflow: hidden;
        position: relative;

        .link-to-channel {
          position: absolute;
          left: 0;
          top: 0;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.7);
          transition: all 0.3s ease;
          opacity: 0;

          &:hover {
            opacity: 1;
          }

          i {
            color: $white;
            font-size: 25px;
          }
        }

        .name-letters {
          font-size: 24px;
          color: $white;
        }
      }

      .channel-info {
        width: calc(100% - 84px);

        .title {
          font-weight: $bold;
          font-size: 16px;
          line-height: 24px;
          margin: 13px 0 4px;
          color: $white;
        }

        .description {
          font-size: 14px;
          color: #72818a;
          line-height: 24px;
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
      }
    }
  }

  .app-pagination {
    padding-left: 15px;

    .pagination {
      justify-content: flex-end;

      .next {
        width: 28px;

        i {
          position: relative;
          left: 8px;
        }
      }
    }
  }
}

.channel-view-settings {
  position: relative;

  .channel-item-container {
    margin-bottom: 20px;
  }

  .filters-toggle {
    position: absolute;
    right: 0;
    top: 90px;
  }

  .app-filter-bar {
    background: #2c2c3a;
    margin-top: 0;

    .selected-filter-item {
      background: #535463;
    }
  }
  .channel-item {
    .avatar {
      width: 100px !important;
      height: 100px !important;
    }

    .channel-info {
      width: calc(100% - 120px) !important;
    }
  }

  .check-coll {
    width: 3.5%;
  }

  .video-coll {
    width: 41%;
    position: relative;
  }

  .visibility-coll {
    position: relative;
    width: 13%;
  }

  .category-coll {
    position: relative;
    width: 13%;
  }

  .date-coll {
    width: 10%;
  }

  .views-coll {
    width: 6%;
    position: relative;
    text-align: center;
  }

  .reactions-coll {
    text-align: center;
  }

  .heading-option {
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #72818a;
    font-weight: 700;
    font-size: 12px;
    padding-top: 4px;
    line-height: 24px;
  }

  .video-library-header {
    flex-direction: row;
    background-color: rgba($color: #ffffff, $alpha: 0.15);
    margin-bottom: 0;
    padding: 0 15px;
    border-radius: 6px;
    max-height: 0;
    color: #ffffff;
    overflow: hidden;
    position: relative;
    z-index: 99;
    animation: 0.4s fadeInFilter forwards ease;

    & > .control-item {
      display: inline-flex;
      font-size: 16px;
      margin: 0 22px;
      line-height: 20px;
      padding: 4px 0;
      cursor: pointer;

      .app-select__menu {
        min-width: 145px !important;
        text-align: left;

        .app-select__option {
          .custom-status-option {
            font-size: 16px;

            i {
              font-size: 20px;
              position: relative;
              top: 2px;
              padding-left: 10px;
            }
          }
        }
      }

      .app-select__single-value {
        i {
          padding-left: 6px;
          padding-right: 2px;
          position: relative;
          top: 2px;
        }
      }

      &.visibility-item {
        .app-select__single-value {
          position: relative;
          top: -2px;
        }
      }

      &.selected-items-count {
        background-color: #ffffff;
        font-weight: 800;
        color: #4f59d9;
        letter-spacing: 2px;
        align-items: center;
        border-radius: 20px;
        padding: 4px 22px;
        font-size: 12px;
      }

      &.delete-button {
        color: #ee4985;
        line-height: 23px;

        & > i {
          font-size: 20px;
          margin-right: 10px;
        }
      }
    }
  }

  .heading-videos {
    padding-left: 17px;
    margin-top: 30px;
  }

  .video-details-list {
    .info {
      > div {
        width: 100% !important;
      }
    }
  }

  & > .heading {
    flex-direction: row;
    padding-left: 10px;
    text-align: left;
    margin-bottom: 10px;
    margin-left: 0;
    font-size: 12px;
    text-transform: uppercase;
    border-bottom: 1px solid rgba($color: #ffffff, $alpha: 0.1);
  }

  .empty-admin-videos {
    position: relative;
    margin-top: 16%;
    text-align: center;
    transform: translateY(-50%);
    margin-bottom: 150px;

    > i {
      font-size: 70px;
      display: inline-block;
      padding-bottom: 42px;
      color: #3c3d4c;
    }

    .empty-message {
      margin-bottom: 32px;
      color: #72818a;
      font-size: 22px;
    }
  }

  .channel-item-container {
    .title {
      font-size: 28px !important;
      margin-bottom: 15px !important;
    }
  }

  .back-button-channel {
    cursor: pointer;
    width: 60%;
  }
}
