@import "sass/variabiles";

.auth-bottom-info {
  text-align: center;
}

.sign-up {
  padding: 5px 0;
  text-align: center;

  .pop-content-container {
    padding-top: 0;
  }

  .heading {
    font-weight: 500;
    line-height: 44px;
    font-size: 44px;
    padding: 0 90px;
  }

  .description {
    width: 100%;
    max-width: 360px;
    margin: 40px auto 55px;
  }
}

.sign-up-options {
  padding-left: 0;

  li {
    list-style-type: none;
    padding-bottom: 20px;

    .icon-button {
      min-width: 365px;

      .text-side {
        width: 300px;
      }
    }
  }
}

.sign-up-mail-form {
  .pop-content-container {
    padding: 45px 63px;

    .main-button {
      margin-top: 20px;
    }
  }
}

.form-container {
  width: 535px;
  padding-top: 20px;

  .heading {
    font-weight: 500;
    line-height: 44px;
    font-size: 44px;
    padding: 0 90px;
    text-align: center;
  }

  .description {
    padding-top: 35px;
    text-align: center;
  }
}

.arrow-back {
  position: absolute;
  top: 45px;
  left: 65px;
  color: $white;
  font-size: 20px;
  cursor: pointer;
  @media only screen and (max-width: $mobile) {
    top: 30px;
    z-index: 100;
    left: 30px;
    font-size: 20px;
    cursor: pointer;
    opacity: 0.25;
  }
}

.pop-content-container {
  padding: 45px 93px;

  .main-button {
    width: 100%;
    margin-top: 12px;
  }
}

.login-register-action {
  padding-top: 25px;
  font-weight: $regular;
  font-size: 18px;

  span {
    color: #4f59d9;
    cursor: pointer;
    text-decoration: none;
    font-weight: $bold;
    padding-left: 12px;
  }
}

.success-sign-up {
  text-align: center;
  width: 535px;

  @media screen and (max-width: $mobile) {
    width: 100%;

    .pop-content-container {
      padding: 46px 15px;
    }
  }
  .heading {
    font-weight: 500;
    line-height: 44px;
    font-size: 44px;
    padding: 30px 15px 10px;
  }

  .description {
    width: 100%;
    max-width: 360px;
    margin: 40px auto 55px;
    padding: 10px 10px 20px;
  }

  a {
    text-decoration: none;
  }

  .main-button {
    padding: 13px 30px;
  }
}

.forgot-password {
  .pop-content-container {
    padding: 55px 93px 75px;
  }
  .description {
    padding: 45px 90px 0;
  }
}

.welcome-modal {
  .pop-content-container {
    padding: 32px 40px 35px;
    position: relative;

    .heading {
      text-align: left;
      font-size: 31px;
      line-height: 44px;
      padding: 0;
      margin: 0;
    }

    .description {
      text-align: left;
      padding: 25px 0 0;
      margin: 0;

      a {
        color: $blue;
        text-decoration: none;

        &:hover {
          opacity: 0.7;
        }
      }
    }

    .main-button {
      max-width: 165px;
      display: inline-block;
      margin-top: 33px;
      margin-right: 38px;
    }

    .skip-link {
      display: inline-block;
      color: $blue;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .basic-filter-container {
    margin-top: 28px;
    border-radius: 6px;
    background: rgba(255, 255, 255, 0.08);
    padding: 27px;

    .name {
      font-weight: $bold;
      font-size: 16px;
      line-height: 22px;
    }
  }
}
