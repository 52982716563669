@import "sass/variabiles";

.filter-chooser {
  position: relative;
  z-index: 9;

  .filter-options {
    position: absolute;
    background: #181826;
    border: 1px solid #2e2e3b;
    margin-top: -6px;
    border-radius: 6px;
    list-style-type: none;
    min-width: 182px;
    overflow: hidden;
    padding: 0;

    > .option {
      padding: 6px 25px;
      cursor: pointer;
      line-height: 25px;
      font-size: 15px;
      color: #8b8b92;

      > i {
        position: absolute;
        cursor: pointer;
        top: 16px;
        right: 12px;
        font-size: 8px;

        &:hover {
          opacity: 0.4;
        }
      }

      &:first-child {
        background: #2f303f;
        cursor: default;
        color: $white;
        padding: 7px 25px;

        &:hover {
          background: #2f303f;
        }
      }

      &:hover {
        background: #3e3e4e;
      }
    }
  }
}
