@import "sass/variabiles";

.playlist-details {
  position: relative;
  padding-top: 35px;
  min-height: 400px;

  .playlist-main-heading {
    margin-bottom: 45px;

    .col-md-4 {
      padding-right: 20px;
    }

    .col-md-8 {
      padding-left: 16px;
    }

    .playlist-info {
      padding: 12px 0;
      margin: 0;

      li {
        list-style-type: none;
        display: inline-block;
        padding-right: 17px;
        font-size: 14px;
        opacity: 0.5;

        i {
          padding-right: 8px;
          font-size: 13px;

          &.icon-circle-play {
            font-size: 15px;
            position: relative;
            padding-right: 4px;
            top: 1px;
            width: 13px;
          }
        }
      }
    }

    .title-icon {
      font-size: 24px;
      padding-left: 8px;
      position: relative;
      top: -2px;
    }

    .title {
      font-size: 24px;
      line-height: 27px;
      font-weight: $regular;
      margin: 0;
      padding-left: 20px;
    }

    .channel-avatar {
      font-size: 16px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;

      .avatar-container {
        width: 36px;
        height: 36px;
        display: inline-block;
        overflow: hidden;
        border-radius: 50px;
        background-size: cover;
        background-position: center;
        margin-right: 15px;
      }
    }

    .playlist-options {
      .tiny-button {
        padding: 5px 30px;
      }

      .action {
        padding: 0 10px;
        font-size: 24px;
        position: relative;
        cursor: pointer;
        top: 3px;

        &:first-of-type {
          padding-left: 20px;
        }
      }
    }

    .account-details {
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin: 0 0 17px;
      font-size: 16px;

      & .channel-avatar {
        display: inline-flex;
        justify-content: center;
        border-radius: 50%;
        margin-left: 0;
        margin-bottom: 2px;
        line-height: 8px;
        font-size: 10px;
        width: 36px;
        cursor: pointer;
        background-position: center;
        background-size: cover;
        height: 36px;
      }

      & > .link-to-channel {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .name-letters {
          font-size: 14px;
          font-weight: $bold;
          color: $white;
        }

        > span {
          color: $white;
          font-size: 16px;
          margin-left: 10px;

          &:hover {
            color: #5986ff;
          }
        }
      }
    }
  }

  .main-playlist-thumb {
    width: 100%;
    border-radius: 6px;
    height: 186px;
    background-size: cover;
    background-position: center;
    background-color: #2e2e3c;
    position: relative;

    > .icon-img-thumb {
      position: absolute;
      font-size: 60px;
      opacity: 0.4;
      text-align: center;
      display: inline-block;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
    }
  }

  .videos-container {
    padding: 0;
  }

  .videos-list {
    margin-left: 0;
    position: relative;

    .app-pagination {
      width: 100%;
      margin-left: 0;
      .pagination {
        padding-right: 0;
      }
    }

    & > .playlist-video-item {
      list-style-type: none;
      margin-left: 0;
      padding-left: 10px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.08);

      &:last-child {
        border-bottom: none;
      }

      &:hover {
        background: rgba(255, 255, 255, 0.05);
      }
    }
  }

  .playlist-heading-table {
    flex-direction: row;
    padding-left: 10px;
    text-align: left;
    margin-top: 25px;
    margin-left: 0;
    font-size: 12px;
    text-transform: uppercase;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    .heading-option {
      letter-spacing: 2px;
      text-transform: uppercase;
      color: #72818a;
      font-weight: 700;
      font-size: 12px;
      padding-top: 4px;
      line-height: 24px;
    }
  }

  .nr-order {
    width: 30px;
    padding-right: 5px;
    text-align: center;
  }
}

.empty-playlist-data {
  position: relative;
  width: 100%;
  display: flex;
  min-height: 250px;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  > i {
    font-size: 40px;
    display: inline-block;
    padding-bottom: 42px;
    padding-right: 20px;
    color: #3c3d4c;
  }

  .empty-message {
    margin-bottom: 32px;
    color: #72818a;
    font-size: 18px;
  }
}
