@import "sass/variabiles";

.search-result-page {
  padding: 0 30px 70px !important;
}

.search-results-list {
  max-width: 1052px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  .search-result-channel {
    margin: 15px 0 49px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 136px;

    .link-to-channel {
      width: 360px;
      display: flex;
      justify-content: center;
      align-items: center;

      .channel-avatar {
        display: inline-flex;
        justify-content: center;
        border-radius: 50%;
        margin-left: 0;
        margin-bottom: 2px;
        line-height: 8px;
        font-size: 10px;
        width: 136px;
        height: 136px;
        cursor: pointer;
        background-position: center;
        background-size: cover;
        position: relative;

        .name-letters {
          align-items: center;
          display: inline-flex;
          font-size: 45px;
          font-weight: 700;
          color: #fff;
        }
      }
    }

    &-image {
      width: 360px;
      height: 136px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    & .item-details {
      width: calc(100% - 391px);
      height: 100%;
      margin-left: 31px;
      display: flex;
      justify-content: center;
      flex-direction: column;

      & .title {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        word-break: break-word;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;

        span {
          color: $white;
          transition: ease-in-out 0.3s;

          &:hover {
            color: #5986ff;
          }
        }
      }

      & .owner_details {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-items: center;
        padding: 10px 0 0;

        &-item {
          margin-right: 15px;

          span {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
            color: #ffffff;
            opacity: 0.5;
          }
        }

        &-desc {
          width: 100%;
          font-size: 14px;
          line-height: 24px;
          color: #72818a;
          word-break: break-word;
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
  }

  .search-result-item {
    margin: 0 0 29px 0;
    display: inline-flex;
    flex-direction: row;

    & .post-image {
      display: inline-flex;
      position: relative;
      border-radius: 6px;
      top: 0;
      left: 0;
      width: 360px;
      height: 200px;
      background-color: #2e2e3c;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;

      & .duration {
        min-width: 42px;
        height: 17px;
        background: rgba(196, 196, 196, 0.33);
        opacity: 1;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: auto;
        position: absolute;
        bottom: 15px;
        right: 15px;

        &-time {
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          color: #ffffff;
          line-height: 17px;
          padding: 0 4px;
        }
      }
    }

    & .item-details {
      width: calc(100% - 391px);
      margin-left: 31px;
      display: inline-flex;
      flex-direction: column;

      & .title {
        text-align: left;
        margin-bottom: 12.5px;

        span {
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 22px;
          color: #ffffff;
          word-break: break-word;
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          transition: ease-in-out 0.3s;

          &:hover {
            color: #5986ff;
          }
        }
      }

      & .description {
        font-size: 14px;
        line-height: 24px;
        color: #72818a;
        word-break: break-word;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
      }

      & .owner_details {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 14px;

        &-channel {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-right: 14px;

          & .avatar {
            width: 22px;
            height: 21px;
            border-radius: 50%;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 6px;
          }

          & .avatar-img {
            background-position: center center;
            background-size: contain;
          }

          & .avatar-custom {
            span {
              font-size: 10px;
              line-height: 21px;
              color: $white;
              font-weight: $bold;
            }
          }

          &-title {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
            color: #ffffff;
            opacity: 0.5;
          }
        }

        &-views {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          i {
            width: 18px;
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 4px;
          }

          span {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
            color: #ffffff;
            opacity: 0.5;
          }
        }
      }
    }
  }
}

.search-no-results {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-height: 50vh;

  &-item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  &-icon {
    color: #2f303f;
    font-size: 51px;
  }

  &-text {
    width: 100%;
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 23px;
    line-height: 34px;
    color: #72818a;
    margin-top: 44px;
  }
}

.search-page-label {
  margin: 22px 0 43px 0;
  line-height: 57px;
  font-size: 46px;
  font-weight: bold;
  color: #ffffff;

  & strong {
    color: #4f59d9;
  }
}

.search-result-page {
  @media only screen and (max-width: $mobile) {
    .search-page-label {
      margin: 0 0 21px 0;
      font-weight: 500;
      font-size: 23px;
      line-height: 34px;
      text-align: center;
    }
    .search-results-list {
      .search-result-channel {
        margin: 0 0 60px 0;
        height: 85px;
        overflow: hidden;
        .link-to-channel {
          width: 85px;
          height: 85px;
          margin-right: 32px;
          .channel-avatar {
            width: 85px;
            height: 85px;
            margin: 0;
          }
        }
        .item-details {
          width: calc(100% - 116px);
          margin: 0;
          justify-content: flex-start;
          .title {
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
            margin: -5px 0 0 0;
          }
          .owner_details {
            padding: 0;
            &-item {
              height: 26px;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              padding-left: 25px;
              position: relative;
              span {
                font-size: 16px;
                &:nth-child(2) {
                  display: none;
                }
              }
              &:nth-child(1) {
                &:before {
                  content: "\e958";
                  font-family: "icomoon" !important;
                  position: absolute;
                  top: -3px;
                  left: 0;
                  font-size: 17px;
                }
              }
              &:nth-child(2) {
                &:before {
                  content: "\e911";
                  font-family: "icomoon" !important;
                  position: absolute;
                  top: -3px;
                  left: 0;
                  font-size: 17px;
                }
              }
            }
            &-desc {
              line-height: 21px;
            }
          }
        }
      }
      .search-result-item {
        margin: 0 0 30px 0;
        .post-image {
          width: 131px;
          height: 83px;
        }
        .item-details {
          width: calc(100% - 154px);
          margin: -5px 0 0 13px;
          .title {
            margin-bottom: 7px;
          }
          .owner_details {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column-reverse;
            margin: 0;
            .owner_details-channel,
            .owner_details-views {
              width: 100%;
              margin: 0 0 10px 0;
              span {
                font-size: 12px;
                line-height: 12px;
              }
            }
            .owner_details-channel {
              margin: 0 !important;
              .avatar {
                width: 16px;
                height: 16px;
                margin-right: 7px;
              }
            }
            .owner_details-views {
              .icon-glasses {
                width: 16px;
                height: 16px;
                margin-right: 7px;
              }
            }
          }
          .description {
            display: none;
          }
        }
        &:last-child {
          margin: 0;
        }
      }
    }
    .app-pagination {
      margin: 0;
      .items-per-page-col {
        margin: 25px 0 15px;
      }
      .pagination-block {
        max-width: 100%;
        width: 100%;
      }
      .pagination {
        padding: 10px 0;
        display: flex;
        justify-content: center;
        width: 100%;
        margin: 0;
        li {
          width: 41px !important;
          display: flex !important;
          justify-content: center !important;
          align-items: center !important;
          margin-bottom: 10px !important;
        }
      }
    }
  }
}
