@import "sass/variabiles";

.wrapper-live-chat-avatar {
  display: inline-flex;
  align-items: center;

  .live-chat-avatar {
    border-radius: 50%;
    height: 20px;
    width: 20px;
    margin-right: 5px;
    display: inline-flex;
    justify-content: center;
    font-size: 10px;
    font-weight: bold;
    display: flex;
    align-items: center;
  }

  .live-chat-avatar-name {
    font-weight: 700;
    color: #5986ff;
    margin-right: 5px;
    font-size: 13px;
    line-height: 20px;

    &__host {
      background: $white;
      height: 20px;
      border-radius: 3px;
      color: $black;
      font-weight: 400;
      padding: 3px 4px;
      font-size: 12px;
      line-height: 20px;
      display: inline-flex;
      align-items: center;
    }

    &__host-bold {
      font-weight: 700;
      margin: 0 3px;
    }

    &__icon {
      color: #48cbb3;
    }
  }
}
