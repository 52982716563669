@import "sass/variabiles";
.stars-rating {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  margin: 0 10px;
  color: #ffffff;
  font-size: 13px;

  & > .icon {
    margin: 2px;
  }

  .muted {
    margin-bottom: 0;
    padding-left: 8px;
    white-space: nowrap;
  }
}
