@import "sass/variabiles";

.app-filter-bar {
  position: relative;
  background: #181826;
  border-radius: 6px;
  margin: 15px 0 5px;
  text-align: left;
  width: 100%;
  z-index: 999;
  padding: 8px 117px 8px 24px !important;
  min-height: 62px;
  display: inline-flex;
  align-items: center;
  animation: 0.6s fadeInFilter forwards ease;

  .edit-filter-item {
    margin: 7px 10px;

    .checkbox-filter,
    .range-filter {
      margin-top: -36px;
    }
  }

  .selected-filter-item {
    display: flex;
    flex-direction: row;
    font-size: 16px;
    line-height: 24px;
    align-items: center;
    padding: 6px 15px !important;
    background: #2f303f;
    border-radius: 26px;
    color: $white;

    &.hide-selected-filter-item {
      width: 190px;
      height: 36px;
      opacity: 0;
    }

    &.opaticy-selected-filter-item {
      opacity: 0.5;
    }

    > i {
      font-size: 8px;
      padding-left: 10px;
      position: relative;
      color: $white;
      cursor: pointer;
      top: 2px;

      &:hover {
        color: $red;
      }
    }
  }

  .chooser-coll {
    height: 30px;
    margin-left: 8px;
  }

  > .icon-close-rounded {
    position: absolute;
    cursor: pointer;
    right: 17px;
    top: 50%;
    font-size: 10px;
    transform: translateY(-50%);

    &:hover {
      opacity: 0.4;
    }
  }

  .bar-label {
    font-weight: 800;
    font-size: 12px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #72818a;
  }

  .filters-list {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    list-style-type: none;
    padding: 0;
    margin: 0;

    > li {
      padding: 0 5px;

      .add-filter {
        width: 30px;
        height: 30px;
        border-radius: 30px;
        position: relative;
        background-color: #4f59d9;
        display: flex;
        cursor: pointer;
        justify-content: center;
        align-items: center;
        transition: all 0.3s ease;

        > i {
          font-size: 15px;
          color: $white;
        }

        &:hover {
          background-color: #38389c;
        }
      }
    }
  }

  .reset-all-filters {
    position: absolute;
    top: 50%;
    right: 40px;
    font-size: 15px;
    cursor: pointer;
    transform: translateY(-50%);

    > i {
      padding-right: 5px;
      font-size: 12px;
    }

    &:hover {
      color: $red;
    }
  }
}
