@import "sass/variabiles";

.single-video-page {
  margin-bottom: 150px;

  .video-placeholder {
    position: relative;
    height: 0;
    padding-top: 56.25%;
    background-color: #000;
    overflow: hidden;
    border-radius: 6px;
    background-position: center;
    background-size: cover;

    .content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: inline-flex;
      justify-content: center;
      flex-direction: column;
      font-size: 22px;
      align-items: center;
      background: linear-gradient(360deg, rgba(1, 5, 57, 0.9) 11.7%, rgba(5, 6, 28, 0.7) 94.79%);

      .heading {
        font-size: 42px;
        padding-bottom: 35px;
      }

      .link {
        padding-bottom: 2px;
        color: $white;
        border-bottom: 1px solid $white;
        cursor: pointer;
      }
      .close-label {
        font-weight: 800;
        font-size: 12px;
        line-height: 24px;
        letter-spacing: 2px;
        text-transform: uppercase;
      }
    }
  }

  .video-details-published {
    .title.hidden {
      display: none;
    }
  }

  .comments-container {
    .comments-label {
      .expand-details {
        display: none !important;
      }
    }
  }

  .app-next-heading {
    width: 96%;
    margin: 0 auto;
    padding-bottom: 20px;

    .label-txt {
      font-size: 23px;
      line-height: 27px;
      margin: 0 !important;
    }

    .auto-play-switch {
      text-align: right;

      > .toggle-switch {
        transform: scale(0.8);
        position: relative;
        left: 5px;
        top: -1px;
      }

      > span {
        display: inline-block;
        position: relative;
        top: -8px;
        padding-left: 12px;
        font-weight: 800;
        font-size: 12px;
        line-height: 24px;
        letter-spacing: 2px;
        text-transform: uppercase;
      }
    }
  }

  .related-videos-list {
    flex-direction: column;
    display: inline-flex;
    align-items: center;
    margin-top: 15px;
    width: 28%;

    & > .videos-list-group {
      width: 100%;

      h3 {
        margin: 15px;
        font-size: 23px;
      }

      &:last-child {
        h3 {
          margin-top: 14px;
          margin-bottom: 17px;
        }
      }
    }
  }

  .video-details {
    margin-bottom: 30px;
    width: 70%;
    padding-right: 20px;

    .video-tag {
      color: #fff;
      text-decoration: none;
      transition: ease-in-out 0.3s;
      &:hover {
        color: #fff;
        border-color: #2c37c9;
        background-color: #2c37c9;
      }
    }

    & > .row {
      margin-top: 15px;

      &.video-header {
        margin-bottom: 18px;
        margin-top: 0;
        margin-left: 0;
        display: inline-flex;
        align-items: center;

        & > .main-button {
          display: inline-flex;
          align-items: center;
          line-height: 1px;
          margin: 5px;

          & span {
            margin: 0 5px;
          }
        }

        & > .owner {
          display: inline-flex;
          flex-direction: row;
          line-height: 36px;
          margin: 5px 5px 5px 0;
          color: #ffffff;

          & > .owner-avatar {
            width: 36px;
            height: 36px;
            border-radius: 50%;
            background-color: #232632;
            margin-right: 10px;
            position: relative;
            background-position: center;
            background-size: cover;

            & > img {
              border-radius: 50%;
              position: absolute;
              top: 0;
              left: 0;
            }
          }
        }

        & > .owner-followers-count {
          margin: 5px 10px;
          display: inline-flex;
          vertical-align: middle;

          > span {
            font-size: 16px;
            opacity: 0.5;
          }

          & > .icon {
            position: relative;
            font-size: 25px;
            margin-right: 10px;
            top: 3px;
          }
        }
      }

      & .created-date {
        font-size: 18px;
        opacity: 0.35;
      }

      & .controls-list {
        display: inline-flex;
        flex-direction: row;
        justify-content: flex-end;

        & > .controls {
          font-size: 16px;
          margin: 5px;
          margin-left: 15px;
          justify-content: center;
          display: inline-flex;
          cursor: pointer;

          & > .icon {
            position: relative;
            font-size: 20px;
            top: 3px;

            &.icon-glasses {
              font-size: 25px;
            }
          }

          & > span {
            padding-left: 10px;
          }

          &.likes {
            opacity: 0.5;

            & .icon-thumbs-down {
              top: 7px;
            }

            &.active {
              opacity: 1;
            }
          }
        }
      }

      & .title {
        min-height: 38px;
        font-size: 38px;
        line-height: 45px;
        width: 100%;
        word-break: break-word;
        padding-right: 0;
        font-weight: 700;
      }

      .tags-list {
        margin: 10px 0 20px;
      }

      & .expand-details {
        display: flex;
        justify-content: flex-end;
        vertical-align: middle;
        line-height: 46px;
        height: 46px;
        margin-right: 5px;

        & > .controls {
          margin-right: 10px;
          margin-top: 5px;
          font-size: 20px;
          opacity: 0.35;
          transition: opacity 0.4s ease, transform 0.3s ease;
          cursor: pointer;
          transform: rotateZ(-90deg);

          &.opened {
            transform: rotateZ(90deg);
          }

          &:hover {
            opacity: 1;
          }
        }
      }

      & .description {
        font-size: 18px;
        opacity: 0.7;
        height: auto;
        max-height: 65px;
        overflow: hidden;
        display: -webkit-box;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: pre-wrap;
        transition: 0.5s ease;

        &.full {
          max-height: 965px;
          display: inline-block;
        }
      }
    }
  }

  .comments-list {
    padding-left: 8px;
  }

  @media only screen and (max-width: $mobile) {
    overflow-x: hidden;
    padding: 0 !important;

    .comments.hidden {
      .comments-list {
        display: none !important;
      }
    }

    .related-videos-list.hidden {
      display: none !important;
    }

    .video-details {
      width: 100%;
      padding: 0;
      margin-bottom: 0px;
      display: flex;
      flex-wrap: wrap;
      .sharevision-video-player {
        order: 0;
      }
      .video-details-published {
        order: 1;
        margin: 0;
      }
      .description-block {
        display: none !important;
      }
      .video-header {
        order: 2;
        margin: 0 30px !important;
        width: 100%;
        padding: 0;
        justify-content: space-between;
        .toggle-info {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          transition: opacity 0.4s ease, transform 0.3s ease;
          padding-top: 24px;
          margin-bottom: -24px;
          span {
            width: 100%;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: #ffffff;
            opacity: 0.7;
            display: block;
            margin-bottom: 24px;
            word-break: break-word;
          }
          .video-tag {
            border: 1px solid #dbdee7;
            box-sizing: border-box;
            border-radius: 6px;
            text-decoration: none;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: #ffffff;
            padding: 6px 19px;
            margin: 0 10px 25px 0;
          }
          .video-tag.default {
            border: 1px solid #5986ff;
            color: #5986ff;
          }
        }
        .owner {
          margin: 25px 0 !important;
        }
        .owner-followers-count {
          display: none !important;
        }
        .follow-btn {
          margin: 25px 0 !important;
          border: 1px solid #4f59d9 !important;
          background: #4f59d9 !important;
          font-weight: 800;
          font-size: 12px;
          line-height: 24px;
          letter-spacing: 2px;
          text-transform: uppercase;
          color: #ffffff;
          padding: 0 12px 0 12px;
          max-width: 140px;
          position: relative;
        }
      }

      .video-details-published {
        .title.hidden {
          display: flex;
          align-items: center;
          width: 100%;
          font-weight: bold;
          font-size: 19px;
          line-height: 28px;
          color: #ffffff;
          padding: 0 99px 0 30px;
          margin-bottom: 18px;
          position: relative;
          .expand-details {
            position: absolute;
            top: 5px;
            color: #ffffff;
            opacity: 0.35;
            right: 30px;
            margin: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 20px;
            height: 20px;
            .controls {
              margin: 0;
              width: 20px;
              height: 20px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }

        .controls-list {
          padding: 0 30px;
          justify-content: space-between;
          margin-bottom: 25px;
          position: relative;
          &:before {
            content: "";
            display: block;
            position: absolute;
            bottom: -25px;
            left: 30px;
            background: rgba(255, 255, 255, 0.3);
            width: calc(100% - 60px);
            height: 1px;
          }
          .controls {
            margin: 0;
          }
        }
      }
      .video-categories-tags {
        width: 100%;
        display: none;
      }

      .sharevision-video-player > .wrap > .video-js .vjs-tech {
        border-radius: 0;
      }

      .created-date {
        padding: 0 30px;
        margin: 15px 0 4px 0;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
        opacity: 0.35;
      }

      .comments {
        width: 100%;
        order: 3;
        margin: 0 !important;

        .comments-block {
          padding: 0 !important;

          .add-comment-btn {
            padding: 1px 0 0 0;
          }
        }

        .app-pagination {
          margin: 15px 0 60px 0;
          height: 36px;
          .items-per-page-col {
            display: none;
          }
          .pagination {
            display: flex;
            justify-content: center;
            li {
              margin: 0 !important;
              width: 47px;
              a {
                width: 37px;
                height: 36px;
                display: block;
                margin: 0 5px;
              }
            }
            .next,
            .previous {
              a {
                display: flex;
                justify-content: center;
                i {
                  margin: 0 !important;
                }
              }
            }
          }
        }
      }

      .video-details-title {
        display: none !important;
      }

      .comments-container {
        margin: 0 30px;
        padding: 0;
        border-top: 1px solid rgba(255, 255, 255, 0.3);

        .comments-label {
          padding: 0;
          position: relative;
        }

        .comments-count {
          font-weight: 500;
          font-size: 23px;
          line-height: 64px;
          color: #ffffff;
          width: 100%;
          padding: 0 8px;
          span {
            opacity: 0.25;
            padding-left: 18px;
          }
        }

        .expand-details {
          display: flex !important;
          position: absolute;
          top: 22px;
          color: #fff;
          opacity: 1;
          right: 8px;
          margin: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 20px;
          height: 20px;
          .controls {
            margin: 0;
            width: 20px;
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            opacity: 1;
          }
        }

        .comments-list {
          .comments-input {
            .avatar {
              width: 24px;
              height: 24px;
            }
            .actions-button {
              padding: 16px 20px 21px 28px;
              display: flex;
              justify-content: center;
              .submit-button {
                background: #4f59d9;
                border-radius: 6px;
                font-weight: bold;
                font-size: 14px;
                line-height: 22px;
                color: #ffffff;
                padding: 6px 12px;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              .cancel-button {
                background: none;
                border-radius: 6px;
                font-weight: bold;
                font-size: 14px;
                line-height: 22px;
                color: #72818a;
                padding: 6px 12px;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }

          .comment-item {
            .replies-toggle {
              font-weight: bold;
              font-size: 14px;
              line-height: 22px;
            }
            .comment-content-wrapper {
              .comment-label {
                .comment-owner {
                  a {
                    display: flex;
                    align-items: center;
                    .avatar {
                      top: 0;
                      width: 24px;
                      height: 24px;
                    }
                    span {
                      line-height: 24px;
                    }
                  }
                }
                .comment-date {
                  font-size: 12px;
                  opacity: 0.5;
                  height: 24px;
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;
                }
              }
              .comment-footer-controls {
                width: 100%;
                display: flex;
                justify-content: space-between;
                margin: 24px 0 0 0;
              }
            }
          }
        }
        .comment-replies {
          width: calc(100% - 20px) !important;
          margin-left: 20px !important;
          .comments-input {
            border: 1px solid #4f59d9;
          }
        }
      }
    }
    .related-videos-list {
      width: 100%;
      padding: 0 30px 30px 30px;
      margin: 0;

      .videos-list-group-next {
        padding-top: 8px;
        border-top: 1px solid rgba(255, 255, 255, 0.3);
      }

      .videos-list-group {
        .videos-list-group-related {
          margin: 0 0 25px 0 !important;
        }

        .app-next-heading {
          margin: 0;
          width: 100%;
          padding: 0;
          display: flex;
          align-items: center;
          height: 27px;
          margin-bottom: 28px;

          .app-next-heading-text {
            padding: 0;
            .label-txt {
              font-weight: bold;
              font-size: 19px;
              line-height: 27px;
              color: #ffffff;
            }
          }
        }

        .auto-play-switch {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          span {
            font-weight: 800;
            font-size: 12px;
            text-transform: uppercase;
            padding-left: 13px;
            top: 0;
          }
        }

        .list {
          .related-video-list-item {
            margin: 0 0 25px 0;
            .thumb {
              width: 131px;
              height: 83px;
              margin-right: 13px;
            }
            .details {
              .title {
                font-weight: bold;
                font-size: 16px;
                line-height: 22px;
                margin-bottom: 7px;
                vertical-align: text-top;
                max-height: 44px;
                margin-top: -5px;
              }
              .video-stats {
                width: 100%;
                height: 12px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-bottom: 9px;
                .icon-glasses {
                  font-size: 14px;
                  top: -1px;
                }
                .icon-clock-o {
                  font-size: 11px;
                  margin-top: -1px;
                }
                span {
                  font-weight: normal;
                  font-size: 12px;
                  line-height: 12px;
                  color: #ffffff;
                  opacity: 0.5;
                }
              }

              .user-details {
                .avatar {
                  width: 16px;
                  height: 16px;
                  margin-right: 10px;
                  .name-letters {
                    font-size: 8px;
                    line-height: 17px;
                  }
                }
                .user-name {
                  font-weight: normal;
                  font-size: 12px;
                  line-height: 12px;
                  color: #ffffff;
                  opacity: 0.5;
                  display: flex;
                  align-items: center;
                }
              }
            }
          }
        }
      }
    }
  }
}

.comments-container {
  flex-direction: column;
  border-top: 1px solid rgba(255, 255, 255, 0.25);
  padding-top: 25px;

  .app-pagination {
    padding-left: 8px;

    .col-xs {
      padding: 0;
    }

    .pagination {
      justify-content: flex-end;
      padding: 0;

      .next {
        width: 25px;
        margin-left: 15px;
      }
    }
  }

  & > .comments-label {
    padding-bottom: 20px;

    & > .comments-count {
      font-size: 36px;
      padding-left: 8px;
      font-weight: 500;

      span {
        opacity: 0.5;
        padding-left: 15px;
        display: inline-block;
      }
    }

    & > .sort-controls {
      display: flex;
      justify-content: flex-end;
      vertical-align: middle;

      .sort-toggle {
        padding-right: 15px;
        font-weight: $bold;
        font-size: 16px;
        line-height: 24px;
        cursor: pointer;
      }

      i {
        transform: rotateZ(270deg);
        font-size: 14px;
        position: relative;
        display: inline-block;
        right: -5px;
        top: -4px;
        cursor: pointer;
      }
    }
  }
}
