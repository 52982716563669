@import "sass/variabiles";

.dashboard-widget {
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  padding: 25px;
  position: relative;
  min-height: 385px;

  .title {
    font-size: 22px;
    line-height: 34px;
    font-weight: $bold;
  }

  .data-list {
    padding-left: 0;
    padding-top: 25px;

    .list-item {
      color: $white;
      list-style-type: none;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      padding-bottom: 5px;
      margin-bottom: 5px;

      &:hover {
        color: #5986ff;
      }

      &:last-child {
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 0;
      }

      span {
        font-size: 16px;
        line-height: 30px;

        &:first-child {
          font-weight: $bold;
          font-size: 15px;
          line-height: 30px;
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          padding-right: 5px;
        }

        &:last-child {
          opacity: 0.5;
        }
      }
    }
  }

  .sort-filter-bar {
    padding: 0;
    margin: 15px 0;
    text-transform: capitalize;
    text-align: left;
    list-style-type: none;

    li {
      line-height: 28px;
      margin: 0 2px;
      display: inline-block;

      &:first-child {
        margin-left: 0;
      }

      label {
        margin: 0;
        width: 100%;
        border-radius: 6px;
        font-size: 16px;
        line-height: 19px;
        color: #72818a;
        text-align: center;
        padding: 6px 6px 5px;
        border: 1px solid transparent;
      }

      input {
        display: none;

        &:checked + label {
          color: $white;
          border: 1px solid $blue;
        }

        &:hover + label {
          color: $white;
          border: 1px solid rgba(79, 89, 217, 0.5);
        }
      }
    }
  }

  .no-list-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    i {
      font-size: 50px;
      color: #2f303f;
      margin-top: 30px;
    }

    span {
      margin-top: 20px;
    }
  }

  .counter {
    font-size: 42px;
    line-height: 38px;
    font-weight: $bold;
    color: $white;
    margin-bottom: 15px;
  }

  .advanced-counter {
    padding: 0;
    list-style-type: none;

    li {
      display: inline-block;
    }
  }

  .counter-total {
    font-size: 16px;
    line-height: 30px;
    color: $white;
    opacity: 0.5;
    position: relative;
    top: -3px;

    .divider {
      padding: 0 5px;
    }
  }

  .counter-label {
    font-size: 16px;
    line-height: 30px;
    color: $white;
    opacity: 0.5;
    margin: 0;
  }

  .data-stat {
    padding-left: 0;
    padding-top: 5px;
    margin-bottom: 0;

    li {
      list-style-type: none;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .direction-label {
        position: relative;
        display: inline-flex;
        flex-direction: row;
        align-items: center;

        & span {
          opacity: 1 !important;
          font-weight: $bold;

          &.label-value {
            width: 82px;

            .icon-dash-line {
              color: #3ba894;
            }

            > span {
              font-weight: normal;

              &.value-indicator-up {
                color: #3ba894;
              }

              &.value-indicator-down {
                color: #ee4985;
              }
            }
          }
        }

        .icon {
          position: relative;
          font-size: 10px;
          margin-left: 15px;
          margin-right: 10px;
          bottom: 1px;

          &.icon-caret-down {
            color: #a83b62;
          }

          &.icon-caret-up {
            color: #3ba894;
          }
        }
      }

      span {
        font-size: 16px;
        line-height: 30px;

        &:first-child {
          opacity: 0.5;
        }

        &:last-child {
        }
      }
    }
  }
}
