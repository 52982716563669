@import "sass/variabiles";

.list-wrapper {
  border: 1px solid #2f2f3c;
  border-radius: 6px;
  position: relative;
  min-height: 470px;
  padding-bottom: 75px;

  .list-header {
    padding: 15px;
    background: #2f2f3c;

    > i {
      float: right;
      cursor: pointer;
      margin-top: 7px;

      &:hover {
        color: $blue;
      }
    }
  }

  .action-button {
    position: absolute;
    bottom: 15px;
    background: transparent;
    border-radius: 6px;
    overflow: hidden;
    border: 1px solid rgba(219, 222, 231, 0.36);
    left: 15px;
    width: calc(100% - 30px);

    &.active-popover {
      box-shadow: 0 0 30px 1px rgba(0, 0, 0, 0.75);
    }

    .static-button {
      text-align: center;
      cursor: pointer;
      padding: 5px;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;

      &:hover {
        background: rgba(219, 222, 231, 0.36);
      }
    }

    .create-new-item {
      overflow: hidden;
      padding: 10px 15px 15px;
      background: #1b1b28;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;

      .main-button {
        font-size: 15px;
        font-weight: $bold;
        padding: 2px 12px;
      }
      .cancel-action {
        font-weight: bold;
        font-size: 15px;
        line-height: 22px;
        color: #72818a;
        margin-left: 20px;
        cursor: pointer;
        &:hover {
          color: $red;
        }
      }
      .creation-form {
        > span {
          font-weight: normal;
          font-size: 14px;
          color: #72818a;
          display: inline-block;
          margin-bottom: 8px;
        }
        .main-input {
          font-size: 14px;
          padding: 10px 12px;
        }
      }
    }
  }
}
