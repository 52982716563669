.metric-bar-container {
  width: 100%;
  height: 2px;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  margin-top: 20px;
  margin-left: 1px;
  max-width: 394px;
  background: rgba(255, 255, 255, 0.3);

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 2px;
    background-image: linear-gradient(to right, #050614 15%, rgba(255, 255, 255, 0) 5%);
    background-position: top center;
    background-size: 40px 2px;
    background-repeat: repeat-x;
    margin-left: -5px;
  }

  .metric-bar-value {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background: #4fba67;
    transition: width 0.2s ease;
  }
}
