@import "sass/variabiles";

.searchable-select {
  position: relative;
  font-family: "icomoon", serif;

  &:after {
    content: "\e91f";
    position: absolute;
    top: 10px;
    left: 15px;
    opacity: 0.5;
  }

  .app-select__placeholder {
    opacity: 0.5;
  }

  .app-select__value-container {
    padding-left: 30px !important;
  }
}

.selected-tag-list {
  padding: 0;
  margin-top: 22px;

  .tag-item {
    display: inline-block;
    list-style-type: none;
    border-radius: 6px;
    overflow: hidden;
    font-size: 16px;
    background: $blue;
    padding: 2px 11px 0;
    margin-right: 14px;
    margin-bottom: 4px;

    i {
      font-size: 8px;
      padding-left: 10px;
      cursor: pointer;
      position: relative;
      top: -2px;

      &:hover {
        opacity: 0.6;
      }
    }
  }
}
