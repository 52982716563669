@import "sass/variabiles";

.channel-playlists {
  width: 100%;
  padding-bottom: 60px;

  @media only screen and (max-width: $mobile) {
    padding-bottom: 30px;
  }

  > .title {
    font-size: 29px;
    line-height: 27px;
    margin: 20px 0 32px;
    font-weight: $regular;
    color: $white;
  }
  .playlist-details {
    .video-data {
      .nr-views-time {
        display: none;
      }
    }
    @media only screen and (max-width: $mobile) {
      .playlist-main-heading {
        .playlist-main-heading-left,
        .playlist-main-heading-right {
          padding: 0;
          width: 100%;
        }

        .playlist-main-heading-right {
          margin-top: 25px;
        }
      }
      .videos-container.videos-list {
        padding-top: 30px;

        .playlist-video-item {
          padding: 0 0 30px 0 !important;
          border-bottom: none !important;
          &:hover {
            background: none !important;
          }

          .auto-column.nr-order {
            display: none;
            padding: 0 !important;
          }

          .video-coll {
            padding: 0;

            .video-details-list {
              display: flex;

              li.thumb {
                width: 131px;
                height: 83px;
                margin-right: 13px;
                max-width: 100%;

                .duration {
                  display: none;
                }
              }

              .info {
                width: calc(100% - 149px);

                .title {
                  font-weight: bold;
                  font-size: 16px;
                  line-height: 22px;
                  margin-bottom: 7px;
                  vertical-align: text-top;
                  max-height: 44px;
                  margin-top: -5px;
                }

                .video-data {
                  padding: 0;
                  display: flex;
                  flex-wrap: wrap;
                  flex-direction: column-reverse;

                  .account-details {
                    padding: 0;
                    display: flex;
                    line-height: 16px;
                    cursor: pointer;
                    color: #ffffff;
                    justify-content: flex-start;
                    height: 16px;

                    .link-to-channel {
                      .channel-avatar {
                        width: 16px;
                        height: 16px;
                        margin: 0;
                        .name-letters {
                          font-size: 8px;
                          line-height: 17px;
                        }
                      }
                      span {
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 12px;
                        color: #ffffff;
                        opacity: 0.5;
                        display: flex;
                        align-items: center;
                      }
                    }
                  }
                  .nr-views {
                    opacity: 0.5;
                    color: #fff;
                    margin-bottom: 0;
                    height: 12px;
                    display: flex;
                    margin-bottom: 4px;
                    padding: 0;
                    align-items: center;
                    &-item {
                      font-size: 12px;
                      line-height: 12px;
                      display: flex;
                      align-items: center;
                      .icon-glasses {
                        font-size: 14px;
                        top: -1px;
                      }
                    }
                    .nr-views-time {
                      display: block;
                      margin-left: 15px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
