@import "video.js/dist/video-js.css";
@import "sass/variabiles";

.sharevision-video-player {
  position: relative;
  display: flex;
  width: 100%;

  .vjs-seek-to-live-control {
    .vjs-icon-placeholder {
      position: relative;
      top: 3px;
    }
  }

  .live-label-container {
    position: absolute;
    z-index: 999;
    top: 18px;
    left: 18px;

    .duration {
      min-width: 48px;
      display: inline-block;
    }

    .watchers-count {
      margin-left: 18px;
      padding: 0 15px;
      position: relative;
      font-size: 16px;
      border-radius: 6px;
      display: inline-block;
      background: rgba(0, 0, 0, 0.5);
      top: 2px;

      i {
        padding-right: 5px;
        font-size: 20px;
        position: relative;
        top: 3px;
      }
    }
  }

  &:after {
    content: "";
    padding-top: 56.25%;
  }

  & > .wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    & > .video-js {
      width: 100%;
      height: 100%;
      position: relative;
      overflow: hidden;
      border-radius: 6px;

      &:before {
        content: "";
        position: absolute;
        bottom: 0;
        opacity: 0;
        left: 0;
        width: 100%;
        height: 120px;
        background: transparent;
        background: linear-gradient(0deg, #000 3%, rgba(0, 0, 0, 0) 67%);
        z-index: 1;
        transition: all 0.3s ease;

        @media only screen and (max-width: $mobile) {
          content: none;
        }
      }

      &.vjs-user-active,
      &.vjs-paused {
        &:before {
          opacity: 1;
        }
      }

      .vjs-control-bar {
        z-index: 2;
      }

      & .vjs-tech {
        border-radius: 6px;
      }

      & .vjs-control-bar {
        background-color: transparent;
        bottom: 25px;
        left: 2.5%;
        width: 95%;
      }

      & .vjs-picture-in-picture-control {
        display: none;
      }

      & .vjs-play-progress {
        background-color: #4f59d9;

        &::before {
          content: none;
        }
      }

      & .vjs-current-time,
      .vjs-time-divider,
      .vjs-duration {
        display: inline-flex;
        min-width: unset;
        padding: 0 5px;
      }

      & .vjs-play-control {
        background-color: #4f59d9;
        margin-right: 10px;
        border-radius: 50%;
        width: 31px;
        height: 31px;
      }

      & .vjs-big-play-button {
        position: absolute;
        top: calc(50% - 40px);
        left: calc(50% - 40px);
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background-color: rgba(#000000, 0.2);

        & .vjs-icon-placeholder:before {
          font-size: 50px;
          line-height: 75px;
        }
      }

      & .vjs-volume-panel,
      .vjs-fullscreen-control {
        margin-right: 5px;
        opacity: 0.8;

        & .vjs-icon-placeholder:before {
          font-size: 30px;
        }
      }

      & .vjs-remaining-time {
        display: none;
      }

      & .vjs-icon-placeholder:before {
        font-size: 22px;
        line-height: 31px;
      }
    }
  }

  .icon-settings:before {
    color: #fff;
    font-style: normal;
    font-weight: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  }

  .icon-settings:hover {
    &::before {
      color: #5986ff !important;
    }
  }

  .vjs-menu-button-popup {
    .vjs-menu {
      .vjs-menu-content {
        background-color: #1b1b28;
        width: 121px;
        right: 41px;
        bottom: 25px;
        max-height: 280px;
        padding: 5px 0;
        border-radius: 6px;

        .vjs-menu-title {
          display: none;
        }

        .vjs-menu-item {
          width: 121px;
          height: 38px;
          padding: 0 0 0 17px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          transition: ease-in-out 0.2s;

          .vjs-menu-item-text {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 32px;
            text-transform: capitalize;
          }

          &:hover {
            background-color: #262633;
          }
        }

        .vjs-menu-item.vjs-selected {
          background-color: #1b1b28;
          position: relative;

          .vjs-menu-item-text {
            color: #5986ff;
          }

          &:before {
            content: "\e94b";
            color: #5986ff;
            font-family: "icomoon" !important;
            font-style: normal;
            font-weight: normal;
            font-feature-settings: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 17px;
          }
        }
      }
    }
  }
}
