@import "sass/variabiles";

.show-chat {
  width: 365px;
  height: 36px;
  background: #131322;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 24px;
  color: $white;
  border: 1px solid $white;
  opacity: 0.5;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
}

.wrapper-live-chat {
  display: flex;
  flex-direction: column;
  width: 365px;
  height: 695px;
  background-color: #131322;
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.2);

  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
}
