@import "sass/variabiles";

.profile-comments-page {
  .nav-tabs {
    .nav-tab.active .badge-counter {
      background: #a83b62;
    }
  }

  .badge-counter {
    font-weight: $regular;
    background: #a83b62;
  }

  .empty-comments {
    position: relative;
    margin-top: 22%;
    text-align: center;
    transform: translateY(-50%);

    > i {
      font-size: 70px;
      display: inline-block;
      padding-bottom: 42px;
      color: #3c3d4c;
    }

    .empty-message {
      margin-bottom: 32px;
      color: #72818a;
      font-size: 23px;
    }
  }
}

.comments-inbox-page {
  flex-direction: column;

  & > .filters {
    font-size: 16px;
    color: #72818a;
    margin-bottom: 30px;
    align-items: center;

    & > .filter-item {
      margin-right: 10px;
      cursor: pointer;
      padding: 0 18px;
      line-height: 24px;
      font-weight: bold;

      &.active {
        background-color: #ffffff;
        border: 1px solid #e8ebf4;
        color: #000000;
        border-radius: 20px;
      }
    }
  }
}

.videos-comments {
  flex-direction: row;

  .app-pagination {
    padding-left: 45px;

    .col-xs {
      padding: 0;
    }

    .pagination {
      justify-content: flex-end;
      padding: 0;

      .next {
        width: 25px;
        margin-left: 15px;
      }
    }
  }

  & > .videos-list {
    flex-direction: column;
    background-color: #1b1b28;
    border-radius: 6px;
    padding: 10px 0;
    overflow: hidden;
    width: 350px;
    align-self: flex-start;

    & > .search-input-wrap {
      font-size: 14px;
      margin: 0;
      padding: 15px;

      & > input {
        background-color: transparent;
        border: none;
        width: 100%;
      }
    }

    & > .header-filters {
      margin: 0;
      height: 60px;
      font-size: 14px;
      padding: 0 15px;
      align-items: center;
      border-bottom: 1px solid rgba($color: #ffffff, $alpha: 0.1);

      .sort-comments {
        font-weight: $bold;
        cursor: pointer;

        span {
          padding-right: 12px;
        }
      }

      & .source-chooser-button {
        background-color: transparent;
      }
    }

    & > .list {
      flex-direction: column;
      flex-wrap: nowrap;
      margin: 0;

      & > .commented-video-list-item {
        align-items: center;
        font-size: 16px;
        padding: 15px;
        margin: 0;
        cursor: default;
        position: relative;
        border-bottom: 1px solid rgba($color: #ffffff, $alpha: 0.1);

        .image-thumb {
          width: 50px;
          height: 50px;
          background-color: #3c3c4c;
          background-position: center;
          background-size: cover;
          border-radius: 6px;
        }

        &:hover {
          background-color: rgba(79, 89, 217, 0.7);
        }

        &.active {
          background-color: #4f59d9;
        }

        &:last-child {
          border-bottom: none;
        }

        & > .thumb {
          width: 46px;
          height: 46px;
          background-color: saddlebrown;
        }

        & > .title {
          padding: 0 10px;
          width: 77%;
          line-height: 24px;
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        & > .info-pin {
          background-color: #ee4985;
          border-radius: 50%;
          font-size: 13px;
          line-height: 22px;
          text-align: center;
          width: 22px;
          height: 22px;
          position: absolute;
          right: 15px;
        }
      }
    }
  }

  .video-comments {
    padding-left: 40px;

    & > .heading-controls {
      margin-bottom: 15px;
      padding: 12px 0;
      border-radius: 6px;
      background-color: rgba($color: #ffffff, $alpha: 0.15);

      & > .control-item {
        display: inline-flex;
        font-size: 16px;
        margin: 0 22px;
        line-height: 20px;
        padding: 4px 0;
        cursor: pointer;

        &.selected-items-count {
          background-color: #ffffff;
          font-weight: 800;
          color: #4f59d9;
          letter-spacing: 2px;
          align-items: center;
          border-radius: 20px;
          padding: 4px 22px;
          font-size: 12px;
        }

        &.delete-button {
          color: #ee4985;
          line-height: 23px;

          & > i {
            font-size: 20px;
            margin-right: 10px;
          }
        }
      }
    }

    .comments-list {
      padding-top: 18px;

      .other-comment {
        margin-left: 40px;
      }

      .admin-input {
        margin-left: 40px !important;
        width: calc(100% - 40px);
        margin-right: 0 !important;
      }

      > .col-xs-12 {
        padding-right: 0;
      }

      .comment-replies {
        margin-left: 0 !important;
      }
    }

    .comment-item {
      .comment-footer {
        position: relative;
      }

      .check-option {
        padding-top: 21px;
      }

      .replies-toggle {
        position: absolute;
      }
    }

    .comment-footer-controls {
      position: relative;
      top: 7px;
      padding-left: 30px;

      &.replies-option {
        padding-left: 130px;
      }

      .likes {
        display: none;
      }
    }

    & > .heading {
      border-bottom: 1px solid rgba($color: #ffffff, $alpha: 0.1);
      color: #72818a;

      .label-text {
        letter-spacing: 2px;
        text-transform: uppercase;
        color: #72818a;
        font-weight: 700;
        font-size: 12px;
        position: relative;
        top: -1px;
        line-height: 24px;

        &:before {
          top: 0;
        }
      }

      .open-post-link {
        color: $white;
        font-size: 20px;
        background-color: transparent;

        &:hover {
          color: $blue;
        }
      }
    }
  }
}
