@import "sass/variabiles";

.range-filter {
  position: absolute;
  margin-top: -6px;
  border-radius: 6px;

  .selected-item {
    background: #2f303f;
    cursor: default;
    color: $white;
    position: absolute;
    left: 0;
    font-size: 15px;
    top: 0;
    width: 100%;
    padding: 6px 20px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;

    > i {
      top: 18px;
      right: 12px;
      position: absolute;
      cursor: pointer;
      font-size: 8px;

      &:hover {
        opacity: 0.4;
      }
    }

    &:hover {
      background: #2f303f;
    }
  }

  .range-body {
    background: #181826;
    border: 1px solid #2e2e3b;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    margin-top: 42px;
    list-style-type: none;
    width: 100%;
    min-width: 192px;
    padding: 22px 10px 45px;
    max-height: 300px;

    .range-select {
      z-index: 9999;

      .app-select__value-container {
        font-size: 14px !important;
      }

      .app-select__indicator {
        padding: 0 !important;
        width: 16px;
      }

      .app-select__option {
        font-size: 14px !important;
        text-align: center;
        padding: 9px 0 !important;
      }
    }

    .main-input {
      padding: 8px 10px;
      font-size: 16px;
    }
  }

  .apply-button {
    position: absolute;
    z-index: 999;
    bottom: 0;
    width: 100%;
    padding: 8px 0 !important;
    font-size: 16px;
    font-weight: $bold;
  }
}
