.lds-ring,
.vjs-loading-spinner {
  position: absolute;
  left: calc(50% - 45px);
  top: calc(50% - 45px);
  visibility: visible;
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  margin: 0;
  border-top: 5px solid #4f59d9;
  width: 90px;
  height: 90px;
  -webkit-animation: spin 2s linear infinite !important;
  animation: spin 2s linear infinite !important;

  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.vjs-loading-spinner {
  left: calc(50% - 30px);
  top: calc(50% - 30px);
  width: 60px;
  height: 60px;
  border: 5px solid rgba(33, 33, 33, 0.74);
  border-top: 5px solid #4f59d9;
}
