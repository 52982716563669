@import "sass/variabiles";

.playlist-accordion {
  display: inline-flex;
  flex-direction: column;
  flex: 1;

  & > .playlist-header {
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 35px;
    height: 66px;
    line-height: 30px;
    background-color: rgba($color: #ffffff, $alpha: 0.05);
    border: 1px solid rgba($color: #ffffff, $alpha: 0.1);
    border-left: none;
    border-right: none;

    & > .playlist-info {
      width: 100%;
      display: inline-flex;
      flex-direction: row;
      align-items: baseline;
      position: relative;

      & > .children-count {
        margin: 0 15px;
        font-size: 23px;
        cursor: pointer;
      }

      .children-count {
        background-color: $white;
        color: $blue;
        font-weight: 700;
        border-radius: 6px;
        font-size: 12px;
        padding: 0 5px;
        margin-left: 15px;
        top: 0;
        min-width: 40px;
        text-align: center;
        right: 15px;
        position: absolute;
      }

      & > .input-wrap {
        width: 100%;
        flex-direction: row;
        display: inline-flex;

        & .input {
          width: 100%;
          background-color: transparent;
          border: none;
        }
      }
    }

    & > .expend-toggle {
      color: #5986ff;
      cursor: pointer;
      font-size: 18px;
    }
  }

  & > .playlist-body {
    display: inline-flex;
    flex-direction: column;
    flex: 1;
  }
}
