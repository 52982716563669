@import "sass/variabiles";

.video-list-item-wrap {
  position: relative;
  width: calc(100% + 10px);
  padding-right: 10px;
  height: 145px;
  text-align: left;
  margin: 5px 0;

  &.invalid {
    background-color: rgba(238, 73, 133, 0.14);

    &:hover {
      background-color: rgba(238, 73, 133, 0.34);
    }

    border-radius: 6px;
  }

  .transparent-select {
    .app-select__single-value,
    .app-select__placeholder {
      transform: translateY(-5px) !important;
    }
  }

  .controls-hover {
    display: flex;
    position: absolute;
    width: 100%;
    align-items: center;
    height: 100%;
    opacity: 0;

    .app-select__menu {
      min-width: 145px !important;
    }
  }

  &:hover {
    background: rgba(255, 255, 255, 0.02);

    .hide-on-hover {
      opacity: 0;
    }

    .hide-category {
      .selected-category {
        opacity: 0;
      }
    }

    .controls-hover {
      opacity: 1;
    }
  }

  .hide-on-hover {
    width: 100%;
  }

  .video-list-item {
    justify-content: flex-start;
    flex-direction: row;
    position: relative;
    font-size: 14px;
    color: #72818a;
    width: 100%;
    height: 145px;
    padding: 20px 0;
    margin-left: 10px;

    .check-coll {
      label {
        position: relative;
        top: 50%;
        margin-top: -17px;
      }
    }

    .video-details-list {
      padding-left: 0;
      margin: 0;
      height: 100%;

      > li {
        list-style-type: none;
        display: inline-block;
        vertical-align: middle;
        height: 100%;

        &.thumb {
          position: relative;
          max-width: 32%;
          width: 140px;
          height: 85px;
          max-height: 74%;
          border-radius: 6px;
          overflow: hidden;
          margin-right: 20px;
          cursor: pointer;
          background: linear-gradient(0deg, rgba(255, 255, 255, 0.11), rgba(255, 255, 255, 0.11)),
            linear-gradient(360deg, rgba(4, 6, 43, 0.75) 11.7%, rgba(5, 6, 28, 0.5) 94.79%);
          @media screen and (min-width: 1424px) {
            max-height: 80%;
          }
          .icon-stream,
          .icon-record {
            color: #fff;
            font-size: 20px;
            position: absolute;
            bottom: 5px;
            text-shadow: 0 2px 1px rgba(0, 0, 0, 0.2), 0 1px 1px rgba(0, 0, 0, 0.14),
              0 1px 3px rgba(0, 0, 0, 0.12);
            right: 10px;
          }

          .icon-record {
            right: 40px;
            font-size: 14px;
            bottom: 8px;
            color: #ff005d;
            animation: liveIcon 2s ease infinite;
          }

          .edit-video {
            left: 0 !important;
            top: 0 !important;
            display: inline-block;
            background: rgba(0, 0, 0, 0.3);
            opacity: 0;
            width: 100%;
            height: 100%;
            transition: all 0.3s ease;
          }

          &:hover {
            .edit-video {
              opacity: 1;
            }
          }

          .video-thumb {
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            background-position: center;
            background-size: cover;
          }

          .default-thumb {
            display: flex;
            position: absolute;
            width: 100%;
            height: 100%;
            align-items: center;
            justify-content: center;

            i {
              font-size: 30px;
            }
          }

          img {
            border-radius: 6px;
          }

          & > .duration {
            position: absolute;
            bottom: 5px;
            right: 5px;
            font-size: 12px;
            font-weight: 400;
            line-height: 17px;
            height: 19px;
            padding: 2px 4px;
            background-color: #575869;
            border-radius: 4px;
            opacity: 0.8;
            color: #ffffff;
          }

          & > .icon {
            position: absolute;
            font-size: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #ffffff;
          }
        }

        &.info {
          width: 60%;
          display: inline-flex;
          flex-direction: column;
          justify-content: center;
          position: relative;
          align-items: center;

          > div {
            width: 100%;
          }

          &.invalid-video {
            align-items: flex-start;
          }

          .title {
            margin: 0;
            font-weight: $bold;
            color: $white;
            font-size: 15px;
            width: 100%;
            line-height: 23px;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          .description {
            font-size: 14px;
            line-height: 22px;
            margin: 0;
            color: #72818a;

            span {
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              padding-top: 10px;
            }
          }

          .controls-hover {
            .icon {
              background: transparent;
              color: $white;
              padding-right: 25px;
              padding-left: 0;
              font-size: 20px;

              &:hover {
                color: $blue;
              }

              &.icon-remove:hover {
                color: #ee4985;
              }
            }
          }
        }
      }
    }

    .video-status {
      color: $white;
      display: flex;
      align-items: center;
      font-size: 14px;
      height: 100%;

      i {
        font-size: 20px;
        padding-left: 6px;
        padding-right: 4px;
        position: relative;
        top: 3px;
      }
    }

    .selected-category,
    .added-date {
      color: $text-gray;
      display: flex;
      align-items: center;
      font-size: 14px;
      height: 100%;
    }

    .views-number {
      color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 15px;
      height: 100%;

      .controls-hover {
        justify-content: center;
      }
    }

    & .likes-range {
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 12px;
      width: 100%;
      height: 100%;

      & > .reactions-label {
        display: inline-flex;
        justify-content: space-between;
        padding: 0 1px;
        width: 100%;

        & > .positive {
          color: #4f59d9;
        }

        & > .negative {
          color: #ee4985;
        }
      }

      & > .likes-range-input {
        display: inline-flex;
        flex-direction: row;
        margin-top: 5px;
        background-color: #2f303f;
        height: 2px;

        & > .volume {
          display: inline-flex;

          &.positive {
            background-color: #4f59d9;
          }

          &.negative {
            background-color: #ee4985;
            border-left: 2px solid #10101e;
          }
        }
      }
    }
  }
}

.category-coll {
  .controls-hover {
    top: 0;
    font-size: 14px !important;
  }

  .app-select__option,
  .app-select__placeholder {
    font-size: 14px !important;
  }

  .app-select__value-container {
    .app-select__single-value {
      color: #72818a !important;
      padding-right: 4px;
    }
  }
}

.reactions-coll-data {
  position: relative;

  &.remove-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    & button {
      display: inline-flex;
      background: transparent;
      color: #fff;
      padding-right: 25px;
      padding-left: 0;
      font-size: 20px;
      width: 22px;
      height: 22px;
    }
  }

  .controls-hover {
    top: 0;
    left: 0;
    padding: 0 8px;
  }
}
