@import "sass/variabiles";

.selected-admin-item {
  background: #2f303f;
  border-radius: 6px;

  .heading-selected {
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    width: 100%;
    padding: 5px 15px;

    > i {
      cursor: pointer;
      color: #6d6e79;

      &.icon-edit {
        &:hover {
          color: $blue;
        }
      }

      &.icon-check-mark {
        &:hover {
          color: #3bc1a7 !important;
        }
      }
    }
  }

  .multiple-selected {
    border-top: 1px solid #1b1b28;

    .admin-chooser-list {
      padding: 15px 0;
      border-radius: 0;

      > .list {
        margin-bottom: 0;
      }

      .icon-search {
        margin: 0;
      }
    }

    .list {
      padding: 4px 20px 5px 15px;
      list-style-type: none;

      li {
        display: inline-flex;
        width: 100%;
        flex-direction: row;
        font-size: 16px;
        align-items: center;
        justify-content: space-between;

        i {
          font-size: 8px;
          cursor: pointer;
          color: #6d6e79;

          &:hover {
            color: $red;
          }
        }
      }
    }

    .checkbox-list {
      padding: 15px 20px 0;
      list-style-type: none;
      li {
        font-size: 16px;
        .label-text {
          &:before {
            margin-right: 10px !important;
          }
        }
      }
    }

    .search-input {
      padding: 0 20px 0 15px;
      position: relative;

      > i {
        position: absolute;
        font-size: 14px;
        top: 16px;
        right: 35px;
      }

      .search-selected-item {
        font-size: 14px;
        padding: 10px 35px 10px 12px;
        background: rgba(22, 22, 37, 0.5);
        margin-top: 4px;
      }
    }
  }

  .multiple-head {
    font-weight: $bold;
  }
}
