@import "sass/variabiles";

.admin-accordion {
  border-bottom: 1px solid #2f2f3c;
  overflow: hidden;
  max-height: 52px;
  transition: all 1.7s ease;
  &.opened {
    max-height: 1000px;
    overflow: visible;
  }

  .accordion-info {
    font-size: 16px;
    line-height: 50px;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.7);
    padding: 0 15px;
    display: flex;
    flex-direction: row;
    position: relative;

    > .icon-remove {
      &:hover {
        color: $red;
      }
    }

    > i {
      position: absolute;
      top: 18px;
      right: 15px;
    }

    &.active {
      color: $white;
      font-weight: bold;
    }
  }

  .accordion-title {
    i {
      font-size: 18px;
      position: relative;
      top: 3px;
      left: 10px;
      color: #484856;
      font-weight: $regular;
    }
  }

  .accordion-body {
    position: relative;
    padding: 0 15px 15px;
    width: 80%;
    margin-top: -6px;
    margin-left: 20%;

    &:before {
      content: "";
      border: 2px solid rgba(255, 255, 255, 0.2);
      border-top: none;
      border-right: none;
      border-bottom-left-radius: 10px;
      position: absolute;
      width: 18px;
      height: 18px;
      left: -13px;
      top: 0;
    }
  }
}
