@import "sass/variabiles";

.ban-list {
  width: calc(100% + 10px);
  .check-coll {
    width: 38px;
    .check-option {
      text-align: center;
      .label-text {
        &:before {
          left: 10px;
          top: -3px;
        }
      }
    }
  }

  .date-coll,
  .action-coll {
    width: 120px;
    text-align: center;
  }

  .action-coll {
    //width: 250px;
  }

  .heading-table {
    padding-bottom: 4px;
    margin-bottom: 15px;
    margin-right: 0;
    position: relative;

    &:after {
      content: "";
      background-color: rgba(255, 255, 255, 0.15);
      height: 1px;
      width: calc(100% - 16px);
      left: 8px;
      bottom: 0;
      position: absolute;
    }
  }

  .app-pagination {
    padding-left: 10px;
  }

  .heading-option {
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #72818a;
    font-weight: 700;
    font-size: 12px;
    padding-top: 4px;
    line-height: 24px;
  }

  .ban-all-list {
    .ban-item {
      margin-right: 0;
      padding: 6px 0;

      .duration-coll,
      .date-coll {
        font-size: 15px;
        line-height: 32px;
        color: #72818a;
      }

      .user-item {
        .link-to-streamer {
          display: inline-flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          font-size: 16px;

          .avatar {
            display: inline-flex;
            justify-content: center;
            border-radius: 50%;
            margin-left: 0;
            margin-bottom: 2px;
            line-height: 8px;
            font-size: 10px;
            width: 28px;
            height: 28px;
            cursor: pointer;
            background-position: center;
            background-size: cover;
            position: relative;
            overflow: hidden;
          }

          span {
            padding-left: 10px;
            color: $white;
            font-weight: $bold;
            font-size: 16px;
            line-height: 24px;
          }

          .name-letters {
            padding-left: 0;
            font-size: 11px;
            position: absolute;
            margin-top: 2px;
          }

          &:hover {
            span:not(.name-letters) {
              color: $blue;
            }
          }
        }
      }
    }
  }
}

.empty-ban-list {
  position: relative;
  margin-top: 22%;
  text-align: center;
  transform: translateY(-50%);

  > i {
    font-size: 70px;
    display: inline-block;
    padding-bottom: 42px;
    color: #3c3d4c;
  }

  .empty-message {
    margin-bottom: 32px;
    color: #72818a;
    font-size: 23px;
  }
}
