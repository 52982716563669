@import "sass/variabiles";

.help-article {
  animation: fadeIn 1s ease;

  .article-title {
    font-weight: bold;
    font-size: 46px;
    line-height: 57px;
    margin: 0 0 40px;
    color: $white;
    animation: fadeInDown 2s ease;

    @media screen and (max-width: $mobile) {
      font-size: 22px;
      line-height: 28px;
      margin: 0 0 20px;
    }
  }

  .big-topic {
    font-size: 24px;
    line-height: 36px;
    color: rgba(255, 255, 255, 0.5);
    @media screen and (max-width: $mobile) {
      font-size: 16px;
      line-height: 24px;
    }

    &.bold {
      font-weight: $bold;
      font-size: 20px;
      color: $white;
      padding-top: 15px;
    }
  }

  .description {
    font-size: 18px;
    line-height: 32px;
    color: rgba(255, 255, 255, 0.5);
    padding: 25px 0;
    max-width: 790px;
    margin: 0;
    @media screen and (max-width: $mobile) {
      padding: 10px 0;
      font-size: 14px;
      line-height: 22px;
    }

    &.top {
      padding-top: 0;
    }

    > a {
      color: $blue;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  img {
    width: 100%;
    max-width: 800px;
    border-radius: 6px;
  }

  ol {
    list-style: none;
    padding: 0 0 25px 0;
    max-width: 800px;
    counter-reset: custom-counter;
    @media screen and (max-width: $mobile) {
      font-size: 12px;
      line-height: 20px;
      padding: 0 0 10px 0;
      margin: 0;
    }

    li {
      counter-increment: custom-counter;
      position: relative;
      padding-left: 25px;
      color: $white;

      &:before {
        content: counter(custom-counter) ". ";
        position: absolute;
        color: $blue;
        left: 0;
      }
    }
  }

  ul {
    padding: 0;
    list-style-type: none;

    li {
      position: relative;
      padding-left: 25px;

      &:before {
        content: "\2022";
        color: $blue;
        font-weight: bold;
        display: inline-block;
        width: 18px;
        position: absolute;
        left: 0;
      }
    }
  }

  .top-more {
    padding-top: 15px;
  }
}
