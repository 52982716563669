@import "sass/variabiles";

.init-stream-modal {
  min-width: 1100px;
  overflow: hidden;
  background: #06081d;

  .section-label {
    margin-bottom: 10px;
    display: inline-block;
  }

  .stream-modal-header {
    border-bottom: none !important;
    &.manager {
      background: #050614;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
    }
  }

  .stream-mode-tabs {
    margin-top: 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    .col-xs-6 {
      padding: 0;
    }
  }

  .stream-tab {
    text-align: center;
    font-weight: $regular;
    font-size: 25px;
    line-height: 44px;
    color: rgba(255, 255, 255, 0.3);
    padding: 15px 10px;
    cursor: pointer;
    max-width: 490px;
    width: 100%;
    position: relative;
    top: 1px;
    z-index: 20;

    &.left {
      float: right;
    }

    &.active {
      color: rgba(255, 255, 255, 1);
      background: #050614;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      border: 1px solid #1e1f2b;
      border-bottom: none;

      &:before,
      &:after {
        content: "";
        position: absolute;
        height: 10px;
        width: 20px;
        bottom: 0;
      }

      &:after {
        right: -21px;
        border-radius: 0 0 0 8px;
        -moz-border-radius: 0 0 0 8px;
        -webkit-border-radius: 0 0 0 8px;
        border-bottom: 1px solid #1e1f2b;
        border-left: 1px solid #1e1f2b;
        box-shadow: -5px 1px 0 #050614;
      }

      &:before {
        left: -21px;
        border-radius: 0 0 8px 0;
        -moz-border-radius: 0 0 8px 0;
        -webkit-border-radius: 0 0 8px 0;
        border-bottom: 1px solid #1e1f2b;
        border-right: 1px solid #1e1f2b;
        box-shadow: 5px 1px 0 #050614;
      }
    }
  }

  .thumb-change-button {
    background-color: rgba($color: #ffffff, $alpha: 0.15);
    padding: 10px 15px;
    border-radius: 6px;

    & .icon {
      opacity: 0.35;
      font-size: 20px;
      margin-right: 10px;
      position: relative;
      top: 3px;
    }
  }

  .show-comments-switch {
    margin-right: 15px;
    margin-bottom: -5px;
  }

  .row {
    margin: 0;
  }

  .modal-heading {
    font-size: 28px;
  }

  .schedule-toggle {
    margin-top: 4px;
    margin-left: 20px;
  }

  .date-picker-container {
    padding-left: 30px;
    display: inline-flex;
    position: relative;
    top: -5px;
    z-index: 99;

    .main-input {
      margin-left: 15px;
      padding: 12px 15px 12px 40px;
      display: inline-block;
    }

    .date-picker {
      width: 160px;
    }

    .time-picker {
      width: 140px;
    }
  }

  .schedule-options {
    display: inline-flex;

    &.preview-mode {
      opacity: 0.6;
      cursor: default;

      .slider {
        cursor: default !important;
      }

      label {
        cursor: default !important;
      }

      .main-input {
        cursor: default;

        &:focus {
          border: 1px solid #2e2e3b;
        }
      }
    }

    .react-datepicker-popper {
      margin-left: 15px;
    }

    .select-container {
      position: relative;

      i {
        position: absolute;
        left: 28px;
        top: 14px;
      }
    }
  }

  .gear-setup {
    padding-right: 45px;

    .main-input {
      max-width: 360px;
    }
  }

  .modal-body.init-stream {
    .modal-content-container {
      padding: 35px 50px 50px;
      background: #050614;
    }

    & .thumb-preview {
      & > .thumb {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 395px;
        height: 220px;
        border-radius: 6px;
        margin-bottom: 20px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-image: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.11),
            rgba(255, 255, 255, 0.11)
          ),
          linear-gradient(360deg, rgba(4, 6, 43, 0.75) 11.7%, rgba(5, 6, 28, 0.5) 94.79%);

        i {
          opacity: 0.2;
          font-size: 65px;
        }
      }

      & > .details-row {
        margin-top: 5px;
        flex-direction: column;

        & > .label {
          font-size: 18px;
          margin: 18px 0;
        }

        & > .value {
          font-size: 16px;
        }
      }
    }

    .video-privacy-options {
      border: 1px solid rgba(219, 222, 231, 0.25);
      box-sizing: border-box;
      border-radius: 6px;
      padding: 33px 40px;
    }
  }

  .modal-footer {
    padding: 20px 50px !important;
    background: #050614;

    .video-status {
      margin-top: 7px;

      span {
        font-size: 15px;
        opacity: 0.35;
      }

      .icon {
        font-size: 12px;
        margin-right: 10px;
      }
    }

    .submit-controls {
      color: #000000;
      font-size: 20px;
      text-align: right;

      & > .next-button,
      .main-button {
        width: 187px;
        height: 50px;
        cursor: pointer;
        border-radius: 6px;
        font-weight: 600;
      }

      .next-button {
        transition: all 0.3s ease;
        background: $white;
        color: #212121;

        &:hover {
          background: rgba(255, 255, 255, 0.15);
          color: $white;
        }
      }

      & > .back-button {
        background-color: transparent;
        color: #ffffff;
        cursor: pointer;
        margin-right: 30px;

        &:hover {
          opacity: 0.6;
        }
      }

      & > .go-live {
        background-color: #ee4985;
        margin-left: 25px;

        &:hover {
          background-color: #bf235c;
        }
      }
    }
  }
}
