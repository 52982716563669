@import "sass/variabiles";
@import "sass/mixins";

.video-library {
  color: #72818a;
  text-align: center;
  position: relative;
  min-height: 75vh;
  padding-bottom: 30px;

  .filters-toggle {
    position: absolute;
    right: -8px;
    top: 26px;
  }

  .filter-search {
    padding-left: 10px;

    .search-input {
      @include main-input();
      background: transparent;
      max-width: 0;
      margin: 0;
      padding: 1px;
      font-weight: $regular;
      border: none !important;
      position: relative;
      top: 1px;
      opacity: 0;
      -webkit-transition: all 0.4s ease;
      -moz-transition: all 0.4s ease;
      -ms-transition: all 0.4s ease;
      -o-transition: all 0.4s ease;
      transition: all 0.4s ease;

      &.active {
        max-width: 200px;
        opacity: 1;
      }
    }

    i {
      padding-right: 10px;
      position: relative;
      cursor: pointer;
      top: 1px;
      font-size: 16px;

      &:hover,
      &.active {
        color: #5986ff;
      }
    }
  }

  .check-coll {
    width: 3.5%;
  }

  .video-coll {
    width: 41%;
    position: relative;

    &.heading-option {
      padding-top: 1px;
    }
  }

  .visibility-coll {
    position: relative;
    width: 13%;
  }

  .category-coll {
    position: relative;
    width: 13%;
  }

  .date-coll {
    width: 10%;
  }

  .views-coll {
    width: 6%;
    position: relative;
    text-align: center;
  }

  .reactions-coll {
    text-align: center;
  }

  .heading-option {
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #72818a;
    font-weight: 700;
    font-size: 12px;
    padding-top: 4px;
    line-height: 24px;
  }

  & > .video-library-header {
    flex-direction: row;
    background-color: rgba($color: #ffffff, $alpha: 0.15);
    margin-bottom: 0;
    padding: 0 15px;
    border-radius: 6px;
    max-height: 0;
    color: #ffffff;
    overflow: hidden;
    position: relative;
    z-index: 99;
    animation: 0.4s fadeInFilter forwards ease;

    & > .control-item {
      display: inline-flex;
      font-size: 16px;
      margin: 0 10px;
      line-height: 20px;
      padding: 4px 0;
      cursor: pointer;

      .checkbox-dropdown-select {
        margin-right: 12px;
      }

      &:first-child {
        margin-right: 18px;
      }

      &:last-child {
        margin-left: auto;
      }

      .app-select__menu {
        min-width: 170px !important;
        text-align: left;

        .app-select__option {
          .custom-status-option {
            font-size: 16px;

            i {
              font-size: 20px;
              position: relative;
              top: 2px;
              padding-left: 10px;
            }
          }
        }
      }

      .app-select__single-value {
        i {
          padding-left: 6px;
          padding-right: 2px;
          position: relative;
          top: 2px;
        }
      }

      &.visibility-item {
        .app-select__single-value {
          position: relative;
          top: -2px;
        }
      }

      &.selected-items-count {
        background-color: #ffffff;
        font-weight: 800;
        color: #4f59d9;
        letter-spacing: 2px;
        align-items: center;
        border-radius: 20px;
        padding: 4px 22px;
        font-size: 12px;
      }

      &.delete-button {
        color: #ee4985;
        line-height: 23px;

        & > i {
          font-size: 20px;
          margin-right: 10px;
        }
      }
    }
  }

  & > .heading {
    flex-direction: row;
    padding-left: 10px;
    text-align: left;
    margin-bottom: 10px;
    margin-left: 0;
    font-size: 12px;
    text-transform: uppercase;
    margin-top: 20px;
    border-bottom: 1px solid rgba($color: #ffffff, $alpha: 0.1);
  }

  .empty-library {
    position: relative;
    margin-top: 25%;
    transform: translateY(-50%);

    &.streams-empty {
      i {
        font-size: 80px;
      }
    }

    > i {
      font-size: 70px;
      display: inline-block;
      padding-bottom: 42px;
      color: #3c3d4c;
    }

    .empty-message {
      margin-bottom: 32px;
      color: #72818a;
      font-size: 23px;
    }

    .main-button {
      padding: 8px 30px;
    }
  }

  .submit-button {
    width: 25px;
    height: 25px;
    margin-left: 10px;
    display: inline-block;
    background-color: #3ba894;
    border-radius: 50%;
    cursor: pointer;
    transition: all ease 0.3s;

    i {
      position: relative;
      top: -6px;
      font-size: 14px;
    }

    &:hover {
      transform: scale(1.2);
    }
  }

  .cancel-button {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    font-size: 10px;
    background-color: #2f303f;
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
    transition: all ease 0.3s;

    i {
      position: relative;
      top: -6px;
      font-size: 10px;
    }

    &:hover {
      transform: scale(1.2);
    }
  }
}

@-webkit-keyframes fadeInFilter {
  from {
    opacity: 0;
    max-height: 0;
    margin-bottom: 0;
    padding: 0 15px;
  }

  to {
    opacity: 1;
    max-height: 250px;
    margin-bottom: 20px;
    padding: 15px;
  }
}

@keyframes fadeInFilter {
  from {
    opacity: 0;
    max-height: 0;
    padding: 0 15px;
  }

  to {
    opacity: 1;
    max-height: 150px;
    padding: 15px;
    overflow: visible;
  }
}

.videos-search-empty {
  padding: 45px 0;
  font-size: 18px;

  i {
    font-size: 24px;
    padding-right: 15px;
  }
}
