@import "sass/variabiles";

.assets-classes {
  padding: 10px 10px 15px;

  .class-coll {
    padding-left: 10px;
  }

  .label-coll {
    font-weight: 800;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 2px;
    padding-top: 10px;
    text-transform: uppercase;
    color: #72818a;
  }

  .items-list {
    list-style-type: none;
    display: inline-flex;
    flex-direction: row;
    padding: 0;
    margin-right: 10px;

    .select-item {
      display: flex;
      flex-direction: row;
      font-size: 16px;
      line-height: 24px;
      align-items: center;
      padding: 6px 15px;
      background: #2f303f;
      margin-left: 15px;
      margin-top: 3px;
      border-radius: 26px;

      > i {
        font-size: 8px;
        padding-left: 10px;
        position: relative;
        color: #787882;
        cursor: pointer;
        top: 2px;

        &:hover {
          color: $red;
        }
      }
    }
  }

  .dropdown-coll {
    .admin-select {
      min-width: 200px;
    }
  }
}
