@import "sass/variabiles";

.live-chat-header {
  border-radius: 6px;
  padding: 20px;
  height: 60px;
  background: #0d1031;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    width: 99%;
    height: 90px;
    display: block;
    background: linear-gradient(180deg, #0d1031 45.31%, rgba(13, 16, 49, 0) 100%);
    left: 0px;
    top: 30px;
  }

  &__block-left,
  &__block-right {
    display: flex;
    align-items: center;
    position: relative;
  }

  &__icon-message {
    font-size: 22px;
  }

  &__title {
    font-weight: bold;
    font-size: 23px;
    line-height: 27px;
    margin-left: 13px;
  }

  &__icon-settings {
    color: rgba(255, 255, 255, 0.3);
    font-size: 24px;
    cursor: pointer;

    &:hover {
      color: $white;
    }
  }
}

.live-chat-menu {
  min-width: 130px;
  width: 100%;
  position: absolute;
  background-color: #1b1b28;
  border: 1px solid #2e2e3b;
  flex-direction: column;
  display: flex;
  border-radius: 6px;
  font-size: 16px;
  line-height: 32px;
  right: 0;
  top: 35px;
  z-index: 999;

  &__item {
    cursor: pointer;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 15px;

    &:hover {
      background: rgba(255, 255, 255, 0.05);
    }
  }
}
