@import "sass/variabiles";

.playlist-post-item {
  border-radius: 6px;
  min-height: 360px;
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
  border: 1px solid #323644;
  background: linear-gradient(180deg, #0a0a1a 28.25%, rgba(16, 16, 28, 0) 97.98%);

  .heading-thumb {
    height: 168px;
    position: relative;
    background-position: center;
    background-size: cover;
    background-color: #2e2e3c;

    > .icon-img-thumb {
      position: absolute;
      font-size: 60px;
      opacity: 0.4;
      text-align: center;
      display: inline-block;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
    }

    .overlay {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(360deg, rgba(4, 6, 43, 0.375) 11.7%, rgba(5, 6, 28, 0.25) 94.79%);
    }
  }

  .playlist-info {
    padding: 25px 0 15px;
    @media only screen and (max-width: $mobile) {
      margin-left: 8px;
    }

    li {
      list-style-type: none;
      display: inline-block;
      padding-right: 17px;
      font-size: 14px;
      opacity: 0.5;

      i {
        padding-right: 8px;
        font-size: 13px;

        &.icon-circle-play {
          font-size: 15px;
          position: relative;
          padding-right: 4px;
          top: 1px;
          width: 13px;
        }
      }
    }
  }

  .content {
    padding: 20px 25px 25px;

    .title-icon {
      font-size: 20px;
      padding-left: 8px;
    }

    .title {
      font-size: 24px;
      line-height: 27px;
      font-weight: $regular;
      margin: 0;
      padding-left: 20px;
    }

    .playlist-btn {
      font-size: 16px;
      padding: 2px 20px;
      color: $white;
      @media only screen and (max-width: $mobile) {
        width: 100%;
      }
    }
  }
}
