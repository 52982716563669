@import "sass/variabiles";

.admin-container {
  background: #1b1b28;
  padding: 0 25px;
  border-radius: 6px;
}

.profile.page-content {
  min-height: 100vh;
  padding: 120px 0 35px;

  &:after {
    height: 1330px !important;
  }

  @media only screen and (max-width: $mobile) {
    padding: 86px 0 35px;
  }

  .confirm-notification {
    width: auto;
    background: #751a3b;
    padding: 12px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto 35px auto;
    max-width: 100%;
    border-radius: 10px;

    @media screen and (max-width: $mobile) {
      flex-direction: column;
      .resend {
        margin: 10px 0 5px;
      }
    }

    &-block {
      display: flex;
      align-items: center;
    }

    .icon-warning-alert {
      color: #ee4985;
      position: relative;
      z-index: 9;
      &::after {
        content: "";
        display: block;
        background: white;
        width: 4px;
        height: 12px;
        position: absolute;
        top: 4px;
        left: 7px;
        z-index: -1;
      }
    }

    .icon-check-mark {
      color: #fff;
    }

    .text {
      font-size: 16px;
      line-height: 23px;
      font-style: normal;
      font-weight: normal;
      color: #fff;
      margin: 0 29px 0 9px;
    }

    .resend {
      background: none;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 23px;
      height: 23px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
    }

    .send {
      display: flex;
      align-items: center;

      .icon-check-mark {
        font-size: 9px;
        margin-right: 10px;
      }
      .send-text {
        color: #ffffff;
        opacity: 0.5;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 23px;
      }
    }
  }

  .nav-tabs-wrap {
    margin-top: 20px;
    margin-left: 40px;
  }

  &:after {
    height: 100%;
  }

  .sidebar-container {
    padding-right: 35px;
    max-width: 300px;
    width: 100%;
    > .col-xs {
      padding-right: 0 !important;
    }
    @media only screen and (max-width: $mobile) {
      display: none;
    }
  }

  .profile-sidebar {
    background: #1b1b28;
    padding: 30px 20px 10px;
    border-radius: 6px;

    hr {
      background: $white;
      opacity: 0.1;
      margin: 20px 0;
    }

    .header-info {
      text-align: center;
      padding-bottom: 5px;

      .name {
        font-size: 24px;
        line-height: 27px;
        font-weight: $regular;
        word-break: break-word;
        margin: 15px 0 0;
      }

      .description {
        font-size: 16px;
        line-height: 24px;
        color: $white;
        opacity: 0.5;
        padding: 10px 0;
      }
    }

    .sections-list {
      padding-left: 0;

      & > a {
        list-style-type: none;
        margin-bottom: 8px;
        border: 1px solid transparent;
        font-size: 16px;
        line-height: 20px;
        display: inline-block;
        padding: 7px 20px;
        text-decoration: none;
        width: 100%;
        position: relative;
        color: $white;

        .badge-counter {
          background: #ee4985;
          position: absolute;
          right: 10px;
          top: 9px;
        }

        i {
          padding-right: 13px;
          position: relative;
          top: 1px;
        }

        &.active,
        &:hover {
          border: 1px solid #5986ff;
          border-radius: 6px;
        }
      }
    }
  }

  .section-heading {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    @media only screen and (max-width: $mobile) {
      margin-bottom: 25px;
    }

    .main-heading {
      font-weight: 700;
      font-size: 46px;
      text-align: left;
      color: #ffffff;
      @media only screen and (max-width: $mobile) {
        margin: 0;
        font-size: 28px;
      }
    }
  }
}

.sections-list {
  &.viewer-sections {
    min-height: 300px;
  }

  .viewer-sections-item.disabled {
    opacity: 0.3;
    pointer-events: none;
  }
}

.create-channel-trigger {
  text-align: center;
  font-size: 16px;
  color: #3ba894;
  font-weight: $regular;
  cursor: pointer;
  margin-bottom: 15px;
  &.disabled {
    opacity: 0.3 !important;
    pointer-events: none !important;
  }

  .icon-wrapper {
    color: $white;
    border-radius: 50px;
    font-size: 9px;
    padding: 4px;
    background: #3ba894;
    margin-right: 10px;
    position: relative;
    top: -2px;
  }

  &:hover {
    opacity: 0.5;
  }
}
