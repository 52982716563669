@import "../../../../../../sass/variabiles";

.empty-playlist-archive {
  position: relative;
  margin-top: 16%;
  text-align: center;
  width: 100%;
  transform: translateY(-50%);
  margin-bottom: 150px;
  @media only screen and (max-width: $mobile) {
    margin-top: 100px;
  }

  > i {
    font-size: 50px;
    display: inline-block;
    padding-bottom: 42px;
    color: #3c3d4c;
  }

  .empty-message {
    margin-bottom: 32px;
    color: #72818a;
    font-size: 22px;
  }
}

.archive-loader {
  position: relative;
  min-height: 400px;
  width: 100%;
}
