@import "sass/variabiles";

.create-channel-modal {
  min-width: 915px;

  @media screen and (max-width: $mobile) {
    min-width: 100%;

    .modal-heading {
      font-size: 18px;
      position: relative;
      top: -3px;
    }
  }

  + .popup-icon-close {
    right: 35px;
  }

  .modal-content {
    padding: 35px 45px;
    min-height: 450px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 95%;

    @media screen and (max-width: $mobile) {
      .modal-content {
        background-image: none !important;
      }
    }

    .become-creator-container {
      max-width: 550px;
      padding-top: 60px;
      width: 100%;
      margin: 0 auto;

      @media screen and (max-width: $mobile) {
        padding-top: 0;

        img {
          display: block;
          margin: 0 auto 22px;
          max-width: 150px;
        }
      }

      .info {
        h3 {
          font-size: 22px;
          margin: 0;
        }

        p {
          display: inline-block;
          padding-top: 25px;
          font-size: 16px;
          @media screen and (max-width: $mobile) {
            padding-top: 12px;
          }
        }
      }
    }

    .channel-picture {
      .account-avatar {
        padding-top: 14px;
      }

      .thumbnail-container {
        margin: 0;
      }
    }
  }

  .modal-footer {
    padding: 25px 38px 18px;
    text-align: right;
    border-top: 1px solid rgba(255, 255, 255, 0.1);

    .row {
      width: 100%;

      .col-xs-9 {
        text-align: left;
      }

      .col-xs-3 {
        padding-right: 0;
      }
    }

    .main-button {
      padding: 10px 45px;
      position: relative;
      left: 12px;
      @media screen and (max-width: $mobile) {
        font-size: 16px;
        padding: 6px 25px;
        margin: 10px 0;
      }
    }
  }
}

.channel-form {
  @media screen and (max-width: $mobile) {
    .main-input,
    .slug-base {
      font-size: 16px;
    }

    .modal-content {
      padding: 15px 25px 35px;
    }

    .account-avatar {
      text-align: center;
      margin-bottom: 15px;

      .thumbnail-container {
        display: inline-block;
      }
    }

    .modal-footer {
      padding: 10px 25px 25px !important;

      .main-button {
        width: 100%;
      }
    }
  }

  label {
    font-weight: $bold;
    margin-bottom: 15px;
  }

  input,
  textarea {
    width: 100%;
  }

  .input-description {
    line-height: 22px;
    min-height: 102px;
  }

  .label-description {
    padding: 0;
    font-weight: $regular;
    font-size: 16px;
    margin-bottom: 20px;
  }

  .terms-conditions-check {
    font-size: 15px;
    font-weight: $regular;
    margin-top: 10px;
    margin-bottom: 0;
  }

  .slug-input-container .main-input {
    padding: 13px 0 13px 170px;
    @media screen and (max-width: $mobile) {
      padding: 13px 0 13px 153px;
    }
  }

  .col-xs-9 > .main-input {
    margin-bottom: 15px;
    padding: 13px 24px;
  }
}
