.admin-select {
  .app-select__control {
    border: 1px solid #60616d !important;
    border-radius: 6px;
    cursor: pointer;
    padding: 2px;
  }

  .main-button {
    padding: 0 15px !important;
    font-size: 14px !important;
    margin-left: 10px;
  }

  .app-select__clear-indicator {
    padding: 0 !important;
  }
  .app-select__dropdown-indicator {
    padding-left: 0 !important;
  }

  .app-select__menu {
    box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.75);
    &:hover {
      .checkbox-option {
        background: transparent !important;
      }
    }
    .app-select__option,
    .app-select__option--is-focused {
      &.checkbox-option {
        background: transparent !important;
        font-size: 16px !important;
        padding: 0 10px !important;
        &:first-child {
          padding-top: 15px !important;
        }
      }
    }
  }

  .app-select__menu-notice--no-options {
    padding: 0;
  }
}
