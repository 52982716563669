@import "sass/variabiles";

.popupModal {
  position: fixed;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1010;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > .closer {
    position: fixed;
    width: 100%;
    height: calc(100% + 160px);
    background-color: #000;
    opacity: 0.7;
    z-index: 99;
  }

  & > .content {
    position: relative;
    width: 100%;
    overflow-y: auto;
    max-width: min-content;
    max-height: 95vh;
    background: #050713;
    border-radius: 6px;
    box-shadow: 0 0 30px 17px rgbA(46, 46, 59, 0.35);
    z-index: 100;
    backface-visibility: hidden;
    -webkit-filter: blur(0);
    animation: fadeInDown 0.5s ease;
    @media only screen and (max-width: $mobile) {
      max-height: 100vh;
      width: 95%;
      max-width: 100%;
    }

    &.fadeOutUp {
      animation: fadeOutUp 0.4s ease forwards;
    }
  }

  .popup-icon-close {
    position: absolute;
    top: 38px;
    z-index: 100;
    right: 32px;
    font-size: 15px;
    cursor: pointer;
    @media only screen and (max-width: $mobile) {
      top: 30px;
      z-index: 100;
      right: 30px;
      font-size: 20px;
      cursor: pointer;
      opacity: 0.25;
    }

    &:hover {
      opacity: 0.5;
    }
  }

  .modal-header {
    display: inline-block;
    align-items: center;
    height: 82px;
    width: 100%;
    padding: 22px 50px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    &.row {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    .modal-heading {
      margin: 0;
      font-size: 28px;
      font-weight: $regular;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 45px;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }

  .modal-body {
    max-height: 60vh;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .modal-footer {
    display: inline-block;
    align-items: center;
    width: 100%;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding: 10px 50px;
  }
}
