.dm-overlay-root {
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
}

.dm-overlay-visible {
  visibility: visible;
  animation: fadeIn 0.5s ease forwards;
}
