@import "sass/variabiles";

.account-avatar {
  opacity: 0;

  &.first-state {
    opacity: 1;
  }

  &.channel {
    opacity: 1;

    > .thumbnail-container {
      background-color: #2f303f;
      background-size: cover;
      background-position: center;
    }

    .icon-placeholder {
      font-size: 65px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      color: $white;
      opacity: 0.2;
    }

    .main-button {
      width: 100%;
      max-width: 164px;
      margin: 20px auto 0;

      i {
        opacity: 0.5;
        font-size: 21px;
        padding-right: 7px;
      }
    }
  }

  > .thumbnail-container {
    width: 164px;
    height: 164px;
    border-radius: 50%;
    background-color: #4d4d61;
    background-position: center center;
    background-size: cover;
    margin: 0 auto;
    overflow: hidden;
    position: relative;

    .edit-controls-container {
      opacity: 0;
      transition: opacity 0.4s ease;
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: inline-flex;
      align-items: center;
      font-size: 24px;
      justify-content: center;

      i {
        cursor: pointer;
        padding: 0 15px;

        &:hover {
          color: #1627fd;
        }

        &.icon-remove {
          &:hover {
            color: #ee4985;
          }
        }
      }

      &:hover {
        opacity: 1;
      }
    }

    .name-letters {
      font-size: 64px;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
