@import "sass/variabiles";

.avatarContainer {
  position: relative;

  .liveBadge {
    position: absolute;
    width: 164px;
    height: 164px;
    margin-left: 5px;
    left: 50%;
    transform: translateX(-53%) scale(1.12);
    border-radius: 50%;
    border: 2px solid #ee4985;
    z-index: 7;

    .liveBeep {
      position: absolute;
      right: 7px;
      top: 7px;
      width: 32px;
      z-index: 6;
      height: 32px;
      border-radius: 50%;
      box-sizing: border-box;
      background: #0a0a1a;

      &:before {
        content: "";
        width: 10px;
        height: 10px;
        z-index: 12;
        left: 0;
        position: absolute;
        box-sizing: border-box;
        margin-top: 11px;
        margin-left: 11px;
        border-radius: 50%;
        background: #ee4985;
        animation: liveIcon 1.5s ease infinite;
      }
    }

    &:before {
      content: "";
      position: absolute;
      right: 11px;
      top: 11px;
      width: 24px;
      height: 24px;
      box-sizing: border-box;
      z-index: 8;
      border-radius: 50%;
      border: 2px solid #ee4985;
    }
  }
}

@-webkit-keyframes liveIcon {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

@keyframes liveIcon {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

.container {
  border: 1px solid rgba(219, 222, 231, 0.15);
  overflow: hidden;
  padding: 33px;
  border-radius: 6px;
  margin-top: 15px;
  min-height: 385px;
  text-align: center;
  margin-bottom: 20px;
}

.thumb {
  width: 164px;
  height: 164px;
  margin: 0 auto;
  border-radius: 50%;
  overflow: hidden;
  background-color: #4d4d61;
  background-position: center center;
  background-size: cover;
}

.nameLetters {
  font-size: 65px;
  color: #ffffff;
  line-height: 170px;
  font-weight: $bold;
}

.title {
  font-weight: $bold;
  font-size: 22px;
  padding: 0;
  height: 50px;
  margin: 20px auto 10px;
  line-height: 25px;
  text-align: center;
  max-width: 245px;
  word-break: break-word;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.subscriptions {
  padding: 2px 0 8px;

  i {
    padding-right: 10px;
    font-size: 22px;
    position: relative;
    top: 3px;
  }

  span {
    color: rgba(255, 255, 255, 0.5);
    font-size: 16px;
    line-height: 24px;
  }
}
