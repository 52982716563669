@import "sass/variabiles";

.checkbox-filter {
  position: absolute;
  margin-top: -6px;
  border-radius: 6px;
  overflow: hidden;
  width: 190px;

  .selected-item {
    background: #2f303f;
    cursor: default;
    color: $white;
    position: absolute;
    left: 0;
    font-size: 15px;
    top: 0;
    width: 100%;
    padding: 6px 20px;

    > i {
      top: 18px;
      right: 12px;
      position: absolute;
      cursor: pointer;
      font-size: 8px;

      &:hover {
        opacity: 0.4;
      }
    }

    &:hover {
      background: #2f303f;
    }
  }

  .dropdown-list {
    background: #181826;
    border: 1px solid #2e2e3b;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    margin-top: 42px;
    list-style-type: none;
    padding: 15px 0 40px;
    width: 100%;
    min-width: 192px;
    min-height: 300px;
    max-height: 300px;
    overflow: auto;

    > .option {
      padding: 4px 10px 4px 20px;
      cursor: pointer;
      line-height: 25px;
      font-size: 15px;
      color: #8b8b92;

      .label-text {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        height: 30px;
      }

      &:hover {
        background: none;
      }
    }

    .lds-ring {
      left: calc(50% - 33px);
      top: calc(50% - 33px);
      width: 60px;
      height: 60px;
    }
  }

  .main-button.apply-button {
    position: absolute;
    z-index: 999;
    bottom: 0;
    width: 100%;
    padding: 8px 0 !important;
    font-size: 16px;
    font-weight: $bold;

    &[disabled] {
      opacity: 1;
      background: #363a86;

      &:hover {
        background: #363a86;
      }
    }
  }
}
