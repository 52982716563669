.filters-chooser-list {
  background: transparent;
  padding: 10px 10px 5px 20px;
  border: 1px solid #2e2e3b;
  border-radius: 8px;
  margin-bottom: 18px;
  max-width: 305px;

  > .list {
    padding: 10px 0 0;
    list-style-type: none;
    max-height: 174px;
    overflow: auto;
    margin: 0;

    .label-text {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      height: 30px;
    }

    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }
  }
}
