@import "sass/variabiles";

.edit-channel-form {
  @media screen and (max-width: $mobile) {
    .main-input {
      font-size: 16px;
      padding: 12px 24px;
      line-height: 24px;
    }

    .save-options {
      padding-top: 20px;
      display: inline-flex;
      width: 100%;
      justify-content: space-between;

      .action-link {
        padding-top: 5px;
        font-size: 16px;
      }
    }

    .main-button {
      font-size: 16px;
      padding: 6px 15px;
    }
  }

  input,
  textarea {
    width: 100%;
  }

  label {
    font-weight: $bold;
    margin-bottom: 15px;
  }

  .input-description {
    line-height: 22px;
    min-height: 102px;
  }

  .slug-input-container {
    .slug-status {
      top: 19px;
    }

    .slug-base {
      top: 12px;
    }

    @media screen and (max-width: $mobile) {
      .slug-status {
        top: 17px;
        font-size: 16px;
      }

      .slug-base {
        top: 10px;
        font-size: 16px;
      }

      .main-input {
        padding-left: 154px;
      }
    }
  }
}

.channel-general-edit {
  padding-right: 21px;
  @media screen and (max-width: $mobile) {
    padding-right: 9px;
  }
}

.channel-links {
  padding-left: 21px;

  @media screen and (max-width: $mobile) {
    padding-left: 10px;
    padding-top: 15px;
  }

  .main-input {
    margin-bottom: 18px;
  }
}
