@import "sass/variabiles";

.comments-list {
  & .app-pagination {
    width: 100%;
  }

  .load-more-comments {
    width: 150px !important;
    font-size: 14px;
    line-height: 12px;
    margin: 22px auto 6px;
  }

  & .avatar {
    display: inline-flex;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    line-height: 22px;
    top: 25px;
    left: 20px;

    > .name-letters {
      width: 100%;
      text-align: center;
    }
  }

  & .comments-input {
    margin-bottom: 15px;
    margin-top: 0 !important;

    .name-letters {
      font-size: 10px;
    }
  }

  .no-comments-coll {
    padding-left: 0;
    opacity: 0.5;
    font-size: 18px;
    line-height: 32px;
  }

  > .col-xs-12 {
    padding-left: 0;

    .row {
      display: inline-flex;
      flex-wrap: nowrap;
      margin: 0;
    }

    > .comment-item.row {
      margin-bottom: 15px;
    }
  }

  & .replies-toggle {
    font-size: 14px;
    color: #5986ff;
    margin-left: 10px;
    position: relative;
    top: 7px;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }

    i {
      font-size: 10px;
      padding-right: 7px;
    }
  }

  & .comment-owner {
    flex-direction: row;
    padding-left: 0;

    & span {
      display: inline-flex;
      font-size: 14px;
      margin-left: 10px;
      color: $white;
    }

    a {
      > span {
        &:hover {
          color: #5986ff;
        }
      }
    }
  }

  & .comment-item.row {
    width: 100%;
    flex-direction: column;

    & .check-option {
      width: 30px;
      margin-right: 10px;
    }

    & .comment-content-wrapper {
      border: 1px solid rgba(255, 255, 255, 0.1);
      justify-content: space-between;
      box-sizing: border-box;
      flex-direction: column;
      border-radius: 6px;
      padding: 20px;
      width: 100%;
      margin: 0;

      &.with-checkbox {
        width: calc(100% - 40px);
      }

      &.unread {
        border-color: #ee4985;
      }

      .comments-input {
        margin-bottom: 8px !important;

        .actions-button {
          padding-left: 60px;
        }
      }

      & > .comment-label {
        flex-direction: row;
        width: 100%;

        & .avatar {
          width: 23px;
          height: 23px;
          position: relative;
          background-size: cover;
          background-position: center;
          background-color: #474751;

          left: 0;
          top: -2px;

          &.image-avatar {
            top: 6px;
          }

          > .name-letters {
            font-size: 10px;
            margin-left: 0;
            display: inline-block;
            width: 100%;
            text-align: center;
          }
        }

        & .comment-date {
          text-align: right;
          font-size: 12px;
          opacity: 0.5;
        }

        & > .comment-reactions {
          flex-direction: row;

          & > .controls {
            display: inline-flex;
            align-items: center;
          }
        }
      }

      & > .comment-content {
        font-size: 14px;
        line-height: 24px;
        width: 100%;
        margin: 0;
        white-space: pre-wrap;

        a {
          display: contents !important;
        }
      }
    }

    & .comment-footer-controls {
      font-size: 14px;
      margin-left: 5px;

      &.replies-option {
        height: 30px;
      }

      & > .controls {
        margin-right: 15px;
        opacity: 0.5;
        font-weight: $bold;
        cursor: pointer;

        &.reply {
          color: #5986ff;
          font-weight: bold;
          opacity: 1;

          &:hover {
            color: #8eb0ff !important;
          }
        }

        &.active {
          opacity: 1;
        }

        span {
          margin-left: 5px;
        }

        &:hover {
          opacity: 1;
          color: #5986ff;
        }

        &:nth-child(1),
        &:nth-child(2) {
          &:hover {
            opacity: inherit;
            color: inherit;
          }
        }

        &.delete-action {
          &:hover {
            color: #ee4985;
          }
        }

        &.ban-action {
          opacity: 1;
          color: #ee4985;

          &:hover {
            color: #ff5789;
          }

          &.banned {
            color: #3ba894;

            &:hover {
              opacity: 0.8;
            }
          }
        }
      }
    }

    & > .comment-footer {
      margin: 0;
      display: inline-flex;
      flex-direction: column;

      > .comment-replies {
        .actions-button {
          padding-left: 60px;
        }

        > .comment-item.row {
          margin-bottom: 15px;

          &:last-child {
            margin-bottom: 0;
          }

          &:first-child {
            margin-top: 15px;
          }
        }
      }

      &.with-checkbox {
        margin-left: 5%;

        & > .comment-replies {
          width: 100%;
          margin-left: 10px;
        }
      }

      & > .comment-replies {
        width: calc(100% - 50px);
        margin-left: 50px;
      }
    }
  }

  & .comments-input-toggle {
    cursor: pointer;
    padding-left: 8px;
    padding-top: 15px;
    padding-bottom: 25px;
    &.disabled {
      pointer-events: none;
      opacity: 0.3;
      cursor: default;
    }

    .add-comment-btn {
      background-color: #4f59d9;
      width: 25px;
      height: 25px;
      text-align: center;
      border-radius: 50px;
      margin-right: 15px;
      padding: 0;

      i {
        font-size: 14px;
        position: relative;
        top: -4px;
      }
    }

    > span {
      position: relative;
      top: -3px;
    }
  }

  & .comments-input {
    width: 100%;
    margin-right: 8px !important;
    margin-left: 0 !important;
    margin-top: 15px !important;
    position: relative;
    flex-direction: column;
    border: 1px solid #32323e;
    border-radius: 6px;
    font-size: 14px;

    &:focus-within {
      border: 1px solid rgba(66, 142, 255, 0.5);
    }

    .actions-button {
      padding: 8px 0 20px 65px;

      .submit-button {
        font-weight: $bold;
        padding: 15px;
      }

      .cancel-button {
        margin-left: 15px;
        font-weight: $bold;
        padding: 5px 12px;
        opacity: 0.5;

        background: transparent;

        &:hover {
          opacity: 1;
          color: #ee4985;
        }
      }
    }

    & .avatar {
      position: absolute;
      border-radius: 50%;
      overflow: hidden;
    }

    > .main-button {
      float: left;
      width: auto !important;
    }

    .new-comment-input {
      display: flex;
      resize: none;
      background-color: transparent;
      width: 100%;
      padding: 20px 20px 0 60px;
      border: none;

      &:focus {
        outline: none;
      }
    }
  }
}
