@import "sass/variabiles";

.root-wrap {
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.app-root {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.app-wrap {
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
  width: 100%;
}

.app-content {
  flex: 1;
}

.main-header {
  padding-top: 22px;
  position: absolute;
  width: calc(100% - 8px);
  z-index: 1001;
  transition: background 0.5s ease;

  .full-container {
    padding: 0 25px;
  }

  .dynamic-auto {
    padding-right: 8px;
  }

  &.fixed {
    background: linear-gradient(180deg, rgba(13, 16, 49, 0.83) 38.63%, rgba(9, 12, 43, 0) 100%);
    z-index: 100;
  }

  .search-container {
    text-align: center;
    padding-top: 5px;
    position: relative;
    justify-content: center;
    flex-direction: column;

    &-item {
      width: 100%;
      max-width: 330px;
      margin: 0 auto;
      position: relative;
    }

    .icon-search {
      position: absolute;
      margin-left: -35px;
      margin-top: 12px;
      font-size: 18px;
      cursor: pointer;
    }

    .result-suggestions {
      background: #1b1b28;
      border-radius: 6px;
      top: 44px;
      left: 0;
      max-width: 404px;
      width: calc(100% + 74px);
      padding: 8px 17px 14px 18px;
      color: #fff;
      display: inline-flex;
      flex-direction: column;
      position: absolute;
      text-align: left;

      & .suggestion-item-link {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 32px;
        color: #ffffff;
        cursor: pointer;
        width: 100%;
        margin-right: 10px;
        display: block;
        transition: ease-in-out 0.3s;

        &:hover {
          opacity: 0.7;
        }
      }
      .suggestion-item.blue {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .suggestion-item-link {
          color: #4f59d9;
        }
        .suggestion-item-btn {
          border: none;
          background: none;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 32px;
          color: #5986ff;
          padding: 0;
          transition: ease-in-out 0.3s;

          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
  }

  .main-logo {
    margin-left: 52px;
  }

  .header-search {
    width: 100%;
    max-width: 330px;
    font-size: 14px;
    line-height: 18px;
    padding: 10px 40px 10px 16px;
    border: 1px solid #2e2e3b;
    font-weight: $regular;
    background: rgba(22, 22, 37, 0.5);

    &::-webkit-input-placeholder {
      opacity: 0.5;
    }

    &::-moz-placeholder {
      opacity: 0.5;
    }

    &:-ms-input-placeholder {
      opacity: 0.5;
    }

    &:-moz-placeholder {
      opacity: 0.5;
    }
  }
  .buttons-close,
  .menu-logo,
  .menu-search {
    display: none;
  }
  .buttons-close {
    @media only screen and (max-width: $mobile) {
      padding: 0 30px 0 0;
      display: block;
      height: 20px;
      margin: 0 0 29px 0;
      i {
        font-size: 20px;
        color: #ffffff;
        opacity: 0.25;
      }
      .icon-chevron-left {
        float: left;
      }
      .icon-close-rounded {
        float: right;
      }
    }
  }
  .menu-logo {
    @media only screen and (max-width: $mobile) {
      height: 40px;
      padding: 0 30px 0 0;
      margin-bottom: 40px;
      display: block;
      text-align: center;
    }
  }
  .menu-search {
    @media only screen and (max-width: $mobile) {
      display: block;
      z-index: 99;
      padding: 0 30px 0 0;
      margin: 0 0 11px 0;
      .search-container {
        display: block;
        padding: 0;
        &-item {
          .main-input {
            margin: 0;
          }
        }
        .result-suggestions {
          max-width: 100%;
          overflow-y: scroll;
          max-height: calc(100vh - 233px);
          z-index: 999;
        }
      }
    }
  }
}

.main-footer {
  padding: 50px 0 45px;
  background: linear-gradient(180deg, #060611 28.25%, rgba(16, 16, 28, 0.87) 97.98%);
  @media only screen and (max-width: $mobile) {
    padding: 60px 0 55px 0;
    .main-footer-top {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      text-align: center;
      .company-slogan {
        padding-top: 17px;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #f2f2f2;
        width: 100%;
        max-width: 100%;
        margin: 0;
      }
    }
  }

  .socials-links {
    padding: 18px 0;
    @media only screen and (max-width: $mobile) {
      margin: 0;
      padding: 41px 0 54px 0;
      display: flex;
      justify-content: space-between;
      width: 100%;
      max-width: 240px;
    }

    > li {
      display: inline-block;
      padding-right: 33px;
      font-size: 17px;
      @media only screen and (max-width: $mobile) {
        padding: 0;
        width: 34px;
        height: 34px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 30px;
      }

      a {
        text-decoration: none;
        color: $white;
        opacity: 0.5;
        transition: all 0.4s ease;
        @media only screen and (max-width: $mobile) {
          color: #979797;
          opacity: 1;
          display: flex;
        }

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  .footer-menu {
    padding-left: 0;
    @media only screen and (max-width: $mobile) {
      display: none;
    }

    > li {
      list-style-type: none;
      font-size: 16px;
      font-weight: $medium;
      line-height: 34px;

      a {
        color: #f2f2f2;
        text-decoration: none;
        opacity: 0.5;
        transition: all 0.3s ease;

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  .footer-title {
    font-weight: $bold;
    font-size: 20px;
    line-height: 44px;
    color: #f2f2f2;
  }

  .menu-coll {
    @media only screen and (max-width: $mobile) {
      display: none;
    }
  }

  .info-coll {
    max-width: 290px;
  }

  .contacts-list {
    float: right;
    padding-left: 0;

    > li {
      list-style-type: none;
      padding-left: 50px;
      padding-bottom: 20px;
      font-size: 16px;
      position: relative;

      &:last-child {
        padding-bottom: 0;
      }

      i {
        position: absolute;
        left: 0;
        color: $blue;
        top: 6px;
        font-size: 20px;
      }

      a {
        text-decoration: none;
        color: $white;

        &:hover {
          color: $blue;
        }
      }
    }
  }

  .company-slogan {
    padding-top: 20px;
    font-size: 16px;
    max-width: 300px;
  }

  .copyright {
    font-size: 12px;
    @media only screen and (max-width: $mobile) {
      text-align: center;
      margin: 0;
    }
  }
}

.markets-view-iframe {
  display: flex;
  border: none;
  width: 1px;
  min-width: 100%;
  height: 0;
  @media only screen and (max-width: $mobile) {
    display: none;
  }

  &.hidden {
    display: none;
  }
}

.main-header-left {
  flex-basis: 25%;
  max-width: 25%;
}

.main-header {
  @media only screen and (max-width: $mobile) {
    .right-header-options-signup,
    .right-header-options-news,
    .search-container {
      display: none;
    }
    .right-header-options-signin {
      padding: 2.5px 17px !important;
      background: #a83b62;
      border-radius: 7px;
    }

    .main-header-left {
      padding: 0;
      max-width: calc(100% - 95px);
      width: 100%;
      flex-basis: auto;
      position: relative;
      .Sidebar_iconButton__1PspK {
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .main-logo {
      margin: 0 0 0 46px;
      display: inline-block;
      max-width: 120px;
      display: flex;
      align-items: center;
      height: 32px;
    }

    .main-header-right {
      padding: 0;
      width: 95px;
      height: 36px;
      max-width: 95px;
      min-width: auto !important;
      .right-header-options {
        margin: 0;
        padding: 0;
      }
      .right-header-options li {
        padding: 0;
        button {
          padding: 2.5px 17px;
        }
      }
      .right-header-options.logged-options {
        .logged-options-item {
          display: none;
        }
      }
    }
  }
}
