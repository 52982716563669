@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;900&display=swap");
@import "../common/assets/icomoon/style.css";
@import "variabiles";
@import "normalize";
@import "mixins";

body {
  overflow-y: auto;
  overflow-x: hidden;
  color: $white;
  background: #1b1b28;
  font-size: 18px;
  line-height: 1.77em;
  font-weight: 400;
}

* {
  font-family: Inter, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  text-decoration: none;

  &:focus {
    outline: none;
  }
}

h1 {
  font-size: 5.55em;
  line-height: 1em;
  font-weight: 900;
  text-align: center;
}

h2 {
  font-weight: 900;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #fff !important;
}

textarea {
  resize: none;
  line-height: 27px !important;
}

.auto-size-description {
  position: relative;
  margin-bottom: 22px;

  textarea {
    margin-bottom: 0 !important;
    height: auto;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;

    &:focus {
      overflow: hidden;
    }
  }

  &:after {
    bottom: 10px;
    left: 2px;
    background: #161625;
    width: calc(100% - 10px);
    height: 11px;
    position: absolute;
    content: "";
  }
}

.icon-chevron-right {
  font-family: "icomoon" !important;
  display: inline-block;
  transform: rotateZ(180deg);

  &:before {
    content: "\e93e";
  }
}

.full-container {
  max-width: 1490px;
  margin: 0 auto;
  padding: 0 30px;
}

.container-primary {
  background-color: #1b1b28;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 30px;
  margin: 35px 0;
  @media only screen and (max-width: $mobile) {
    padding: 0;
    margin: 20px 0;
    .description {
      padding: 15px 0;
      font-size: 16px;
      line-height: 24px;
    }
  }

  &-header {
    display: inline-flex;
    flex-direction: row;

    & span {
      font-size: 16px;
      font-weight: 400;
      margin-left: 35px;
      opacity: 0.5;
    }
  }

  & .main-button {
    width: fit-content;
  }

  & h1,
  h2,
  h3,
  h4,
  h5,
  h5 {
    margin: 0;
    font-weight: 500;
  }
}

button:focus {
  outline: none;
}

.icon-button {
  @include icon-button();
}

.main-input {
  @include main-input();
}

.main-button {
  &:not(.red),
  &:not(.white),
  &:not(.gray) {
    @include main-button();
  }

  &.red {
    @include main-button($red);
  }

  &.gray {
    @include main-button($gray-button-color);
  }

  &.blank {
    background-color: transparent;
    border: 1px solid #ffffff;

    &:hover {
      border: 1px solid $blue;
    }

    &.opacity {
      color: rgba(255, 255, 255, 0.5);
      border: 1px solid rgba(255, 255, 255, 0.5);

      &:hover {
        color: $white;
        border: 1px solid $blue;
      }
    }
  }

  &.small {
    height: 29px;
    font-size: 12px;
    font-weight: 500;
    line-height: 0;
    padding: 5px 20px;
  }

  &.white {
    @include main-button($white);
  }

  i {
    padding-right: 10px;
    position: relative;
    top: 1px;
  }

  &.tiny-button {
    padding: 5px 17px;
    font-weight: 700;
    font-size: 16px;
  }

  & > .button-suffix {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    margin: 0 10px;
    padding-left: 10px;
    border-left: 1px solid #ffffff;
  }
}

.nav-tabs {
  display: inline-flex;
  flex-direction: row;
  width: 100%;

  &.is-live {
    .nav-tab {
      order: 1;

      &:nth-child(2) {
        order: 0;
      }
    }
  }

  & .nav-tab {
    color: rgba(255, 255, 255, 0.5);
    margin: 10px 30px 10px 0;
    font-size: 16px;
    padding-bottom: 3px;
    border-bottom: 2px solid transparent;

    &.active {
      color: rgba(255, 255, 255, 1);
      border-bottom: 2px solid #5986ff;

      .badge-counter {
        background: $blue;
      }
    }
  }
}

.muted {
  opacity: 0.5;
}

.secondary-button {
  @include secondary-button();

  &.outline {
    background: transparent;
    display: inline-block;
    width: auto;
    color: rgba(219, 222, 231, 0.55);
    border: 1px solid rgba(219, 222, 231, 0.15);

    &:hover {
      background: rgba(219, 222, 231, 0.15);
    }
  }
}

.terms-conditions {
  width: 100%;
  line-height: 24px;
  margin: 35px 0;
  color: #ffffff;
  font-size: 15px;
  opacity: 0.35;
  text-align: center;

  a {
    color: $white;
    text-decoration: underline;

    &:hover {
      opacity: 0.6;
    }
  }
}

.error-message {
  font-size: 15px;
  color: $red;
  padding-top: 20px;
}

.success-message {
  font-size: 15px;
  color: #00be00;
  padding-top: 20px;
}

.logout-link {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.5);

  &:hover {
    color: $red !important;
  }
}

.success-container {
  width: 100%;
  max-width: 380px;
  margin: 35px auto 0;
  position: relative;
  text-align: center;
}

.success-animation {
  display: inline-block;
  position: relative;

  &:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: -100%;
    background: #050713;
    animation: complete-check 1s ease forwards;
  }
}

.avatar .name-letters {
  font-weight: $bold;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

label {
  cursor: pointer;
  color: $white;
  display: block;
  margin-bottom: 10px;
  font-weight: $regular;
  font-size: 18px;

  .label-text {
    a {
      color: $white;
    }
  }

  input[type="checkbox"] {
    display: none;

    + .label-text:before {
      content: "\f000";
      color: $blue;
      font-family: "icomoon", serif;
      line-height: 1;
      font-size: 12px;
      width: 1em;
      padding: 5px;
      position: relative;
      top: -2px;
      background: #303043;
      border-radius: 6px;
      display: inline-block;
      margin-right: 18px;
    }

    &:checked {
      + .label-text:before {
        content: "\e94b";
        color: $white;
        background: $blue;
        animation: tick 150ms ease-in;
      }
    }
  }

  &.partial-selected {
    input[type="checkbox"] {
      + .label-text:before {
        content: "\e91b";
        color: $white;
        background: $blue;
        animation: tick 150ms ease-in;
      }
    }
  }
}

input[type="checkbox"].app-label {
  display: none;

  + .label-text {
    font-size: 16px;
  }

  + .label-text:before {
    content: "\f000";
    color: $blue;
    font-family: "icomoon", serif;
    line-height: 1;
    font-size: 12px;
    width: 1em;
    padding: 5px;
    position: relative;
    top: -2px;
    background: #303043;
    border-radius: 6px;
    display: inline-block;
    margin-right: 8px;
  }

  &:checked {
    + .label-text:before {
      content: "\e94b";
      color: $white;
      background: $blue;
      animation: tick 150ms ease-in;
    }
  }
}

.auto-column {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;

  &.dynamic-auto {
    min-width: 25%;
  }
}

.css-1okebmr-indicatorSeparator {
  background-color: transparent !important;
}

.css-1wa3eu0-placeholder {
  font-size: 16px;
  width: 100%;
  color: #bdbdbd !important;
}

.css-g1d714-ValueContainer {
  padding-right: 0 !important;
  width: 100%;
}

.app-select__control {
  background: #161625 !important;
  padding: 5px 10px;
  border: 1px solid #2e2e3b !important;
  box-shadow: none !important;

  .app-select__input {
    input {
      color: #fff !important;
    }
  }

  .app-select__single-value {
    color: $white !important;
  }
}

.app-select__menu {
  z-index: 500 !important;
  background: #161625 !important;
  border: 1px solid #2e2e3b !important;
}

.app-select__menu-list {
  font-size: 16px;
  max-height: 500px;

  > .app-select__option {
    padding: 12px 15px !important;
    line-height: 20px;
    cursor: pointer;
    opacity: 0.7;
    color: $white !important;

    &:hover {
      background: #2f303f;
    }
  }

  .app-select__option--is-focused {
    background: #2f303f;
    color: $white !important;
  }

  .app-select__option--is-selected {
    background: transparent !important;
    font-weight: $bold;
    opacity: 1;

    &:hover {
      background: #2f303f !important;
    }
  }
}

.app-tooltip {
  background: #f6f9ff !important;
  border: 1px solid #e8ebf4 !important;
  border-radius: 6px !important;
  padding: 3px 15px !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 24px !important;

  &:after,
  &:before {
    content: none !important;
  }
}

.icon-close-popup {
  position: absolute;
  top: 30px;
  cursor: pointer;
  font-size: 14px;
  right: 30px;
  color: #72818a;

  &:hover {
    color: #414a4f;
  }
}

.action-link {
  cursor: pointer;
  font-weight: $bold;
  color: $white;

  &:hover {
    color: $light-red;
  }
}

.page-content {
  position: relative;
  padding-top: 100px;
  width: 100%;
  min-height: calc(100vh - 150px);
  @media only screen and (max-width: $mobile) {
    background: #1b1b28;
    padding-top: 81px;
    min-height: 100vh;
    height: 100%;
  }

  &:after {
    content: "";
    position: absolute;
    background: linear-gradient(180deg, #0a0a1a 28.25%, rgba(16, 16, 28, 0) 97.98%);
    width: 100%;
    top: 0;
    z-index: -1;
    left: 0;
    height: 700px !important;
  }

  &.home {
    padding-top: 0;
  }

  .page-title {
    font-size: 46px;
    font-weight: 700;
    margin-bottom: 15px;
    text-transform: capitalize;
    @media only screen and (max-width: $mobile) {
      margin: 8px 0 21px 0;
      font-weight: 500 !important;
      font-size: 23px !important;
      line-height: 34px;
      text-align: center;
      color: #ffffff;
    }
  }
}

.Toastify__toast-container--top-right {
  top: 5em;
}

.Toastify__toast {
  border-radius: 10px !important;
  padding: 10px 23px 10px 20px !important;
  min-height: auto;
  font-size: 16px !important;
  word-break: break-word;
}

.Toastify__toast-container {
  width: auto !important;
  max-width: 350px;

  .Toastify__toast-body {
    position: relative;
    width: auto !important;
    padding-left: 30px;

    &:first-letter {
      text-transform: uppercase;
    }

    &:before {
      font-family: "icomoon";
      font-size: 20px;
      padding-right: 10px;
      position: absolute;
      left: 0;
    }
  }
}

.Toastify__toast--error {
  background: #751a3b;

  .Toastify__toast-body {
    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 4px;
      height: 12px;
      background-color: #fff;
      left: 8px;
      top: 15px;
      z-index: 98;
    }

    &:before {
      content: "\e94a";
      z-index: 99;
      color: #ee4985;
    }
  }
}

.Toastify__toast--info {
  background: #454959;

  .Toastify__toast-body {
    &:before {
      content: "\e944";
      color: $white;
    }
  }
}

.Toastify__toast--success {
  background: #2d6057;

  .Toastify__toast-body {
    &:before {
      content: "\e94b";
      color: #fff;
      width: 18px;
      height: 18px;
      font-size: 9px;
      background: #3ba894;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin-right: 10px;
      top: 12px;
      padding-right: 0 !important;
    }
  }
}

@keyframes tick {
  0% {
    transform: scale(0);
  }
  90% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes complete-check {
  0% {
    left: 0;
  }
  100% {
    left: 100%;
  }
}

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translateY(-15%);
    transform: translateY(-15%);
    backface-visibility: hidden;
    -webkit-filter: blur(0);
  }

  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    backface-visibility: hidden;
    -webkit-filter: blur(0);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translateY(-15%);
    transform: translateY(-15%);
    backface-visibility: hidden;
    -webkit-filter: blur(0);
  }

  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    backface-visibility: hidden;
    -webkit-filter: blur(0);
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    backface-visibility: hidden;
    -webkit-filter: blur(0);
  }

  to {
    opacity: 0;
    -webkit-transform: translateY(-15%);
    transform: translateY(-15%);
    backface-visibility: hidden;
    -webkit-filter: blur(0);
  }
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    backface-visibility: hidden;
    -webkit-filter: blur(0);
  }

  to {
    opacity: 0;
    -webkit-transform: translateY(-15%);
    transform: translateY(-15%);
    backface-visibility: hidden;
    -webkit-filter: blur(0);
  }
}

.slug-input-container {
  position: relative;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #5986ff !important;
  }

  .main-input {
    caret-color: $white;
    padding-left: 170px;
    opacity: 1;
    color: #5986ff;
  }

  .slug-base {
    position: absolute;
    top: 9px;
    left: 25px;
  }
}

.slug-status {
  position: absolute;
  right: 18px;
  top: 15px;
  color: #5986ff;

  &.error {
    color: #da0551;
  }
}

.profile-settings,
.edit-channel-form {
  .save-options {
    float: right;

    .action-link {
      padding-right: 30px;
      opacity: 0.5;

      &:hover {
        color: $white;
        opacity: 1;
      }
    }
  }
}

.input-icon {
  position: relative;

  i {
    position: absolute;
    top: 19px;
    color: #72818a;
    left: 20px;
    font-size: 18px;
  }

  .main-input {
    padding-left: 50px;
  }
}

.addition-description {
  margin: 15px 0;
  opacity: 0.35;
  font-size: 15px;
  line-height: 25px;

  &.mandatory {
    opacity: 1;
  }

  &.label-description {
    opacity: 1;
  }
}

.css-b8ldur-Input {
  margin: 0 !important;
  color: $white !important;
}

.searchable-select {
  .app-select__dropdown-indicator {
    display: none;
  }

  .app-select__clear-indicator {
    cursor: pointer;
  }
}

.transparent-select {
  width: 100%;
  height: 22px;

  .app-select__control {
    background: transparent !important;
    border: none !important;
    cursor: pointer;
    min-height: auto !important;
    padding: 0;
    display: inline-block;

    .arrow-dropdown-indicator {
      position: absolute;
      top: 4px;
    }

    &--is-focused,
    &--menu-is-open {
      min-height: auto !important;
      border: none !important;
      box-shadow: none !important;
    }

    .app-select__indicator {
      padding: 0;
      display: inline-block;
    }

    .app-select__indicators {
      display: inline-block;
    }

    .app-select__value-container {
      padding: 0;
      width: auto;
      display: inline-block;
      float: left;
    }

    .app-select__single-value {
      max-width: 100%;

      i {
        font-size: 20px;
      }
    }

    .app-select__placeholder,
    .app-select__single-value {
      position: relative;
      width: auto;
      top: 0;
      margin: 0;
      padding-right: 3px;
      transform: translateY(0);
    }

    .app-select__menu {
      position: absolute !important;
      z-index: 9900 !important;
    }
  }
}

.app-select__option {
  .custom-status-option {
    font-size: 14px !important;
  }
}

.tags-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 20px 0;

  & > .video-tag {
    padding: 6px 10px;
    border: 1px solid #dbdee7;
    box-sizing: border-box;
    border-radius: 6px;
    font-size: 16px;
    line-height: 24px;
    display: inline-block;
    margin-right: 17px;
    margin-bottom: 15px;

    &.default {
      color: #5986ff;
      border-color: #5986ff;
    }
  }
}

.posts-list {
  .col-md-4 {
    &:nth-child(3n - 2) {
      padding-right: 14px;
    }

    &:nth-child(3n - 1) {
      padding: 0 14px;
    }

    &:nth-child(3n) {
      padding-left: 14px;
    }
  }

  &.streams-full {
    .col-md-4,
    .col-md-8 {
      &:nth-child(3n - 2) {
        padding: 0 14px;
      }

      &:nth-child(3n - 1) {
        padding-left: 14px;
        padding-right: 8px;
      }

      &:nth-child(3n) {
        padding-right: 14px;
        padding-left: 8px;
      }

      &:first-child {
        padding-left: 8px;
      }
    }
  }
}

canvas {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

#chartjs-tooltip {
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  border-radius: 3px;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
  pointer-events: none;
  -webkit-transform: translate(0, 0);
  width: 70%;
  transform: translate(0, 0);

  .tooltip-title {
    font-size: 12px;
    margin: 0;
  }

  .tooltip-data {
    span {
      font-size: 12px;
      margin: 0;
    }
  }

  .chartjs-tooltip-key {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 10px;
    border-radius: 5px;
  }
}

.live-btn {
  background: #a83b62;
  text-transform: uppercase;
  font-size: 12px;
  border-radius: 6px;
  padding: 0 10px;
  font-weight: $bold !important;
  letter-spacing: 2px;
  animation: liveButtonEffect 2s ease infinite;
  cursor: default;

  i {
    padding-right: 8px;
    position: relative;
    top: 1px;
  }

  span {
    color: $white !important;
    font-size: 12px !important;
    font-weight: $regular !important;
    padding-left: 10px;
    letter-spacing: 0;
  }
}

@-webkit-keyframes liveButtonEffect {
  0% {
    background: rgba(168, 59, 98, 1);
  }
  50% {
    background: rgba(168, 59, 98, 0.5);
  }
  100% {
    background: rgba(168, 59, 98, 1);
  }
}

@keyframes liveButtonEffect {
  0% {
    background: rgba(168, 59, 98, 1);
  }
  50% {
    background: rgba(168, 59, 98, 0.5);
  }
  100% {
    background: rgba(168, 59, 98, 1);
  }
}

@-webkit-keyframes liveIcon {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

@keyframes liveIcon {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

@keyframes pulseEffect {
  0% {
    opacity: 1;
  }
  75% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

::-moz-selection {
  color: $white;
  background: $blue;
}

::selection {
  color: $white;
  background: $blue;
}

.app-pagination {
  margin-top: 35px;

  .items-per-page-col {
    min-width: 100px;
    display: inline-flex;
    flex-direction: row;
    align-items: center;

    .app-select__control {
      background-color: #252531 !important;
      padding: 0 5px;
    }

    .app-select__indicator {
      padding: 0;
    }

    .app-select__single-value {
      width: 100%;
      max-width: 100%;
      text-align: center;
    }

    .app-select__value-container {
      min-width: 38px;
      padding-left: 0;
    }

    > span {
      padding-right: 14px;
      font-size: 14px;
    }
  }

  .pagination {
    display: flex;
    justify-content: center;
    padding: 10px 8px 0 0;
    list-style: none;

    & > .page-number {
      a {
        padding: 4px 0;
        width: 35px;
        height: 35px;
        margin: 0 3px;
        font-size: 16px;
        line-height: 25px;
        text-align: center;
        cursor: pointer;
        outline: none;
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 6px;
        display: inline-block;

        &:hover {
          background: $white;
          color: $blue;
        }
      }

      &.active {
        a {
          background: $blue;
          color: $white;

          &:hover {
            background: $blue;
            color: $white;
          }
        }
      }
    }

    .previous,
    .next {
      color: $blue;

      &.disabled {
        color: rgba(255, 255, 255, 0.1);

        i {
          cursor: default;

          &:hover {
            opacity: 1;
          }
        }
      }

      i {
        &:hover {
          opacity: 0.6;
        }
      }
    }

    & .nav-icon {
      line-height: 35px;
      margin: 0 20px;
      display: flex;
      cursor: pointer;
    }
  }

  .go-to-page {
    display: inline-flex;
    flex-direction: row;
    align-items: center;

    span {
      font-size: 14px;
      padding-right: 14px;
    }

    input {
      width: 50px;
      height: 40px;
      margin-right: 14px;
      padding: 0;
      text-align: center;
      border-radius: 6px;
      background: rgba(255, 255, 255, 0.05);
      border: 1px solid rgba(255, 255, 255, 0.1);
    }

    button {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      font-size: 16px;
      color: #000;
      padding-left: 7px;
      text-align: center;

      i {
        padding-left: 3px;
      }
    }
  }
}

.follow-btn {
  text-transform: uppercase;
  letter-spacing: 2px;

  &.following {
    color: $white !important;
    opacity: 1 !important;
    border: 1px solid $blue !important;
    background: $blue;
  }
}

.badge-counter {
  background: #72818a;
  color: #fff;
  border-radius: 50px;
  font-size: 11px;
  line-height: 12px;
  padding: 2px 5px 1px;
  font-weight: $bold;
  position: relative;
  top: -1px;
  margin-left: 7px;
}

.public-profile-page {
  margin-bottom: 150px;

  .mobile-block {
    display: none;

    .breadcrumbs-block {
      width: 100%;
    }
  }

  .account-name {
    font-size: 46px;
    margin-bottom: 15px;
    font-weight: $bold;
    word-break: break-word;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    line-height: 51px;
  }

  &.page-content:after {
    height: 1080px;
  }

  & > .account-heading {
    width: 100%;

    & .left-side {
      width: 100%;
      max-width: 290px;
      padding-right: 30px;
    }

    & .account-image {
      flex-direction: column;
      align-content: center;

      .avatar-container {
        position: relative;

        .live-badge {
          position: absolute;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          border: 2px solid #ee4985;
          z-index: 7;
          transform: scale(1.12);

          .live-beep {
            position: absolute;
            right: 7px;
            top: 7px;
            width: 32px;
            z-index: 6;
            height: 32px;
            border-radius: 50%;
            box-sizing: border-box;
            background: #0a0a1a;

            &:before {
              content: "";
              width: 10px;
              height: 10px;
              z-index: 12;
              position: absolute;
              box-sizing: border-box;
              margin-top: 11px;
              margin-left: 11px;
              border-radius: 50%;
              background: #ee4985;
              animation: liveIcon 1.5s ease infinite;
            }
          }

          &:before {
            content: "";
            position: absolute;
            right: 11px;
            top: 11px;
            width: 24px;
            height: 24px;
            box-sizing: border-box;
            z-index: 8;
            border-radius: 50%;
            border: 2px solid #ee4985;
          }
        }
      }

      & .owner-avatar {
        width: 164px;
        height: 164px;
        overflow: hidden;
        margin: 0 auto;
        border-radius: 50%;
        background-size: cover;
        background-position: center;

        & > .name-letters {
          font-size: 64px;
          position: relative;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      & .streamer-details {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        font-size: 16px;

        & .streamer-avatar {
          display: inline-flex;
          justify-content: center;
          border-radius: 50%;
          margin-left: 10px;
          line-height: 8px;
          font-size: 10px;
          width: 22px;
          cursor: pointer;
          background-position: center;
          background-size: cover;
          height: 22px;

          .name-letters {
            font-size: 10px;
            margin-left: 1px;
          }
        }

        & > .link-to-streamer {
          display: inline-flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;

          .name-letters {
            color: $white;
          }

          > span {
            color: #5986ff;
            margin-left: 10px;

            &:hover {
              opacity: 0.7;
            }
          }
        }
      }
    }

    & > .account-details {
      padding-left: 25px;
      width: calc(100% - 290px);

      & > .row {
        align-items: center;
        margin-top: 10px;

        & > .account-name {
          font-size: 46px;
          margin: 0;
        }

        & .media-icons {
          display: flex;
          flex-direction: row;
          align-items: center;

          > a {
            &:hover {
              color: $blue;
            }
          }
        }

        & .details-col {
          display: inline-flex;
          flex-direction: row;
          align-items: center;
          line-height: 41px;
          margin: 0 10px;
          color: #ffffff;
          font-size: 16px;

          .follow-btn {
            margin: 0 12px;
          }

          > .subscriptions-icon {
            font-size: 22px;
          }

          & p {
            margin: 0 10px;
          }
        }

        &.description {
          display: inline-flex;
          flex-direction: row;
          align-items: baseline;
          font-size: 16px;
          padding: 10px 10px 5px;
          margin-bottom: 12px;
          width: 100%;
          border-radius: 6px;
          border: 1px solid rgba($color: #ffffff, $alpha: 0.15);
          line-height: 24px;

          & i {
            width: 40px;
            padding-left: 8px;
            position: relative;
            top: 1px;
          }

          & p {
            width: calc(100% - 60px);
            color: #72818a;
            white-space: pre-wrap;

            .desc-indicator {
              color: #5986ff;
              font-weight: bold;
              cursor: pointer;

              &:hover {
                opacity: 0.7;
              }
            }
          }
        }
      }
    }

    & .channel-updates {
      width: 100%;
      margin-top: 15px;
      margin-left: 0;

      & .updates-container {
        width: 100%;

        h3 {
          font-size: 23px;
          line-height: 27px;
          font-weight: 400;
          margin-bottom: 30px;
        }

        .rates-graph {
          min-height: 380px;
          width: 100%;
          padding: 40px 25px 28px;
          background-color: #1f1f2d;
          border-radius: 6px;
          position: relative;
        }

        .upcoming-video {
          height: 110px;
          width: 100%;
          margin: 9px 0;
          background: linear-gradient(90deg, #101a1f 0%, #192735 100%);
          border-radius: 6px;
        }
      }
    }
  }

  > .section-title {
    text-align: center;
    font-weight: 500;
    font-size: 46px;
    margin-top: 45px;
    line-height: 64px;
    display: inline-block;
    width: 100%;
    color: $white;
  }

  > .channel-tags-list {
    text-align: center;
    display: inline-block;
    width: 100%;

    .video-tag {
      border: 1px solid #5986ff;
      padding: 6px 20px;
    }
  }

  .chart-container {
    position: relative;
    width: 100%;
    padding: 0 20px;
  }

  @media only screen and (max-width: $mobile) {
    margin-bottom: 30px;
    .section-title,
    .channel-tags-list.tags-list,
    .similar-channels-list {
      display: none;
    }
    & > .account-heading {
      margin: 0;

      .left-side {
        display: none;
      }

      .account-details {
        padding: 0;

        .account-details-top,
        .account-details-buttons,
        .nav-tabs {
          display: none;
        }

        .row {
          margin: 0 !important;
        }

        .description {
          margin-top: 30px !important;
        }

        .channel-load-more {
          margin: 0 auto;
          min-width: auto;
          display: inherit;
          background: #a83b62;
          border-radius: 7px;
          max-width: 290px;
          width: 100%;
          font-weight: bold;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: #ffffff;
          padding: 6px 0;
        }

        .channel-followers {
          padding-top: 20px;
          margin-bottom: 30px;

          .heading-table {
            display: none;
          }

          .app-pagination {
            display: flex;
            justify-content: center;

            .items-per-page-col {
              margin: 24px 0;
            }
          }

          .follower-list {
            .follower-item {
              width: 100%;
              display: flex;
              flex-wrap: wrap;

              .name-coll {
                width: 100%;
                flex-grow: unset;
                flex-basis: auto;
                height: 28px;
                align-items: center;
                margin-bottom: 5px;
              }

              .duration-coll {
                width: 50%;
                display: flex;
                justify-content: flex-start;
                padding-left: 8px;
              }

              .date-coll {
                width: 50%;
                display: flex;
                justify-content: flex-end;
              }
            }
          }
        }

        .channel-videos {
          .channel-videos-title {
            display: none;
          }

          .posts-list {
            padding-top: 30px;

            .col-md-4,
            .posts-list-item {
              padding: 0 !important;

              .video-post-item {
                margin-bottom: 30px;

                .content {
                  .date {
                    right: unset;
                    left: 20px;
                    padding: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
    .mobile-block {
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      &-item {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .mobile-block-title {
        span {
          font-style: normal;
          font-weight: bold;
          font-size: 28px;
          line-height: 57px;
          color: #ffffff;
          word-break: break-word;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }

      .mobile-block-info {
        margin-top: 12px;
        margin-bottom: 16px;
        justify-content: center;

        &-avatar {
          width: 100px;
          height: 100px;

          .avatar {
            background-position: center;
            background-size: cover;
            width: 100px;
            height: 100px;
            border-radius: 50%;
            overflow: hidden;

            .name-letters {
              font-size: 40px;
              width: 100px;
              height: 100px;
            }
          }
        }

        &-follow {
          max-width: 155px;
          width: calc(100% - 100px);
          height: 100px;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          padding: 16px 0 16px 30px;

          &-item.people {
            height: 24px;
            display: flex;
            align-items: center;
          }

          &-item {
            width: 100%;

            .icon-users-group {
              font-size: 24px;
            }

            .number {
              font-weight: normal;
              font-size: 16px;
              line-height: 24px;
              color: #ffffff;
              opacity: 0.5;
              padding-left: 6px;
            }

            .follow-btn {
              padding: 5px 17px;
            }
          }
        }
      }

      .mobile-block-nav {
        .nav-tabs {
          display: flex;
          flex-wrap: nowrap;
          overflow: scroll;
          -ms-overflow-style: none;
          scrollbar-width: none;
          border-bottom: 1px solid rgba(255, 255, 255, 0.5);

          &::-moz-scrollbar {
            width: 0;
            height: 0;
            background: transparent;
          }

          &::-webkit-scrollbar {
            width: 0;
            height: 0;
            background: transparent;
          }

          .nav-tab {
            margin: 0 34px 0 0;
            padding-bottom: 6px;
          }
        }
      }

      .mobile-block-info {
        margin: 0 0 20px 0 !important;
      }

      .mobile-block-joined {
        margin-bottom: 10px;

        .icon-calendar {
          font-size: 18px;
          margin-right: 9px;
        }

        .muted {
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          color: #ffffff;
          opacity: 0.5;
          margin: 0;
        }
      }

      .mobile-block-desc {
        padding: 10px 16px 12px;
        border: 1px solid rgba(255, 255, 255, 0.1);
        box-sizing: border-box;
        border-radius: 6px;
        margin: 17px 0 23px 0;
        text-align: left;
        justify-content: flex-start;

        p {
          margin: 0;
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
          color: #72818a;
        }
      }

      .mobile-block-owner {
        .streamer-details {
          .muted {
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
          }

          .avatar {
            width: 18px;
            height: 18px;
          }

          .channel-name {
            font-weight: normal;
            font-size: 16px;
            line-height: 32px;
          }
        }
      }
    }
  }
}

.round-action-button {
  background: transparent;
  font-size: 14px;
  line-height: 16px;
  color: #72818a;
  border: 1px solid #72818a;
  border-radius: 50px;
  padding: 6px 12px;
  transition: all 0.3s ease;

  &:hover {
    background-color: #72818a;
    color: $white;
  }
}

.chart-no-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;

  i {
    font-size: 50px;
    color: #2f303f;
  }

  p {
    color: #72818a;
    padding-top: 20px;
  }
}

.clreatype {
  text-shadow: 0 2px 1px rgba(0, 0, 0, 0.2), 0 1px 1px rgba(0, 0, 0, 0.14),
    0 1px 3px rgba(0, 0, 0, 0.12);
}

.link-decorator {
  color: #5986ff;

  &:hover {
    color: #5b6ad6;
  }
}

.react-datepicker {
  color: #fff !important;
  background-color: #161625 !important;
  box-shadow: 0 0 30px 3px #000;
  border: none !important;

  .react-datepicker__time {
    background-color: #161625 !important;
  }

  .react-datepicker__navigation {
    top: 24px;
  }

  .react-datepicker__day {
    color: #fff;
    width: 2.2rem;
    line-height: 2.2rem;
    font-size: 16px;

    &:hover {
      background: #33334e;
    }

    &.react-datepicker__day--selected {
      background: $blue;
    }
  }

  .react-datepicker__time-container,
  .react-datepicker__time-box {
    width: 140px !important;
  }

  .react-datepicker__time-list-item {
    height: auto !important;
    font-size: 16px;

    &:hover {
      background: #33334e !important;
    }

    &.react-datepicker__time-list-item--selected {
      background: $blue !important;
    }
  }

  .react-datepicker-time__header {
    color: #fff;
  }

  .react-datepicker__header {
    background-color: #0c0c18;
    border-bottom: 1px solid #585656;

    .react-datepicker__current-month {
      color: #fff;
      margin: 8px 0;
    }

    .react-datepicker__day-name {
      width: 2.2rem;
      line-height: 2.2rem;
      font-size: 16px;
      color: #fff;
    }
  }
}

.react-datepicker__triangle {
  display: none !important;
}

.vjs-waiting .vjs-big-play-button {
  display: none;
}

.vjs-loading-spinner {
  &:before,
  &:after {
    content: none !important;
  }
}

.link-decorator {
  color: #5986ff;

  &:hover {
    color: #5b6ad6;
  }
}

.now-on-air {
  margin-left: 10px;

  i {
    animation: liveIcon 2s ease infinite;
  }
}

.breadcrumbs-block {
  display: flex;
  justify-content: left;
  height: 24px;

  @media only screen and (max-width: $mobile) {
    justify-content: center;
    height: 24px;
  }

  &-link,
  span {
    font-weight: normal;
    text-decoration: none;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    opacity: 0.5;
    text-transform: capitalize;
  }

  &-link {
    &:last-child {
      pointer-events: none;
      text-transform: capitalize;
    }
  }

  span {
    padding: 0 5px;
  }
}

.avatar-container {
  position: relative;
  @media only screen and (max-width: $mobile) {
    width: 106px;
    height: 106px;
  }

  .live-badge {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 2px solid #ee4985;
    z-index: 7;
    transform: scale(1.12);
    @media only screen and (max-width: $mobile) {
      top: -3px;
      left: -3px;
    }

    .live-beep {
      position: absolute;
      right: 7px;
      top: 7px;
      width: 32px;
      z-index: 6;
      height: 32px;
      border-radius: 50%;
      box-sizing: border-box;
      background: #0a0a1a;
      @media only screen and (max-width: $mobile) {
        right: 0;
        top: 0;
      }

      &:before {
        content: "";
        width: 10px;
        height: 10px;
        z-index: 12;
        position: absolute;
        box-sizing: border-box;
        margin-top: 11px;
        margin-left: 11px;
        border-radius: 50%;
        background: #ee4985;
        animation: liveIcon 1.5s ease infinite;
      }
    }

    &:before {
      content: "";
      position: absolute;
      right: 11px;
      top: 11px;
      width: 24px;
      height: 24px;
      box-sizing: border-box;
      z-index: 8;
      border-radius: 50%;
      border: 2px solid #ee4985;
      @media only screen and (max-width: $mobile) {
        right: 4px;
        top: 4px;
      }
    }
  }
}

.breadcrumbs-block {
  @media only screen and (max-width: $mobile) {
    display: flex;
    justify-content: center;
    height: 24px;
    &-link,
    span {
      font-weight: normal;
      text-decoration: none;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #ffffff;
      opacity: 0.5;
    }
    &-link {
      &:last-child {
        pointer-events: none;
        text-transform: capitalize;
      }
    }
    span {
      padding: 0 5px;
    }
  }
}

.value-container-drop-item {
  width: 100%;
  display: block;
}

.block-filters {
  margin: 0;
  background: #232632;
  border-radius: 6px;
  overflow: hidden;
  box-sizing: border-box;
  max-height: 0;
  opacity: 0;
  padding: 0 25px;

  @media only screen and (max-width: $mobile) {
    margin-bottom: 30px;
  }

  &.filters-in {
    animation: filters-in 0.4s ease forwards;
  }

  &.filters-out {
    animation: filters-out 0.4s ease forwards;
  }

  .filter-title {
    font-weight: 800;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: $white;
    margin-bottom: 15px;
  }

  .sort-list-chooser {
    padding: 0;
    list-style-type: none;

    li {
      line-height: 28px;

      label {
        font-size: 16px;
        color: #979797;
        display: inline-block;
      }

      input {
        display: none;

        &:checked + label {
          color: $white;
          font-weight: $bold;
        }
      }
    }
  }

  .filter-coll {
    padding: 0 20px;

    &.list-col {
      @media only screen and (max-width: $mobile) {
        display: none;
      }
    }
  }
}

@keyframes filters-in {
  0% {
    max-height: 0;
    opacity: 0;
    padding: 0 25px;
    margin-top: 0;
  }
  100% {
    opacity: 1;
    padding: 25px;
    margin-top: 20px;
    max-height: 500px;
  }
}

@keyframes filters-out {
  0% {
    opacity: 1;
    padding: 25px;
    margin-top: 20px;
    max-height: 500px;
  }
  100% {
    opacity: 0;
    padding: 0 25px;
    max-height: 0;
    margin-top: 0;
  }
}
