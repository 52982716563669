@import "sass/variabiles";
$transition_time: 0.5s;
// @media only screen and (max-width: $mobile) {}
.discovery-menu-root {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 100;
  background: linear-gradient(90deg, #05335b 12.56%, #00042b 96.5%), #171717;
  height: 100vh;
  top: 0;
  left: 0;
  padding: 25px 0 25px 25px;
  box-shadow: 36px 0 32px -32px rgba(0, 0, 0, 0.5);
  transition: transform $transition_time ease;
  transform: translateX(calc(-100% - 36px));
  @media only screen and (max-width: $mobile) {
    width: 100%;
    padding: 30px 0 30px 30px;
    z-index: 999;
  }
}

.dm-visible {
  transform: unset;
}

.dm-icon-button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 38px;
  margin-left: 4px;
  z-index: 101;
  cursor: pointer;

  @media (max-width: $mobile) {
    top: 8px;
  }

  .icon {
    padding: 0 !important;
  }

  &.animated-button {
    transition: transform $transition_time ease;
  }
}

.dm-header {
  font-size: 22px;
  line-height: 34px;
  padding-right: 25px;
  margin: 5px 48px;
  @media only screen and (max-width: $mobile) {
    display: none;
  }
}

.dm-category-buttons {
  padding-right: 25px;
  margin: 24px 0;
  @media only screen and (max-width: $mobile) {
    display: block;
    margin: 19px 0;
    text-align: center;
    padding: 0 30px 0 0;
  }

  button {
    color: #72818a;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    padding: 4px 18px;
    background: transparent;
    border: 1px solid transparent;
    border-radius: 20px;
    text-transform: capitalize;
    @media only screen and (max-width: $mobile) {
      padding: 2px 18px;
      height: 30px;
    }

    &[data-active="true"] {
      color: #000000;
      font-weight: 800;
      background: #ffffff;
      border: 1px solid #e8ebf4;
    }
  }
}
