.webCam-inputs {
  margin-bottom: 45px;

  .app-select__indicator-separator {
    display: none;
  }

  .input-container {
    position: relative;

    i {
      position: absolute;
      z-index: 9;
      left: 15px;
      top: 15px;
      text-align: center;
      width: 20px;
    }

    &.disabled {
      opacity: 0.6;
    }
  }

  .app-select__value-container {
    padding-left: 35px !important;
  }

  .labels-select {
    margin: 15px 0 5px;
    font-size: 16px;
    line-height: 24px;
  }

  .inputs-select {
    max-width: 395px;
  }
}
