@import "sass/variabiles";

.page-content {
  &.help {
    &:after {
      background: linear-gradient(180deg, #0a0a1a 0%, #2d2d3f 100%);
      height: 100% !important;
    }
  }
}

.help-center {
  padding: 15px 0 0;

  .page-title {
    text-align: center;
    font-weight: bold;
    font-size: 62px;
    line-height: 46px;
    padding-bottom: 5px;
    color: $white;
    @media screen and (max-width: $mobile) {
      padding: 0;
    }
  }

  .page-description {
    text-align: center;
    font-size: 20px;
    line-height: 34px;
    @media screen and (max-width: $mobile) {
      font-size: 16px;
      line-height: 28px;
    }
  }

  .articles {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 6px;
    padding: 60px;
    margin: 80px 0 100px;
    @media screen and (max-width: $tablet) {
      padding: 30px 20px 10px;

      > .top-xs {
        display: flex;
        flex-direction: column-reverse;
      }
    }
    @media screen and (max-width: $mobile) {
      margin: 30px 0 10px;
      padding: 10px 20px;
    }

    .article-tabs {
      position: sticky;
      top: 90px;

      @media screen and (max-width: $tablet) {
        position: relative;
        top: 0;
      }

      .tabs-title {
        font-weight: $medium;
        font-size: 24px;
        line-height: 34px;
        color: $white;
        padding-left: 15px;
        @media screen and (max-width: $mobile) {
          padding-left: 0;
          font-size: 20px;
          line-height: 28px;
          margin-bottom: 0;
        }
      }
    }

    .tabs-list {
      background: #1b1b28;
      border-radius: 6px;
      list-style-type: none;
      padding: 20px 30px;
      @media screen and (max-width: $tablet) {
        padding: 0 0 0 15px;
        background: none;
      }
      @media screen and (max-width: $mobile) {
        padding-left: 0;
      }

      .nav-item {
        color: $white;
        font-size: 18px;
        line-height: 24px;
        opacity: 0.5;
        transition: opacity 0.3s ease;
        padding-bottom: 15px;
        display: inline-block;
        @media screen and (max-width: $mobile) {
          font-size: 14px;
          line-height: 20px;
          padding-bottom: 4px;
        }

        &.active,
        &:hover {
          opacity: 1;
        }

        &.active {
          font-weight: $bold;
        }
      }
    }
  }

  .article-data {
    padding: 0 60px;
    min-height: 500px;

    @media screen and (max-width: $tablet) {
      padding: 0 15px;
    }
    @media screen and (max-width: $mobile) {
      padding: 0 10px;
    }
  }

  .contact-us {
    scroll-margin-top: -30px;
    background: linear-gradient(0deg, #0a0a1a 28.25%, #1b1b28 97.98%);

    .contact-container {
      padding: 140px 190px;

      @media (max-width: 1920px) {
        padding: 140px 10vw;
      }
      @media (max-width: 1200px) {
        padding: 90px 35px;
      }

      .contact-info {
        padding-top: 80px;
        padding-right: 0;
      }

      .info-inner {
        background: #0a0a1a;
        border-radius: 6px 0 0 6px;
        padding: 80px 80px 80px 110px;
        position: relative;
        @media (max-width: 1920px) {
          padding: 80px 80px 80px 5vw;
        }

        > img {
          position: absolute;
          width: 100%;
          max-width: 350px;
          bottom: 0;
          left: 108px;
          @media (max-width: 1920px) {
            left: 5vw;
          }
        }

        .title {
          font-weight: $bold;
          font-size: 42px;
          line-height: 55px;
          @media screen and (max-width: $mobile) {
            font-size: 20px;
            line-height: 25px;
            margin: 0;
          }
        }

        .blue-title {
          font-weight: $regular;
          font-size: 42px;
          line-height: 55px;
          color: $light-blue;
          margin-top: 0;
          padding-bottom: 255px;
          @media screen and (max-width: $mobile) {
            font-size: 20px;
            line-height: 25px;
            margin: 0;
            padding-bottom: 0 !important;
          }
        }

        @media screen and (max-width: $tablet) {
          padding: 15px 45px;
          text-align: center;
          border-radius: 6px 6px 0 0;

          > img {
            display: none;
          }
          .blue-title {
            padding-bottom: 20px;
          }
        }
        @media screen and (max-width: $mobile) {
          padding: 35px 20px;
        }
      }

      .contact-form {
        padding-left: 0;
      }

      .form-inner {
        background: #1b1b28;
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 6px;
        padding: 35px 60px 30px;
        position: relative;

        .terms-conditions {
          margin: 27px auto 0;
          max-width: 400px;
        }

        .form-heading {
          margin: 0;
        }

        .explanation {
          font-style: italic;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          color: $white;
          opacity: 0.5;
          @media screen and (max-width: $tablet) {
            position: absolute;
            right: 35px;
            font-size: 14px;
            line-height: 25px;
          }
          @media screen and (max-width: $mobile) {
            right: 20px;
            font-size: 12px;
            line-height: 30px;
          }
        }

        .form-label {
          font-weight: bold;
          font-size: 18px;
          line-height: 34px;
          color: $white;
          @media screen and (max-width: $mobile) {
            font-size: 14px;
            line-height: 28px;
            margin-bottom: 0;
          }
        }

        .input-container {
          padding: 10px 0 0;

          .message {
            min-height: 130px;
          }
        }

        .main-button {
          width: 100%;
          font-weight: $bold;
        }
        @media screen and (max-width: $mobile) {
          input,
          .message {
            font-size: 14px;
            line-height: 28px;
            padding: 8px 15px;
            margin-bottom: 8px;
          }
        }

        @media screen and (max-width: $tablet) {
          padding: 35px 35px 30px;
        }
        @media screen and (max-width: $mobile) {
          padding: 10px 20px;
          .terms-conditions {
            font-size: 12px;
          }
        }
      }

      @media screen and (max-width: $tablet) {
        padding: 45px 15px;

        .between-xs {
          flex-direction: column;
        }
        .col-xs {
          padding: 0 25px;
        }
      }
    }
  }
}
.grecaptcha-badge {
  visibility: hidden;
}
