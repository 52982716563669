@import "sass/variabiles";

.radio-select {
  display: inline-flex;
  flex-direction: column;

  &.horizontal {
    flex-direction: row;
  }

  & > .select-item {
    display: inline-flex;
    flex-direction: row;
    line-height: 24px;
    margin-bottom: 20px;
    margin-right: 20px;
    cursor: pointer;

    &:last-child {
      margin-right: 0px;
    }

    &.checked {
      & > .checkbox {
        border: 6px solid #dbdee7;
      }

      & > .details {
        & span {
          font-weight: 500;
        }
      }
    }

    & > .checkbox {
      box-sizing: border-box;
      width: 24px;
      height: 24px;
      border: 1px solid #dbdee7;
      border-radius: 50%;
      margin-right: 16px;
    }

    & > .details {
      display: inline-flex;
      flex-direction: column;

      & span {
        font-size: 18px;
        font-weight: 100;
      }

      & p {
        font-size: 15px;
        opacity: 0.35;
        margin-top: 10px;
      }
    }
  }
}
